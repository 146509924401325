"use client";

import { useEffect } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";

const GOOGLE_LIBRARIES: ("drawing" | "geometry" | "localContext" | "places" | "visualization")[] = ["places"];
const GOOGLE_PLACES_KEY = process.env.NEXT_PUBLIC_GOOGLE_PLACES_KEY;

const formatAddressForPlace = ({ geometry, ...place }: { geometry: any; address_components: any }) => {
  const addressComponents = place.address_components;
  let address_line_1 = "";
  const address_line_2 = "";
  let city = "";
  let state = "";
  let zip_code = "";
  let country = "";

  for (const component of addressComponents) {
    const componentType = component.types[0];
    switch (componentType) {
      case "street_number": {
        address_line_1 = `${component.long_name} ${address_line_1}`;
        break;
      }
      case "route": {
        address_line_1 += component.long_name;
        break;
      }
      case "postal_code": {
        zip_code = component.long_name;
        break;
      }
      case "locality": {
        city = component.long_name;
        break;
      }
      case "administrative_area_level_1": {
        state = component.short_name;
        break;
      }
      case "country":
        country = component.long_name;
        break;
      default:
        break;
    }
  }

  return {
    address_line_1,
    address_line_2,
    city,
    state,
    zip_code,
  };
};

export default function useAddressAutocomplete(updateAddress: any) {
  const {
    suggestions: { data },
    clearSuggestions,
    setValue,
    init,
  } = usePlacesAutocomplete({
    initOnMount: false,
    debounce: 300,
    requestOptions: {
      types: ["address"],
      componentRestrictions: { country: "us" },
    },
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_PLACES_KEY as string,
    libraries: GOOGLE_LIBRARIES,
  });

  useEffect(() => {
    if (isLoaded) {
      init();
    }
  }, [isLoaded, init]);

  const onSelect = (placeId: string) => {
    if (placeId) {
      clearSuggestions();
      getGeocode({ placeId })
        .then((results) => {
          if (results?.[0]) {
            updateAddress(formatAddressForPlace(results[0]));
          }
        })
        .catch((e) => console.error(e));
    } else {
      updateAddress({});
    }
  };

  return { suggestions: data, onChangeInput: setValue, onSelect };
}
