import { useMemo, useState } from "react";
import clsx from "clsx";
import Skeleton from "components/Skeleton";
import { RecordItem } from "types/common";
import { FALLBACK_IMAGE_SRC, FileCellProps, Size } from "utils/constants";
import { BAFile } from "types/baTypes";
import Image from "components/Image";
import useTableActionsState from "hooks/useTableActionsState";
import { isBAFile } from "../utils";
import CellEmpty from "./CellEmpty";

type CellBeforeAfterProps = {
  isLoading?: boolean;
  size?: Size;
  className?: string;
  id?: string;
  inDetailOverview?: boolean;
  inCard?: boolean;
  row?: RecordItem;
  mimetype?: string;
  beforeImage?: string | File | BAFile;
  afterImage?: File | BAFile;
};
const CellBeforeAfter = ({
  beforeImage,
  afterImage,
  isLoading = false,
  size = Size.SM,
  className,
  inDetailOverview = false,
  inCard = false
}: CellBeforeAfterProps) => {
  const [hasLoadError, setHasLoadError] = useState(false);
  const { updateBeforeAfterFileViewState } = useTableActionsState();
  const imageSrc = useMemo(() => {
    if (hasLoadError) return { beforeImage: FALLBACK_IMAGE_SRC, afterImage: FALLBACK_IMAGE_SRC };
    let beforeImageSrc;
    let afterImageSrc;
    if (beforeImage) {
      if (typeof beforeImage !== "string" && !isBAFile(beforeImage)) {
        beforeImageSrc = URL.createObjectURL(beforeImage as File);
      } else if (typeof beforeImage === "string") {
        beforeImageSrc = beforeImage;
      } else {
        beforeImageSrc = (beforeImage as BAFile)?.path;
      }
    }

    if (afterImage) {
      if (typeof afterImage !== "string" && !isBAFile(afterImage)) {
        afterImageSrc = URL.createObjectURL(afterImage as File);
      } else if (typeof afterImage === "string") {
        afterImageSrc = afterImage;
      } else {
        afterImageSrc = (afterImage as BAFile)?.path;
      }
    }

    if (!beforeImageSrc || !afterImageSrc) return null;
    return {
      beforeImage: beforeImageSrc,
      afterImage: afterImageSrc
    };
  }, [afterImage, beforeImage, hasLoadError]);

  const finalProps = useMemo(
    () =>
      inCard
        ? FileCellProps.cardProps
        : inDetailOverview
        ? FileCellProps.detailProps
        : FileCellProps.sizeProps[size] || {},
    [inCard, inDetailOverview, size]
  );

  if (isLoading) {
    return <Skeleton className="h-10 w-10" />;
  }

  if (!imageSrc) {
    return <CellEmpty />;
  }

  return (
    <div
      className={clsx("flex cursor-pointer", className)}
      onClick={(e) => {
        e.stopPropagation();
        updateBeforeAfterFileViewState({
          isOpen: true,
          beforeImage: imageSrc.beforeImage,
          afterImage: imageSrc.afterImage
        });
      }}
    >
      <div
        className={clsx(
          "relative",
          inCard
            ? FileCellProps.cardClassName
            : inDetailOverview
            ? FileCellProps.detailClassName
            : FileCellProps.sizeClassName[size]
        )}
      >
        <Image
          alt=""
          src={imageSrc.beforeImage}
          {...finalProps}
          className={clsx(finalProps.className, "rounded-r-none")}
          onError={() => setHasLoadError(true)}
        />
      </div>
      <div
        className={clsx(
          "relative",
          inCard
            ? FileCellProps.cardClassName
            : inDetailOverview
            ? FileCellProps.detailClassName
            : FileCellProps.sizeClassName[size]
        )}
      >
        <Image
          alt=""
          src={imageSrc.afterImage}
          {...finalProps}
          className={clsx(finalProps.className, "rounded-l-none")}
          onError={() => setHasLoadError(true)}
        />
      </div>
    </div>
  );
};

export default CellBeforeAfter;
