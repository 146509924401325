import React, { useEffect, useState } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { Combobox } from "@headlessui/react";
import { Home as AddressIcon } from "lucide-react";

import Skeleton from "components/Skeleton";
import { CellRules } from "types/baTypes";
import { AddressValue } from "types/common";
import useAddressAutocomplete from "hooks/useAddressAutocomplete";
import CellEmpty from "./CellEmpty";
import CellState from "./CellState";

type CellAddressInputProps = {
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inForm?: boolean;
  inList?: boolean;
  autoFocus?: boolean;
  rules?: CellRules;
  hasRequiredError?: boolean;
  ignoreValidation?: boolean;
  placeholder?: string;
  className?: string;
};

type CellAddressProps = {
  value: AddressValue;
  onEdit?: (value: AddressValue) => void;
  inForm?: boolean;
  inList?: boolean;
  init?: (value: string) => string;
  rules?: CellRules;
  hasRequiredError?: boolean;
  type?: string;
  pattern?: string;
  title?: string;
  ignoreValidation?: boolean;
  autoFocus?: boolean;
  onExit?: () => void;
  isLoading?: boolean;
  isEditable?: boolean;
  onClick?: () => void;
  className?: string;
  placeholder?: string;
  isHovered?: boolean;
  hasCoordinatesOrProjectId?: boolean;
  isHeader?: boolean;
};

const isNotEmpty = (value: AddressValue) =>
  !isEmpty(value) && (value.address_line_1 || value.address_line_2 || value.city || value.state || value.zip_code);

const isEqual = (value1: AddressValue, value2: AddressValue) =>
  !isEmpty(value1) &&
  !isEmpty(value2) &&
  value1.address_line_1 === value2.address_line_1 &&
  value1.address_line_2 == value2.address_line_2 &&
  value1.city == value2.city &&
  value1.state == value2.state &&
  value1.zip_code == value2.zip_code;

const AddressInput = ({ value, rules, autoFocus, placeholder, onChange, className = "" }: CellAddressInputProps) => (
  <div className="relative mb-4 flex w-full items-center rounded-lg">
    <input
      autoComplete="none"
      autoCorrect="off"
      autoCapitalize="none"
      className={clsx(
        className,
        "relative flex min-h-[56px] w-full items-center rounded-lg bg-transparent px-3 py-[11px]"
      )}
      value={value}
      onChange={onChange}
      autoFocus={autoFocus}
      placeholder={placeholder}
      required={rules?.required}
    />
  </div>
);

const CellAddress = ({
  value,
  isLoading = false,
  hasRequiredError,
  ignoreValidation,
  rules,
  onEdit,
  isEditable = false,
  className = "",
  onClick,
  isHovered = false,
  hasCoordinatesOrProjectId = false,
  inList = false,
  isHeader = false,
  inForm = false
}: CellAddressProps) => {
  const classNameFormField = clsx(
    "border-none focus:bg-white text-md",
    "bg-gray-100 dark:bg-gray-600/60 hover:bg-gray-150",
    "focus:border-none focus:outline-0",
    "focus:ring-2 focus:ring-primary-700 dark:focus:!ring-primary-dark-700",
    "focus-visible:ring-2 focus-visible:!ring-primary-700 dark:!focus-visible:ring-primary-dark-700",
    "dark:placeholder:text-base-dark-secondary placeholder:text-base-secondary",
    inForm && "!bg-gray-100 dark:!bg-gray-600/60 focus:!bg-transparent"
  );

  const updateAddress = (newVal: AddressValue) => {
    setSelected(newVal);
    onEdit?.(newVal);
  };

  const [selected, setSelected] = useState<AddressValue>(value);
  const { suggestions, onChangeInput, onSelect } = useAddressAutocomplete(updateAddress);

  useEffect(() => {
    if (!isEqual(value, selected)) {
      setSelected(value);
    }
  }, [value]);

  const handleChange = (key: string, val: string, callOnEdit: boolean) => {
    const newVal = {
      ...value,
      [key]: val
    };
    setSelected(newVal);

    if (callOnEdit) {
      onEdit?.(newVal);
    }
  };

  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 max-w-full" />
      </div>
    );
  }

  return (
    <div
      onClick={onClick}
      className={clsx(
        "w-full",
        isEditable && !onEdit && "min-h-[56px]",
        isHovered && hasCoordinatesOrProjectId && "pr-12",
        inList && "rounded-xl px-3 py-4 hover:bg-neutral-200 dark:hover:bg-neutral-dark-200",
        className
      )}
    >
      {isEditable ? (
        <>
          <div>
            <Combobox value={selected?.address_line_1 ?? ""} onChange={onSelect} as="div" className="mb-4">
              {({ open }) => (
                <div className={clsx("relative w-full bg-transparent", open ? "rounded-lg" : "rounded-full")}>
                  <div className="relative">
                    <Combobox.Input
                      autoComplete="none"
                      autoCorrect="off"
                      autoCapitalize="none"
                      onChange={(e) => {
                        onChangeInput(e.target.value);
                        handleChange("address_line_1", e.target.value, false);
                      }}
                      as="input"
                      className={clsx(
                        classNameFormField,
                        "relative flex min-h-[56px] w-full items-center rounded-lg bg-transparent px-3 py-[11px] transition-colors",
                        !ignoreValidation &&
                          "border-transparent valid:focus:outline-none valid:focus:ring-2 valid:focus:ring-primary-700 invalid:focus:ring-2 invalid:focus:ring-red-700 dark:focus:ring-primary-dark-700  dark:invalid:focus:ring-red-dark-700"
                      )}
                      placeholder="Address Line 1"
                    />
                  </div>

                  <Combobox.Options
                    as="div"
                    className="text-primary bg-dropdown border-separator absolute z-10 min-w-[250px] origin-top-left scale-100 transform rounded-lg border !p-1 font-medium opacity-100 shadow-lg focus:outline-none"
                  >
                    {suggestions.map(({ description, place_id }) => (
                      <Combobox.Option
                        key={place_id}
                        value={place_id}
                        as="div"
                        className="flex  cursor-pointer items-center gap-x-3 rounded-md px-2.5 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700 "
                      >
                        <AddressIcon className="text-neutral-500 h-4 w-4" />
                        <div className="flex flex-col">
                          <span className="text-sm">{description}</span>
                        </div>
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </div>
              )}
            </Combobox>
          </div>

          <AddressInput
            rules={rules}
            inForm={true}
            onChange={(e) => handleChange("address_line_2", e.target.value, true)}
            value={selected?.address_line_2 ?? ""}
            hasRequiredError={hasRequiredError}
            ignoreValidation={ignoreValidation}
            placeholder="Address Line 2"
            className={classNameFormField}
          />
          <AddressInput
            inForm={true}
            rules={rules}
            onChange={(e) => handleChange("city", e.target.value, true)}
            value={selected?.city}
            hasRequiredError={hasRequiredError}
            ignoreValidation={ignoreValidation}
            placeholder="City"
            className={classNameFormField}
          />
          <div className="mb-4">
            <CellState
              isEditable
              value={selected?.state}
              onEdit={(val) => handleChange("state", val, true)}
              placeholder="State"
            />
          </div>
          <AddressInput
            inForm={true}
            rules={rules}
            onChange={(e) => handleChange("zip_code", e.target.value, true)}
            value={selected?.zip_code}
            hasRequiredError={hasRequiredError}
            ignoreValidation={ignoreValidation}
            placeholder="Zip Code"
            className={classNameFormField}
          />
        </>
      ) : isNotEmpty(value) ? (
        <div className="line-clamp-2 text-sm">
          {!!value.address_line_1 && (
            <div className={clsx("text-primary", isHeader ? "font-semibold" : "font-medium")}>
              {value.address_line_1}
            </div>
          )}
          {!!value.address_line_2 && (
            <div className="text-base-disabled dark:text-base-dark-disabled">{value.address_line_2}</div>
          )}
          {!!(value.city || value.state || value.zip_code) && (
            <div className="text-base-disabled dark:text-base-dark-disabled">
              {[value.city, value.state, value.zip_code].filter(Boolean).join(", ")}
            </div>
          )}
        </div>
      ) : (
        <CellEmpty className={className} />
      )}
    </div>
  );
};

export default CellAddress;
