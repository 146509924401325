"use client";
import clsx from "clsx";
import Skeleton from "components/Skeleton";
import Tooltip from "components/Tooltip";
import CellEmpty from "./CellEmpty";

type CellProgressProps = {
  value: number;
  isLoading?: boolean;
  isEditable?: boolean;
  className?: string;
};

type ProgressBarProps = { value: number; inCard?: boolean };

function CellProgress({ value, isLoading = false, isEditable = false, className }: CellProgressProps) {
  if (isLoading) {
    return (
      <div>
        <Skeleton className="h-2 max-w-full" />
      </div>
    );
  }

  if ((value === undefined || value === null) && !isEditable) return <CellEmpty className={className} />;

  return (
    <div className={clsx("flex min-h-[24px] w-full items-center", className)}>
      <ProgressBar value={value} />
    </div>
  );
}

function ProgressBar({ value }: ProgressBarProps) {
  const _value = `${value}%`;
  return (
    <Tooltip title={_value + " complete"} className="text-xs">
      <div
        className="w-full cursor-pointer rounded-full bg-neutral-300 dark:bg-gray-700"
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <div
          style={{ width: _value }}
          className={clsx("h-2 rounded-full", {
            "bg-red-700 dark:bg-red-dark-700": value < 30,
            "bg-yellow-700 dark:bg-yellow-dark-700": value >= 30 && value < 60,
            "bg-primary-700 dark:bg-primary-dark-700": value >= 60 && value < 80,
            "bg-green-700 dark:bg-green-dark-700": value >= 80,
          })}
        />
      </div>
    </Tooltip>
  );
}

export default CellProgress;
