import clsx from "clsx";
import noop from "lodash/noop";
import { XIcon } from "lucide-react";
import { useRef } from "react";
import { useHover } from "usehooks-ts";

import Skeleton from "components/Skeleton";
import EllipisWithTooltip from "components/EllipisWithTooltip";
import CellEmpty from "./CellEmpty";

type CellBadgeProps = {
  value: string;
  color?: string;
  isLoading?: boolean;
  className?: string;
  isEditable?: boolean;
  isHeader?: boolean;
  onRemoveClick?: () => void;
  onClick?: () => void;
};

const CellBadge = ({
  value,
  isHeader,
  className = "",
  onRemoveClick,
  isEditable,
  isLoading,
  color,
  onClick = noop
}: CellBadgeProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 max-w-full" />
      </div>
    );
  }

  if (!value) return <CellEmpty className={className} />;

  return (
    <div className={className} onClick={onClick}>
      <div
        className={clsx(
          `h inline-flex max-w-full cursor-pointer rounded-lg border border-transparent bg-neutral-200 px-1.5 py-1 text-xs transition-opacity duration-100 hover:border hover:border-gray-500 hover:opacity-80 dark:bg-neutral-dark-200`,
          !!color && "dark:text-gray-900"
        )}
        ref={hoverRef}
        style={{
          backgroundColor: color
        }}
      >
        <div className="relative inline-flex w-full">
          <EllipisWithTooltip
            className={clsx(
              "overflow-hidden  overflow-ellipsis whitespace-nowrap text-sm",
              isEditable && "opacity-0",
              isHeader ? "font-semibold" : "font-medium"
            )}
          >
            {value}
          </EllipisWithTooltip>

          {isEditable && (
            <div className="absolute inset-0 flex overflow-hidden">
              <EllipisWithTooltip
                className={clsx(
                  "overflow-hidden overflow-ellipsis whitespace-nowrap text-sm",
                  isHeader ? "font-semibold" : "font-medium"
                )}
              >
                {value}
              </EllipisWithTooltip>
              {onRemoveClick && (
                <button
                  className={clsx("", isHovered ? "block" : "hidden")}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemoveClick?.();
                  }}
                >
                  <XIcon className="h-4 w-4" />
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CellBadge;
