import clsx from "clsx";

type CellPlaceholderProps = {
  onClick?: () => void;
  className?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

const CellPlaceholder = ({ onClick, className = "", onKeyDown }: CellPlaceholderProps) => {
  return (
    <div
      className={clsx("flex items-center text-sm text-base-disabled dark:text-base-dark-disabled", className)}
      onClick={onClick}
      tabIndex={0}
      onKeyDown={onKeyDown}
    >
      Add
    </div>
  );
};

export default CellPlaceholder;
