"use client";
import { useState } from "react";
import { TooltipProps } from "@mui/material/Tooltip";
import Tooltip from "components/Tooltip";

const ellipsisDefaultStyle: React.CSSProperties = {
  overflow: "hidden",
  overflowWrap: "break-word",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  wordBreak: "break-all"
};

const EllipisWithTooltip = ({
  children,
  style,
  as: Tag = "div",
  tooltipProps,
  ...props
}: {
  children: React.ReactNode;
  tooltipProps?: Omit<TooltipProps, "title" | "children">;
  as?: React.ElementType;
} & React.HTMLAttributes<HTMLElement>) => {
  const [hasOverflowingChildren, setHasOverflowingChildren] = useState(false);
  const [text, setText] = useState("");
  const ellipsisStyle: React.CSSProperties = { ...ellipsisDefaultStyle, ...style };

  const updateOverflow = (event: any) => {
    const el = event.target;
    if (!hasOverflowingChildren && el.scrollWidth > el.clientWidth) {
      setHasOverflowingChildren(true);
      if (el.textContent !== text) {
        setText(el.textContent);
      }
    } else {
      setHasOverflowingChildren(false);
    }
  };

  return hasOverflowingChildren ? (
    <Tooltip title={text} {...tooltipProps}>
      <div style={ellipsisStyle} {...props}>
        {children}
      </div>
    </Tooltip>
  ) : (
    <Tag style={ellipsisStyle} {...props} onMouseEnter={updateOverflow}>
      {children}
    </Tag>
  );
};

export default EllipisWithTooltip;
