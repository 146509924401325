import { useCallback, useRef, useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import isEmpty from "lodash/isEmpty";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { IAnnotation } from "components/ImageAnnotate/types";
import { isHTMLStringEmpty } from "utils/texts";
import toast, { ToastId } from "utils/toast";
import { TOAST_TYPE } from "utils/constants";
import { IAnnotationType } from "components/ImageAnnotate/types/enums";
import useUpsertRecord from "./useUpsertRecord";
import useAddRecord from "./useAddRecord";

const useSubmitFileAnnotation = ({
  tableName,
  currentProjectId,
  fileId
}: {
  tableName?: string;
  currentProjectId?: string;
  fileId?: string;
}) => {
  const [finalNoteListID, setFinalNoteListID] = useState<string | null>(null);
  const { upsertRecordAsync: upsertRecord, isLoading: upsertingRecord } = useUpsertRecord();
  const { addRecordAsync: createRecord, isLoading: creatingRecord } = useAddRecord();
  const queryClient = useQueryClient();
  const progresssToastId = useRef<ToastId | null>(null);
  const supabaseClient = useSupabaseBrowser();
  const fileNoteListRef = useRef<string | null>(null);

  const getFileNoteListId = useCallback(async () => {
    if (!fileId) return;
    fileNoteListRef.current = fileId;
    const { data: fileNoteListData, error: fileErr } = await supabaseClient
      .from("files")
      .select("id,notes_list_id")
      .eq("id", fileId);
    if (fileErr) {
      return;
    }
    if (fileNoteListData?.[0]?.notes_list_id) {
      setFinalNoteListID(fileNoteListData?.[0]?.notes_list_id);
      return;
    }
    // We add a note list id to the file and then use that to link annotation notes
    const { data: noteListData, error: noteListErr } = await createRecord({
      tableName: "notes_list",
      input: {
        project_id: currentProjectId,
        created_in: "File Modal",
        created_in_path: window.location.pathname
      }
    });
    if (noteListData?.[0]?.id) {
      await supabaseClient.from("files").update({ notes_list_id: noteListData?.[0]?.id }).eq("id", fileId);
      setFinalNoteListID(noteListData?.[0]?.id);
    }
  }, [createRecord, currentProjectId, fileId, supabaseClient]);

  const submitNoteAnnotation = useCallback(
    async (annotation: IAnnotation) => {
      const noteFields = annotation.data?.[IAnnotationType.NOTE];
      if ((!noteFields?.id && isHTMLStringEmpty(noteFields?.message || "")) || !fileId || !annotation.geometry) return;

      progresssToastId.current = toast.success("Saving...", { autoClose: false });
      try {
        const noteInput = {
          id: noteFields?.id,
          message: noteFields?.message,
          project_id: currentProjectId,
          notes_list_id: finalNoteListID
        };

        const { data: updatedNote, error: notesErr } = await upsertRecord({ tableName: "notes", input: noteInput });
        let annotationData = annotation.data;
        // if new annotation send annotation creation request tpp
        if (!noteFields?.id && updatedNote?.[0]?.id) {
          const annotationInput = {
            id: annotation.data?.id,
            x_coordinate: annotation.geometry?.x,
            y_coordinate: annotation.geometry?.y,
            note_id: updatedNote?.[0]?.id,
            file_id: fileId
          };

          const { data: newAnnotationData, error: annotationErr } = await createRecord({
            tableName: "files_annotations",
            input: annotationInput
          });
          if (!newAnnotationData?.length || !isEmpty(annotationErr)) {
            console.log(`Failed to create note annotation, error saving annotation info`, {
              extras: { error: annotationErr }
            });
            toast.update(progresssToastId.current, "Failed to save annotation, Try Again!", {
              autoClose: 2000,
              type: TOAST_TYPE.ERROR
            });
            return;
          }
          annotationData = newAnnotationData[0];
        }

        if (!updatedNote?.length || !isEmpty(notesErr)) {
          console.log(`Failed to create note annotation, error saving note`, { extras: { error: notesErr } });
          toast.update(progresssToastId.current, "Failed to save annotation, Try Again!", {
            autoClose: 2000,
            type: TOAST_TYPE.ERROR
          });
          return;
        }

        const noteData = updatedNote[0];

        // if annotation already existed nothing else to do as we can only edit the note during update
        if (noteFields?.id) {
          toast.update(progresssToastId.current, "Saved Successfully!", { autoClose: 2000 });
          queryClient.refetchQueries({ queryKey: ["table", tableName] });
          queryClient.refetchQueries({ queryKey: ["table", tableName, fileId] });
          return {
            ...annotation,
            data: {
              ...annotation.data,
              [IAnnotationType.NOTE]: {
                ...annotation.data?.[IAnnotationType.NOTE],
                ...noteData
              },
              type: IAnnotationType.NOTE
            }
          };
        }

        toast.update(progresssToastId.current, "Saved Successfully!", { autoClose: 2000 });
        queryClient.refetchQueries({ queryKey: ["table", tableName] });
        queryClient.refetchQueries({ queryKey: ["table", tableName, fileId] });
        return {
          ...annotation,
          data: {
            id: annotationData.id,
            [IAnnotationType.NOTE]: {
              ...noteData
            },
            type: IAnnotationType.NOTE
          }
        };
      } catch (err) {
        console.log(`Failed to create note annotation: ${err}`);
        toast.update(progresssToastId.current, "Failed to save annotation, Try Again!", {
          autoClose: 2000,
          type: TOAST_TYPE.ERROR
        });
      }
    },
    [upsertRecord, createRecord, queryClient, tableName, currentProjectId, fileId, finalNoteListID]
  );

  useEffect(() => {
    if (!fileId || fileNoteListRef?.current === fileId) return;
    getFileNoteListId();
  }, [fileId, getFileNoteListId]);

  return {
    submitNoteAnnotation,
    loading: creatingRecord || upsertingRecord
  };
};

export default useSubmitFileAnnotation;
