import clsx from "clsx";

import Skeleton from "components/Skeleton";
import CellEmpty from "./CellEmpty";
import CellPlaceholder from "./CellPlaceholder";

export type CellNumberFields = {
  value: number;
};

type CellNumberProps = CellNumberFields & {
  isLoading?: boolean;
  isEditable?: boolean;
  onClick?: () => void;
  className?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  isHeader?: boolean;
  textColor?: string;
};

const CellNumber = ({
  value,
  isLoading = false,
  isEditable = false,
  onClick,
  className = "",
  onKeyDown,
  isHeader = false,
  textColor
}: CellNumberProps) => {
  if (isLoading) {
    return <Skeleton className="h-3 w-16" />;
  }

  if ((value === undefined || value === null) && !isEditable) return <CellEmpty className={className} />;

  if ((value === undefined || value === null) && isEditable) {
    return <CellPlaceholder onClick={onClick} className={className} onKeyDown={onKeyDown} />;
  }

  return (
    <span
      className={clsx("text-primary w-full text-sm", className, isHeader && "font-semibold")}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={isEditable ? 0 : undefined}
      style={{
        color: textColor
      }}
    >
      {value}
    </span>
  );
};

export default CellNumber;
