import React, { useState } from "react";
import { usePopper } from "react-popper";
import Avatar from "components/Avatar";
import { AvatarSize } from "utils/constants";
import { RecordItem } from "types/common";
import generateInitials from "utils/generateInitials";
import { ViewAction } from "types/baTypes";
import { IAnnotation } from "../types";
import Editor from "./Editor";
import Point from "./Point";

type HighlightProps = {
  annotation: IAnnotation;
  active: boolean;
  handleMouseEnter: (annotation: IAnnotation) => void;
  handleMouseLeave: (annotation: IAnnotation) => void;
  onDeleteAnnotation?: (annotation: IAnnotation) => void;
  currentUser?: RecordItem;
  actions?: ViewAction[];
  tableName?: string;
  tablePath?: string;
  onSubmit?: (e: any) => void;
};

const Highlight = React.memo(
  ({
    annotation,
    active = false,
    handleMouseEnter,
    handleMouseLeave,
    onSubmit,
    currentUser,
    actions,
    tableName,
    tablePath
  }: HighlightProps) => {
    const [targetElement, setTargetElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const note = annotation.data?.[annotation?.data?.type];

    const { styles, attributes } = usePopper(targetElement, popperElement, {
      placement: "bottom",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8]
          }
        }
      ]
    });
    const onMouseEnter = () => handleMouseEnter(annotation);
    const onMouseLeave = () => handleMouseLeave(annotation);
    return (
      <Point
        annotation={annotation}
        ref={setTargetElement}
        {...{ onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave }}
      >
        <>
          <Avatar
            size={AvatarSize.XS}
            src={note?.id ? note?.created_by?.path : currentUser?.files?.path}
            initials={
              note?.id ? generateInitials(note?.created_by?.full_name) : generateInitials(currentUser?.full_name)
            }
            type={note?.id ? note?.created_by?.type : currentUser?.type}
          />
          {active && (
            <div ref={setPopperElement as any} style={styles.popper} {...attributes.popper}>
              <Editor
                annotation={annotation}
                isEditable={false}
                actions={actions}
                onSubmit={onSubmit}
                tableName={tableName}
                tablePath={tablePath}
              />
            </div>
          )}
        </>
      </Point>
    );
  }
);

Highlight.displayName = "HightLight";

export default Highlight;
