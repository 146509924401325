import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import dynamic from "next/dynamic";

import { CellRules } from "types/baTypes";
import Tooltip from "components/Tooltip";
import { formatDateTime, formatDateFull } from "utils/format";
import Skeleton from "components/Skeleton";
import CellEmpty from "./CellEmpty";

const DatePicker = dynamic(() => import("components/DatePicker"), { ssr: false });

type CellDateProps = {
  value: string;
  isEditable?: boolean;
  onEdit?: (value: string) => void;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  isEditing?: boolean;
  inForm?: boolean;
  rules?: CellRules;
  showRelativeTime?: boolean;
  isHeader?: boolean;
};

const CellDateTime = ({
  value = "",
  isLoading = false,
  isEditable = false,
  onEdit,
  className = "",
  inForm = false,
  showRelativeTime = false,
  isHeader = false
}: CellDateProps) => {
  const [selectedDateTime, setDateTime] = useState(() => (value ? new Date(value) : null));

  useEffect(() => {
    if (value) {
      setDateTime(new Date(value));
    } else {
      setDateTime(null);
    }
  }, [value]);

  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="max-10 h-3" />
      </div>
    );
  }

  return (
    <div className={clsx("w-full text-sm font-medium", className)}>
      {isEditable ? (
        <DatePicker
          selected={selectedDateTime}
          onChange={(dateTimeUpdated) => {
            if (dateTimeUpdated === null) {
              onEdit?.("");
              return;
            }
            setDateTime(dateTimeUpdated);
            onEdit?.(dayjs(dateTimeUpdated).toISOString());
          }}
          showTimeSelect
          dateFormat="MMMM d, yyyy h:mm aa"
          className={clsx(
            "min-h-[56px] w-full cursor-pointer rounded-lg border-none bg-transparent text-sm transition-colors hover:bg-gray-150 focus:bg-gray-150 focus:text-md  dark:hover:bg-gray-600 dark:focus:bg-gray-600",
            inForm
              ? "border-transparent !bg-gray-100 text-md transition-colors hover:bg-gray-150 focus:border-transparent focus:!bg-transparent focus:ring-2 focus:ring-primary-700 dark:bg-gray-600/60 dark:hover:bg-gray-600 dark:focus:bg-transparent dark:focus:ring-primary-dark-700"
              : ""
          )}
          placeholderText="Add"
        />
      ) : selectedDateTime ? (
        <Tooltip title={formatDateFull(selectedDateTime.toString())} className="inline">
          <span className={clsx(isHeader && "font-semibold")}>
            {formatDateTime(selectedDateTime.toString(), true, showRelativeTime)}
          </span>
        </Tooltip>
      ) : (
        <CellEmpty />
      )}
    </div>
  );
};

export default React.memo(CellDateTime);
