"use client";
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import dynamic from "next/dynamic";

import Skeleton from "components/Skeleton";
import { CellRules } from "types/baTypes";
import Tooltip from "components/Tooltip";
import { formatDate } from "utils/format";
import CellEmpty from "./CellEmpty";

const DatePicker = dynamic(() => import("components/DatePicker"), { ssr: false });

type CellDateProps = {
  value: string;
  isEditable?: boolean;
  onEdit?: (value: string) => void;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  isEditing?: boolean;
  inForm?: boolean;
  rules?: CellRules;
  isHeader?: boolean;
};

const CellDate = ({
  value = "",
  isLoading = false,
  isEditable = false,
  onEdit,
  className = "",
  inForm = false,
  isHeader = false
}: CellDateProps) => {
  const [selectedDate, setDate] = useState(() => (value ? dayjs(value).toDate() : null));

  useEffect(() => {
    if (value) {
      setDate(dayjs(value).toDate());
    } else {
      setDate(null);
    }
  }, [value]);

  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="max-10 h-3" />
      </div>
    );
  }

  return (
    <div className={clsx("w-full text-sm font-medium", className)}>
      {isEditable ? (
        <DatePicker
          selected={selectedDate}
          onChange={(dateUpdated) => {
            if (dateUpdated === null) {
              onEdit?.("");
              return;
            }

            setDate(dayjs(dateUpdated).toDate());
            onEdit?.(dayjs(dateUpdated).toString());
          }}
          className={clsx(
            "min-h-[56px] w-full cursor-pointer rounded-lg border-none bg-transparent text-sm hover:bg-neutral-200 focus:text-md dark:hover:bg-neutral-dark-200",
            inForm
              ? "border-transparent !bg-gray-100 text-md transition-colors hover:bg-gray-150 focus:border-transparent focus:!bg-transparent focus:ring-2 focus:ring-primary-700 dark:!bg-gray-600/60 dark:hover:bg-gray-600 dark:focus:!bg-transparent dark:focus:ring-primary-dark-700"
              : ""
          )}
          placeholderText="Add"
        />
      ) : selectedDate ? (
        <Tooltip title={formatDate(selectedDate.toString())} className="inline">
          <span className={clsx(isHeader && "font-semibold")}>{formatDate(selectedDate.toString())}</span>
        </Tooltip>
      ) : (
        <CellEmpty />
      )}
    </div>
  );
};

export default CellDate;
