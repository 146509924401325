import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { SearchIcon, XIcon, Check } from "lucide-react";
import Popper from "@mui/material/Popper";

type SearchInputProps = {
  value: string;
  rounded?: boolean;
  placeholder?: string;
  autofocus?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onEnter?: (value: string) => void;
  className?: string;
  mRef?: React.RefObject<HTMLInputElement> | null;
  inputClassName?: string;
  disabled?: boolean;
  onFocus?: () => void;
  indexFacets?: string[];
  onFacetSelected?: (facet: string) => void;
  activeFacet?: string;
  facetSearchClicked?: () => void;
};

const SearchInput = ({
  value = "",
  rounded = true,
  placeholder = "Search",
  autofocus = false,
  onChange,
  onEnter,
  onClear,
  className = "",
  mRef = null,
  inputClassName = "",
  onFocus,
  indexFacets,
  onFacetSelected,
  activeFacet,
  facetSearchClicked,
  ...rest
}: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const facetsRef = useRef<HTMLDivElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onEnter?.(value);
    }
  };

  const handleOnFocus = useCallback(
    (e: any) => {
      setAnchorEl(e.currentTarget);
      setIsFocused(true);
      onFocus?.();
    },
    [onFocus]
  );

  const onClearClicked = useCallback(() => {
    onClear?.();
    setIsFocused(false);
  }, [onClear]);

  const onFacetSelectClicked = useCallback(
    (facet: string) => {
      inputRef.current?.focus();
      onFacetSelected?.(facet);
      setIsFocused(false);
    },
    [onFacetSelected]
  );

  useEffect(() => {
    if (autofocus && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autofocus]);

  useEffect(() => {
    if (!isFocused) {
      return;
    }
    const handleClickOutside = (event: TouchEvent | MouseEvent) => {
      const isInSearchComponents =
        inputRef.current?.contains(event.target as Node) ||
        facetsRef.current?.contains(event.target as Node) ||
        anchorEl?.contains(event.target as Node);
      if (!isInSearchComponents && anchorEl) {
        setIsFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorEl, isFocused]);

  return (
    <div
      data-testid="SearchInput"
      className={clsx(
        "relative flex flex-grow items-center bg-gray-100",
        className,
        rounded ? "rounded-full px-2 py-1.5" : "px-2 py-4"
      )}
      ref={mRef}
    >
      <span className={clsx("p-1", rounded ? "" : "")}>
        <SearchIcon size="14px" className="dark:text-neutral-300" />
      </span>
      {activeFacet ? (
        <span className={clsx("p-0.5 text-[13px]", rounded ? "top-2" : "top-4.5")}>{activeFacet + "> "}</span>
      ) : null}
      <input
        className={clsx(
          "flex w-full flex-grow items-center",
          "text-secondary text-sm placeholder-base-disabled",
          "bg-gray-100 text-[13px] ring-0  transition-colors  hover:bg-gray-200  dark:bg-white/5 dark:ring-inset  dark:hover:bg-gray-600",
          "px-3 focus:[&:not(:focus-visible)]:outline-none",
          "border-separator border-0 dark:border dark:bg-neutral-dark-0 dark:text-neutral-300 dark:placeholder-neutral-300",
          rounded ? "" : "py-4",
          inputClassName,
          activeFacet ? `pl-[${(activeFacet.length + 3) * 8 + 50}px]` : ""
        )}
        value={value}
        onFocus={handleOnFocus}
        onChange={onChange}
        placeholder={placeholder}
        onKeyPress={handleKeyPress}
        role="search"
        ref={inputRef}
        {...rest}
      />
      {indexFacets?.length ? (
        <Popper className="z-50 w-auto" open={isFocused} anchorEl={anchorEl}>
          <div
            className="dark:bg-neutral-dark-800 border-separator dark:border-neutral-dark-600 w-full min-w-[200px] rounded-md border bg-white px-3 py-3 text-sm shadow-md"
            ref={facetsRef}
          >
            <p className="mt-2">Search Within: </p>
            <ul className="py-2">
              {indexFacets.map((facet) => (
                <li
                  key={facet}
                  onClick={() => onFacetSelectClicked?.(facet)}
                  className="px-4 py-2 hover:bg-neutral-200 dark:hover:bg-neutral-dark-200"
                >
                  {facet}
                </li>
              ))}
            </ul>
          </div>
        </Popper>
      ) : null}
      {activeFacet ? (
        <button
          type="button"
          className={clsx(
            " rounded-full p-1 pr-3 outline-none hover:bg-neutral-200 focus:bg-neutral-200  dark:hover:bg-neutral-dark-200 dark:focus:bg-neutral-dark-200"
          )}
          onClick={facetSearchClicked}
        >
          <Check
            size="16px"
            className="rounded-full bg-neutral-300 p-0.5 text-neutral-dark-0 dark:bg-neutral-dark-300 dark:text-neutral-0"
          />
        </button>
      ) : null}
      {(value.length > 0 || (indexFacets?.length && isFocused) || activeFacet) && (
        <button
          type="button"
          className={clsx(
            "rounded-full p-1 outline-none hover:bg-neutral-200 focus:bg-neutral-200  dark:hover:bg-neutral-dark-200 dark:focus:bg-neutral-dark-200"
          )}
          onClick={onClearClicked}
        >
          <XIcon
            size="16px"
            className="rounded-full bg-neutral-300 p-0.5 text-neutral-dark-0 dark:bg-neutral-dark-300 dark:text-neutral-0"
          />
        </button>
      )}
    </div>
  );
};

export default SearchInput;
