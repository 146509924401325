import { useContext, useMemo } from "react";
import { AudioPlayerContext } from "context/AudioPlayerContext";
import { Player, PlayerMeta } from "types/common";

const useAudioPlayer = (data?: PlayerMeta) => {
  const player = useContext(AudioPlayerContext);

  return useMemo(
    () =>
      ({
        ...(player || {}),
        play() {
          player?.play(data);
        },
        toggle() {
          player?.toggle(data);
        },
        get playing() {
          return player?.isPlaying(data);
        },
        getPlayerReady() {
          player?.getPlayerReady(data);
        },
      } as Player),
    [player, data]
  );
};

export default useAudioPlayer;
