import React, { useMemo, useRef } from "react";
import clsx from "clsx";
import { useHover } from "usehooks-ts";
import { XIcon } from "lucide-react";
import noop from "lodash/noop";

import Tooltip from "components/Tooltip";
import Skeleton from "components/Skeleton";
import { getTextWidth } from "utils/texts";
import CellEmpty from "./CellEmpty";
import { RecordItem } from "types/common";

export type CellSelectProps = {
  value: string;
  isLoading?: boolean;
  isInOptions?: boolean;
  isMultiple?: boolean;
  isEditable?: boolean;
  onRemoveClick?: () => void;
  additionalText?: string;
  className?: string;
  isHeader?: boolean;
  onClick?: () => void;
  tooltipProps?: RecordItem;
};

const CellSelect = ({
  value,
  isLoading,
  isInOptions,
  isMultiple,
  isEditable,
  onRemoveClick,
  additionalText = "",
  className = "",
  isHeader = false,
  onClick = noop,
  tooltipProps
}: CellSelectProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  const isBigText = useMemo(() => {
    if (!value || typeof value !== "string" || !isInOptions) return false;
    const textWidth = getTextWidth(value);

    return textWidth > 260;
  }, [value, isInOptions]);

  if (isLoading) {
    return <Skeleton className="h-3 max-w-[102px]" />;
  }

  if (!value && !isEditable) {
    return <CellEmpty className={className} />;
  }

  if (isMultiple) {
    return (
      <div
        className={clsx(
          "relative inline-flex flex-nowrap items-center gap-x-1 overflow-hidden rounded-full bg-neutral-200 px-2 py-1 dark:bg-neutral-dark-200",
          className
        )}
        ref={hoverRef}
        onClick={onClick}
      >
        <div className="relative flex">
          <div
            className={clsx(
              "overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm",
              isEditable && "max-w-[200px] opacity-0",
              isHeader ? "font-semibold" : "font-medium"
            )}
          >
            {typeof value === "object" ? JSON.stringify(value) : value}
          </div>
          {isEditable && (
            <div className="absolute inset-0 flex overflow-hidden">
              <div className={"overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm font-medium"}>{value}</div>
              {onRemoveClick && (
                <button
                  className={clsx("", isHovered ? "block" : "hidden")}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemoveClick?.();
                  }}
                >
                  <XIcon className="h-4 w-4" />
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  const finalValue = typeof value === "object" ? JSON.stringify(value) : value + additionalText;

  return (
    <div
      data-testid="CellSelect"
      className={clsx(
        "overflow-hidden overflow-ellipsis whitespace-nowrap text-sm",
        isInOptions && "max-w-[260px] overflow-hidden overflow-ellipsis !py-0.5",
        className,
        isHeader ? "font-semibold" : "font-medium"
      )}
      onClick={onClick}
    >
      {isBigText ? (
        <Tooltip title={finalValue} {...tooltipProps}>
          <span>{finalValue}</span>
        </Tooltip>
      ) : (
        finalValue
      )}
    </div>
  );
};

export default React.memo(CellSelect);
