"use client";
import { useEffect, useRef, useState } from "react";
import { XIcon } from "lucide-react";
import useAudioPlayer from "hooks/useAudioPlayer";
import IconButton from "components/IconButton";
import { IconButtonSize } from "components/IconButton/utils";
import ForwardButton from "./ForwardButton";
import MuteButton from "./MuteButton";
import PlaybackRateButton from "./PlaybackRateButton";
import PlayButton from "./PlayButton";
import RewindButton from "./RewindButton";
import Slider from "./Slider";
import { formatHumanTime } from "./utils";

const AudioPlayer = ({ hideCloseButton }: { hideCloseButton?: boolean }) => {
  const player = useAudioPlayer();

  const wasPlayingRef = useRef<boolean>(false);

  const [currentTime, setCurrentTime] = useState<number | null | undefined>(player.currentTime);

  useEffect(() => {
    setCurrentTime(null);
  }, [player.currentTime]);

  if (!player.meta) {
    return null;
  }

  return (
    <div className="relative flex items-center gap-6 bg-neutral-0 px-4 py-4 backdrop-blur-sm dark:bg-neutral-dark-0 md:px-6 lg:rounded-xl lg:shadow-200">
      {!hideCloseButton && (
        <IconButton
          icon={XIcon}
          size={IconButtonSize.XS}
          className="absolute right-3 top-4 lg:right-3 lg:top-2.5"
          onClick={() => player.close?.()}
        />
      )}
      <div className="hidden md:block">
        <PlayButton player={player} size="medium" />
      </div>
      <div className="mb-[env(safe-area-inset-bottom)] flex flex-1 flex-col gap-3 overflow-hidden p-1">
        <span className="truncate text-center text-sm font-semibold leading-6 md:text-left" title={player.meta.title}>
          {player.meta.title}
        </span>
        <div className="flex justify-between gap-6">
          <div className="flex items-center md:hidden">
            <MuteButton player={player} />
          </div>
          <div className="flex flex-none items-center gap-4">
            <RewindButton player={player} />
            <div className="md:hidden">
              <PlayButton player={player} size="small" />
            </div>
            <ForwardButton player={player} />
          </div>
          <Slider
            label="Current time"
            maxValue={player.duration as number}
            step={1}
            value={[currentTime ?? player.currentTime]}
            onChange={([v]) => setCurrentTime(v)}
            onChangeEnd={(value) => {
              player.seek?.(value);
              if (wasPlayingRef.current) {
                player.play();
              }
            }}
            numberFormatter={{ format: formatHumanTime } as Intl.NumberFormat}
            onChangeStart={() => {
              wasPlayingRef.current = player.playing as boolean;
              player.pause?.();
            }}
          />
          <div className="flex items-center gap-4">
            <div className="flex items-center">
              <PlaybackRateButton player={player} />
            </div>
            <div className="hidden items-center md:flex">
              <MuteButton player={player} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
