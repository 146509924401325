import { RecordItem } from "types/common";
import { IAnnotation } from "../types";
import { SELECTOR_TYPES } from "../types/enums";
import { getCoordPercentage } from "./offsetCoordinates";
const MARGIN = 6;

const marginToPercentage = (container: HTMLElement) => ({
  marginX: (MARGIN / container.offsetWidth) * 100,
  marginY: (MARGIN / container.offsetHeight) * 100,
});

export function intersects(
  { x, y }: { x: number; y: number },
  geometry: { x: number; y: number },
  container: HTMLElement
) {
  const { marginX, marginY } = marginToPercentage(container);

  if (x < geometry.x - marginX) return false;
  if (y < geometry.y - marginY) return false;
  if (x > geometry.x + marginX) return false;
  if (y > geometry.y + marginY) return false;

  return true;
}

export function area(container: HTMLElement) {
  const { marginX, marginY } = marginToPercentage(container);
  return marginX * marginY;
}

export const methods = {
  onClick(annotation: IAnnotation, e: TouchEvent): IAnnotation | undefined {
    if (!annotation?.geometry) {
      return {
        ...annotation,
        selection: {
          ...annotation?.selection,
          showEditor: true,
          mode: "EDITING",
        },
        geometry: {
          ...annotation?.geometry,
          ...getCoordPercentage(e),
          type: SELECTOR_TYPES.POINT,
        },
      };
    } else {
      return;
    }
  },
};

const PointSelector: RecordItem = {
  TYPE: SELECTOR_TYPES.POINT,
  intersects,
  area,
  methods,
};

export default PointSelector;
