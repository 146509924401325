import clsx from "clsx";
import noop from "lodash/noop";
import React, { useEffect, useRef, useState } from "react";
import ImageComponent from "components/Image";
import { RecordItem } from "types/common";
import { ViewAction } from "types/baTypes";
import Highlight from "./components/Highlight";
import Selector from "./components/Selector";
import compose from "./utils/compose";
import isMouseHovering from "./utils/isMouseHovering";
import PointSelector from "./utils/PointSelector";
import withRelativeMousePos from "./utils/withRelativeMousePos";

import "./ImageAnnotate.css";
import { IAnnotation, IAnnotationProps } from "./types";

interface ImageAnnotateProps extends IAnnotationProps {
  className?: string;
  containerClassName?: string;
  onDeleteAnnotation?: (annotation: IAnnotation) => void;
  currentUser?: RecordItem;
  actions?: ViewAction[];
  tableName?: string;
  tablePath?: string;
}

const ImageAnnotate = ({
  src,
  className,
  allowTouch,
  innerRef,
  value,
  onChange = noop,
  onSubmit = noop,
  activeAnnotationComparator = (a, b) => a === b,
  annotations = [],
  disableSelector = false,
  disableEditor = false,
  onDeleteAnnotation,
  disableAnnotation = false,
  currentUser,
  actions,
  tableName,
  tablePath,
  ...props
}: ImageAnnotateProps & RecordItem) => {
  const targetRef = useRef<HTMLDivElement | null>(null);
  const containerElem = useRef<HTMLImageElement | null>(null);
  const [currActive, setCurrActive] = useState<IAnnotation | undefined>();

  const timeout = useRef<NodeJS.Timeout | undefined>();

  const onHightLightMouseEnter = (annotation: IAnnotation) => {
    clearTimeout(timeout.current);
    setCurrActive(annotation);
  };

  const onHighlightMouseLeave = () => {
    timeout.current = setTimeout(() => setCurrActive(undefined), 50);
  };

  useEffect(() => {
    if (allowTouch) {
      addTargetTouchEventListeners();
    } else {
      removeTargetTouchEventListeners();
    }
  }, [allowTouch]);

  const addTargetTouchEventListeners = () => {
    // Safari does not recognize touch-action CSS property,
    // so we need to call preventDefault ourselves to stop touch from scrolling
    // Event handlers must be set via ref to enable e.preventDefault()
    // https://github.com/facebook/react/issues/9809
    if (!targetRef?.current) return;

    targetRef.current.ontouchstart = onTouchStart;
    targetRef.current.ontouchend = onTouchEnd;
    targetRef.current.ontouchmove = onTargetTouchMove;
    targetRef.current.ontouchcancel = onTargetTouchLeave;
  };

  const removeTargetTouchEventListeners = () => {
    if (!targetRef?.current) return;
    targetRef.current.ontouchstart = undefined;
    targetRef.current.ontouchend = undefined;
    targetRef.current.ontouchmove = undefined;
    targetRef.current.ontouchcancel = undefined;
  };

  const callSelectorMethod = (methodName: string, e: React.MouseEvent | TouchEvent) => {
    if (disableAnnotation) {
      return;
    }
    if (!!props[methodName]) {
      props[methodName](e);
    } else {
      if (PointSelector.methods[methodName]) {
        const val = PointSelector.methods[methodName](value, e);
        if (typeof val !== "undefined") {
          onChange(val);
        }
      }
    }
  };

  const setInnerRef = (el: HTMLImageElement) => {
    containerElem.current = el;
    props.relativeMousePos?.innerRef(el);
    innerRef?.(el);
  };

  const getTopAnnotationAt = (x: number, y: number) => {
    if (!containerElem?.current) return;

    const intersections = annotations
      .map((annotation) => {
        const { geometry } = annotation;
        return PointSelector.intersects({ x, y }, geometry, containerElem.current) ? annotation : undefined;
      })
      .filter((a) => !!a);

    return intersections[0];
  };

  const shouldAnnotationBeActive = (annotation: IAnnotation, top?: IAnnotation) => {
    if (props.activeAnnotations) {
      const isActive = !!props.activeAnnotations.find((active: IAnnotation) =>
        activeAnnotationComparator(annotation, active)
      );
      return isActive || top === annotation || activeAnnotationComparator(annotation, currActive);
    } else {
      return top === annotation || activeAnnotationComparator(currActive, annotation);
    }
  };

  const onTouchStart = (e: TouchEvent) => callSelectorMethod("onTouchStart", e);
  const onTouchEnd = (e: TouchEvent) => callSelectorMethod("onTouchEnd", e);
  const onMouseMove = (e: React.MouseEvent) => callSelectorMethod("onMouseMove", e);
  const onTouchMove = (e: TouchEvent) => callSelectorMethod("onTouchMove", e);
  const onMouseUp = (e: React.MouseEvent) => callSelectorMethod("onMouseUp", e);
  const onMouseDown = (e: React.MouseEvent) => callSelectorMethod("onMouseDown", e);
  const onClick = (e: React.MouseEvent) => callSelectorMethod("onClick", e);

  const onTargetMouseMove = (e: React.MouseEvent) => {
    props.relativeMousePos.onMouseMove(e);
    onMouseMove(e);
  };
  const onTargetTouchMove = (e: TouchEvent) => {
    props.relativeMousePos.onTouchMove(e);
    onTouchMove(e);
  };
  const onTargetMouseLeave = (e: React.MouseEvent) => {
    props.relativeMousePos.onMouseLeave(e);
  };
  const onTargetTouchLeave = (e: TouchEvent | React.TouchEvent) => {
    props.relativeMousePos.onTouchLeave(e);
  };

  const topAnnotationAtMouse = getTopAnnotationAt(props.relativeMousePos.x, props.relativeMousePos.y);

  return (
    <div
      data-testid="ImageAnnotate"
      className={clsx("relative clear-both h-full", allowTouch ? "touch-pinch-zoom" : "touch-auto")}
      onMouseLeave={onTargetMouseLeave}
      onTouchCancel={onTargetTouchLeave}
      ref={props.isMouseHovering.innerRef}
    >
      <ImageComponent
        fill
        className={clsx("!relative block !w-fit object-contain", className)}
        src={src}
        alt=""
        mRef={setInnerRef}
      />
      <div
        className="absolute bottom-0 left-0 right-0 top-0"
        ref={targetRef}
        onClick={onClick}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        onMouseMove={onTargetMouseMove}
      />
      {!disableAnnotation &&
        annotations.map((annotation) => (
          <Highlight
            key={annotation.data?.id}
            annotation={annotation}
            handleMouseEnter={onHightLightMouseEnter}
            handleMouseLeave={onHighlightMouseLeave}
            active={shouldAnnotationBeActive(annotation, topAnnotationAtMouse)}
            onDeleteAnnotation={onDeleteAnnotation}
            currentUser={currentUser}
            actions={actions}
            tableName={tableName}
            tablePath={tablePath}
            onSubmit={onSubmit}
          />
        ))}
      {!disableSelector && value && value.geometry ? (
        <Selector
          annotation={value}
          disableEditor={disableEditor}
          onChange={onChange}
          onSubmit={onSubmit}
          currentUser={currentUser}
        />
      ) : null}
    </div>
  );
};

export default compose(isMouseHovering(), withRelativeMousePos())(ImageAnnotate);
