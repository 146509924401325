import { useRef } from "react";
import clsx from "clsx";
import { useHover } from "usehooks-ts";
import { XIcon } from "lucide-react";
import noop from "lodash/noop";

import Avatar from "components/Avatar";
import { AvatarSize } from "utils/constants";
import generateInitials from "utils/generateInitials";
import { PeopleType } from "utils/constants";
import Skeleton from "components/Skeleton";
import { CellPeopleConfigProps } from "types/apiTypes";
import CellEmpty from "./CellEmpty";

export type CellPeopleFields = {
  path: string;
  full_name: string;
  type?: PeopleType;
};

type CellPeopleProps = CellPeopleFields & {
  onRemoveClick?: () => void;
  isEditable?: boolean;
  isLoading?: boolean;
  isInOptions?: boolean;
  isHeader?: boolean;
  onClick?: () => void;
  peopleConfig?: CellPeopleConfigProps;
};

const CellPeople = ({
  path = "",
  full_name: fullName = "",
  onRemoveClick,
  isEditable = false,
  isLoading = false,
  type,
  isInOptions = false,
  isHeader = false,
  onClick = noop,
  peopleConfig
}: CellPeopleProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const showAvatarOnly = !!peopleConfig?.showAvatarOnly;

  if (isLoading) {
    return <Skeleton className="h-3 w-24" />;
  }

  if (!fullName) return <CellEmpty />;

  if (showAvatarOnly && !isEditable && !isInOptions) {
    return (
      <div
        className={clsx(
          "relative inline-flex flex-nowrap items-center gap-x-1 rounded-full bg-neutral-200 dark:bg-neutral-dark-0",
          isInOptions && "max-w-[260px]"
        )}
        data-testid="CellPeople"
        ref={hoverRef}
        onClick={onClick}
      >
        <Avatar
          hasTooltip
          src={path}
          initials={generateInitials(fullName)}
          size={AvatarSize.XS}
          type={type}
          title={fullName}
        />
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "relative inline-flex flex-nowrap items-center gap-x-1 rounded-full bg-neutral-200 pl-0 pr-2 dark:bg-neutral-dark-0",
        isInOptions && "max-w-[260px]"
      )}
      data-testid="CellPeople"
      ref={hoverRef}
      onClick={onClick}
    >
      <div className="relative">
        <Avatar src={path} initials={generateInitials(fullName)} size={AvatarSize.XS} type={type} />
      </div>
      <div className="relative flex">
        {/* Ghost text to know the text width to show the remove icon without increase width */}
        <div
          className={clsx(
            "overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm",
            isEditable && "opacity-0",
            isInOptions && "max-w-[220px]",
            isHeader ? "font-semibold" : "font-medium"
          )}
        >
          {fullName}
        </div>
        {isEditable && (
          <div className="absolute inset-0 flex overflow-hidden">
            <div className={"overflow-hidden overflow-ellipsis whitespace-nowrap text-sm font-medium"}>{fullName}</div>
            {onRemoveClick && (
              <button
                className={clsx("", isHovered ? "block" : "hidden")}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveClick?.();
                }}
              >
                <XIcon className="h-4 w-4" />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CellPeople;
