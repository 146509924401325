import { useCallback, useMemo, useRef } from "react";
import { useHover } from "usehooks-ts";
import clsx from "clsx";
import sortBy from "lodash/sortBy";
import { ChevronRight, FocusIcon, MapPinIcon } from "lucide-react";
import isEmpty from "lodash/isEmpty";

import { useRouter } from "next/navigation";
import { getColumnOptionsLookupPropAndColumnName } from "lib/utils";
import { ColumnCellAdditionalValueFields, Page, TableColumnType, ViewAction } from "types/baTypes";
import { CellType, LINK_OPEN_LOCATIONS, Size, TEXT_TYPE_CELLS, USER_TYPE } from "utils/constants";
import { isColumnTextWithOptions } from "utils/columnUtils";
import { RecordItem } from "types/common";
import IconButton from "components/IconButton";
import useCurrentUser from "hooks/useCurrentUser";
import useTableActionsState from "hooks/useTableActionsState";
import { NestedViewState } from "context/NestedViewContext";
import { CellTypesAvailableForUrl } from "utils/constants";
import { CellGroupValueInput, CellGroupValueType, getCellForType } from "./utils";
import CellFilesGallery from "./Cells/CellFilesGallery";

type CellDisplayHandlerProps = {
  value?: CellGroupValueInput;
  type: CellType;
  className?: string;
  isLoading?: boolean;
  column: TableColumnType;
  onClick?: () => void;
  isEditable?: boolean;
  inList?: boolean;
  inGrid?: boolean;
  inCard?: boolean;
  inDetailOverview?: boolean;
  handleExtraActions?: () => void;
  size?: Size;
  isMultiple?: boolean;
  row?: RecordItem;
  onCellDetailActionClick?: (record: RecordItem, tablePath: string, additionalProps?: Partial<NestedViewState>) => void;
  count?: number;
  actions?: ViewAction[];
  onActionClick?: (action: ViewAction, record: RecordItem) => void;
  columnTableName?: string;
  columnTablePath?: string;
  allColumns?: TableColumnType[];
  isHeader?: boolean;
  autoHeight?: boolean;
  loadOnClick?: boolean;
  label?: string;
  hideEmpty?: boolean; // If no value exists show nothing
  additionalValueFields?: ColumnCellAdditionalValueFields;
  hideLinkIcon?: boolean;
  gridInlineEditable?: boolean;
  cellWrapperClassName?: string;
  showTooltip?: boolean;
  defaultTablePage?: Page;
};

const CellDisplayHandler = ({
  label,
  type,
  value,
  className,
  isLoading = false,
  column,
  allColumns,
  onClick,
  isEditable,
  inGrid = false,
  inList = false,
  inCard = false,
  isHeader = false,
  inDetailOverview = false,
  size,
  handleExtraActions,
  isMultiple = false,
  row,
  onCellDetailActionClick,
  count,
  onActionClick,
  actions,
  columnTableName,
  columnTablePath,
  autoHeight,
  loadOnClick = false,
  hideEmpty = false,
  hideLinkIcon = false,
  additionalValueFields,
  gridInlineEditable = false,
  cellWrapperClassName,
  showTooltip,
  defaultTablePage
}: CellDisplayHandlerProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const Cell = getCellForType(type) as React.FC<any>;

  const currentUser = useCurrentUser();
  const router = useRouter();
  const isStaffUser = useMemo(() => currentUser?.type === USER_TYPE.STAFF, [currentUser]);
  const { updateMapState } = useTableActionsState();

  const booleanProps = type === CellType.BOOLEAN ? column.cellConfig?.booleanProps : undefined;
  const dateProps =
    type === CellType.DATETIME
      ? {
          showRelativeTime: column.cellConfig?.showRelativeTime
        }
      : undefined;

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (!isEditable) return;
      if (e.key === "Enter") {
        onClick?.();
      }
    },
    [isEditable, onClick]
  );

  const finalProperty: null | { lookupCols: string } = useMemo(() => {
    return (type === CellType.SELECT && !isColumnTextWithOptions(column)) ||
      (column.isLookup && TEXT_TYPE_CELLS.includes(type))
      ? getColumnOptionsLookupPropAndColumnName(column, false, true)
      : null;
  }, [column, type]);

  const finalValue = useMemo(() => {
    if (
      typeof value === "string" ||
      typeof value === "number" ||
      typeof value === "boolean" ||
      typeof value === "function" ||
      type === CellType.JSON
    ) {
      return value;
    }
    if (Array.isArray(value)) {
      return type === CellType.SPACES ? sortBy(value, "name") : value;
    }
  }, [value, type]);

  const renderMapIcon = useCallback(
    (value: RecordItem) => {
      return (
        <IconButton
          data-testid="CellMapIcon"
          icon={MapPinIcon}
          className="bg-background"
          onClick={(e) => {
            e.stopPropagation();

            updateMapState({
              isOpen: true,
              items: [
                {
                  id: value?.id,
                  longitude: value?.longitude || 0,
                  latitude: value?.latitude || 0
                }
              ],
              address: {
                address_line_1: value?.address_line_1,
                address_line_2: value?.address_line_2,
                city: value?.city,
                state: value?.state,
                zip_code: value?.zip_code
              }
            });
          }}
        />
      );
    },
    [updateMapState]
  );

  const renderProjectExpandIcon = useCallback(
    (value: RecordItem, path: string) => {
      return (
        <IconButton
          data-testid="CellProjectExpandIcon"
          icon={FocusIcon}
          className="bg-background"
          onClick={(e) => {
            e.stopPropagation();
            router.push(`/r${path}/${value?.projectId}`);
          }}
        />
      );
    },
    [router]
  );

  // If the column has a columnGroupBySortOrder, we sort the values by that
  // order.
  // If the values are strings, we sort them alphabetically.
  const valueSorted = useMemo(() => {
    if (!Array.isArray(value)) {
      return [];
    }
    return column.columnGroupBySortOrder
      ? sortBy(finalValue, column.columnGroupBySortOrder)
      : finalValue.every((item: any) => typeof item === "string")
        ? sortBy(finalValue)
        : column.type === CellType.BADGE && finalValue.every((item: any) => typeof item?.value === "string")
          ? sortBy(finalValue, "value")
          : finalValue;
  }, [column.columnGroupBySortOrder, finalValue, value, column.type]);

  if (hideEmpty && isEmpty(value)) {
    return null;
  }

  if (type === CellType.EXIST) {
    return (
      <Cell
        value={value}
        onCellDetailActionClick={onCellDetailActionClick}
        record={row}
        columnTablePath={columnTablePath}
        isHeader
        hideLinkIcon={hideLinkIcon}
        {...column.cellConfig?.existProps}
        {...additionalValueFields}
      />
    );
  }

  if (type === CellType.ICON) {
    return (
      <Cell
        key={`${column.id}_${row?.id}`}
        label={label}
        iconName={value}
        className={className}
        onClick={onClick}
        isEditable={isEditable}
        hideLinkIcon={hideLinkIcon}
        {...additionalValueFields}
      />
    );
  }

  if (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean" ||
    typeof value === "function" ||
    type === CellType.JSON
  ) {
    const fileType = type === CellType.FILE ? row?.file_type : undefined;
    const fileId = type === CellType.FILE && row?.file_type ? row?.id : undefined;

    return (
      <div
        className={clsx(
          "align-center relative flex w-full flex-row justify-center",
          loadOnClick && "cursor-pointer",
          cellWrapperClassName,
          inDetailOverview && "overflow-hidden"
        )}
        ref={hoverRef}
      >
        <Cell
          key={`${column.id}_${row?.id}`}
          isLoading={isLoading}
          value={finalValue}
          isEditable={isEditable}
          onClick={onClick}
          className={clsx(className, loadOnClick && "cursor-pointer")}
          inGrid={inGrid}
          gridInlineEditable={gridInlineEditable}
          inCard={inCard}
          inList={inList}
          size={size}
          inDetailOverview={inDetailOverview}
          handleExtraActions={handleExtraActions}
          fileType={fileType}
          row={row}
          onActionClick={onActionClick}
          actions={actions}
          onKeyDown={onKeyDown}
          fileId={fileId}
          column={column}
          columnTableName={columnTableName}
          columnTablePath={columnTablePath}
          isHeader={isHeader}
          autoHeight={autoHeight}
          hideLinkIcon={hideLinkIcon}
          showTooltip={showTooltip}
          {...column?.cellConfig}
          {...booleanProps}
          {...dateProps}
          {...additionalValueFields}
        />
        <div className="absolute right-2 top-2 z-[1000]">
          {defaultTablePage?.path && isHovered ? (
            <IconButton
              data-testid="HoverLinkIcon"
              className={clsx("rounded-lg", !isHovered && "invisible")}
              icon={ChevronRight}
              color="primary"
              tag={
                !column.cellConfig?.link_open_location ||
                column.cellConfig?.link_open_location === LINK_OPEN_LOCATIONS.SIDEBAR
                  ? "button"
                  : "a"
              }
              target={column.cellConfig?.link_open_location === LINK_OPEN_LOCATIONS.NEW_TAB ? "_blank" : undefined}
              rel="nofollow"
              href={
                !column.cellConfig?.link_open_location ||
                column.cellConfig?.link_open_location === LINK_OPEN_LOCATIONS.SIDEBAR
                  ? undefined
                  : `${column.cellConfig?.recordLinkPage}/${finalValue[0]?.id}`
              }
              onClick={(e) => {
                e.stopPropagation();
                if (
                  !!column.cellConfig?.link_open_location &&
                  column.cellConfig?.link_open_location !== LINK_OPEN_LOCATIONS.SIDEBAR
                )
                  return;
                const colValue =
                  row?.[column?.lookupPath?.[0]?.lookupColumnLabel || column?.lookupPath?.[0]?.lookupForeignKey || ""];
                if (!colValue) return;
                const record = Array.isArray(colValue) ? { ...colValue?.[0] } : { ...colValue };

                onCellDetailActionClick?.(record, defaultTablePage.path, {
                  redirectOnExpand: true
                });
              }}
            />
          ) : null}
        </div>
      </div>
    );
  }

  if (type === CellType.FILE && column.cellConfig?.renderGalleryView) {
    return (
      <div className={clsx("relative w-full", loadOnClick && "cursor-pointer", cellWrapperClassName)} ref={hoverRef}>
        <CellFilesGallery
          key={`${column.id}_${row?.id}`}
          value={value}
          isLoading={isLoading}
          row={row}
          size={size}
          className={className}
          inDetailOverview={inDetailOverview}
          inCard={inCard}
          {...column?.cellConfig}
          {...additionalValueFields}
        />
      </div>
    );
  }

  if (Array.isArray(value)) {
    const statusStyles =
      type === CellType.STATUS
        ? {
            borderColor: finalValue?.[0]?.color || "#2F6FED"
          }
        : undefined;
    const showLinkIcon =
      (type === CellType.PROJECT && finalValue?.[0]?.projectId) ||
      (type !== CellType.PROJECT && finalValue[0]?.id) ||
      defaultTablePage?.path;

    const linkOpenLocation = column.cellConfig?.showRecordLink
      ? column.cellConfig?.link_open_location
      : LINK_OPEN_LOCATIONS.SIDEBAR;
    return (
      <div
        data-testid="CellDisplayHandler"
        className={clsx(
          "relative flex w-full items-center gap-1",
          type === CellType.FILE && inGrid ? "flex-nowrap" : "flex-wrap",
          loadOnClick && "cursor-pointer",
          className
        )}
        ref={
          ((isStaffUser && CellTypesAvailableForUrl.includes(type)) || column.cellConfig?.showRecordLink) &&
          defaultTablePage?.path
            ? hoverRef
            : undefined
        }
        style={statusStyles}
      >
        {valueSorted.map((cellValue: Partial<CellGroupValueType>, index: number) => {
          const value =
            typeof cellValue === "string" // It is necessary for values text[]
              ? cellValue
              : finalProperty?.lookupCols
                ? cellValue?.[finalProperty.lookupCols]
                : type === CellType.GENERIC_CELL || type === CellType.FILE || type === CellType.COORDINATE
                  ? cellValue
                  : type === CellType.BUTTON
                    ? cellValue?.[column?.cellConfig?.linkColumn || ""]
                    : null;

          return (
            <Cell
              key={`${column.id}_${row?.id}_${index}`}
              value={value}
              isLoading={isLoading}
              isEditable={isEditable}
              onClick={onClick}
              size={size}
              inDetailOverview={inDetailOverview}
              isMultiple={isMultiple}
              fileType={type === CellType.FILE ? cellValue?.file_type : undefined}
              row={row}
              isHovered={isHovered}
              onActionClick={onActionClick}
              inCard={inCard}
              inList={inList}
              isHeader={isHeader}
              hideLinkIcon={hideLinkIcon}
              additionalText={
                (type === CellType.SELECT || type === CellType.TEXT) && index < finalValue.length - 1 ? "," : ""
              }
              hideColorName={column?.type === CellType.COLOR ? column.cellConfig?.hideColorName : undefined}
              {...cellValue}
              path={
                type === CellType.FILE && column.cellConfig?.filePathColumn
                  ? cellValue?.[column.cellConfig.filePathColumn]
                  : cellValue?.path
              }
              onCellDetailActionClick={onCellDetailActionClick}
              column={column}
              allColumns={allColumns}
              actions={actions}
              columnTableName={columnTableName}
              columnTablePath={columnTablePath}
              showTooltip={showTooltip}
              {...column?.cellConfig}
              {...booleanProps}
              {...dateProps}
              {...additionalValueFields}
            />
          );
        })}
        {value.length === 0 && (
          <Cell isLoading={isLoading} isEditable={isEditable} onClick={onClick} {...additionalValueFields} />
        )}

        {!hideLinkIcon && (
          <div className="absolute right-2 z-[1000] flex gap-x-1">
            {(type === CellType.ADDRESS || type === CellType.PROJECT) && isHovered
              ? value?.[0]?.projectId && defaultTablePage
                ? renderProjectExpandIcon(value?.[0], defaultTablePage.path)
                : value?.[0]?.longitude
                  ? renderMapIcon(value?.[0])
                  : null
              : null}

            {finalValue?.length === 1 && !isMultiple && showLinkIcon && (
              <>
                {(type === CellType.PEOPLE || type === CellType.COMPANY) && defaultTablePage && (
                  <IconButton
                    data-testid="RecordExpandIcon"
                    icon={FocusIcon}
                    className={clsx("bg-background", !isHovered && "invisible")}
                    onClick={(e) => {
                      e.stopPropagation();
                      const record = { ...finalValue[0] };
                      router.push(`/r${defaultTablePage.path}/${record?.id}`);
                    }}
                  />
                )}
                <IconButton
                  data-testid="HoverLinkIcon"
                  className={clsx("rounded-lg", !isHovered && "invisible")}
                  icon={ChevronRight}
                  color="primary"
                  tag={linkOpenLocation === LINK_OPEN_LOCATIONS.SIDEBAR ? "button" : "a"}
                  target={linkOpenLocation === LINK_OPEN_LOCATIONS.NEW_TAB ? "_blank" : undefined}
                  rel="nofollow"
                  href={
                    linkOpenLocation === LINK_OPEN_LOCATIONS.SIDEBAR
                      ? undefined
                      : `${column.cellConfig?.recordLinkPage}/${finalValue[0]?.id}`
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (linkOpenLocation !== LINK_OPEN_LOCATIONS.SIDEBAR) return;
                    const record = { ...finalValue[0] };
                    if (type === CellType.PROJECT) {
                      record.id = finalValue[0].projectId;
                    }

                    if (column.cellConfig?.showRecordLink && column.cellConfig?.recordLinkPage) {
                      if (column.cellConfig?.link_open_location === LINK_OPEN_LOCATIONS.SIDEBAR) {
                        onCellDetailActionClick?.(record, column.cellConfig?.recordLinkPage, {
                          redirectOnExpand: true
                        });
                      } else {
                        const url = `${window.location.host}${column.cellConfig.recordLinkPage}/${record.id}`;

                        if (column.cellConfig?.link_open_location === LINK_OPEN_LOCATIONS.NEW_TAB) {
                          window.open(url, "_blank");
                        } else {
                        }
                      }
                    } else {
                      if (defaultTablePage?.path) {
                        onCellDetailActionClick?.(record, defaultTablePage.path, {
                          redirectOnExpand: true
                        });
                      }
                    }
                  }}
                />
              </>
            )}
          </div>
        )}

        {count ? (
          <span className="inline-flex pl-3 text-sm font-medium text-base-disabled dark:text-base-dark-disabled">
            {count}
          </span>
        ) : null}
      </div>
    );
  }

  const hasCoordinatesOrProjectId =
    (type === CellType.ADDRESS || type === CellType.PROJECT) &&
    ((value?.longitude && value?.latitude) || value?.projectId);

  return (
    <div className={clsx("relative w-full", loadOnClick && "cursor-pointer", cellWrapperClassName)} ref={hoverRef}>
      <Cell
        key={`${column.id}_${row?.id}`}
        isLoading={isLoading}
        className={className}
        isEditable={isEditable}
        value={
          finalProperty?.lookupCols
            ? value?.[finalProperty.lookupCols as any]
            : type === CellType.ADDRESS
              ? value
              : type === CellType.BUTTON
                ? value?.[column.cellConfig?.linkColumn || ""]
                : type === CellType.BEFORE_AFTER
                  ? value
                  : null
        }
        onClick={onClick}
        size={size}
        inDetailOverview={inDetailOverview}
        fileType={type === CellType.FILE ? value?.file_type : undefined}
        row={row}
        {...value}
        onActionClick={onActionClick}
        actions={actions}
        isHovered={isHovered}
        hasCoordinatesOrProjectId={hasCoordinatesOrProjectId}
        inCard={inCard}
        inList={inList}
        isHeader={isHeader}
        onKeyDown={onKeyDown}
        hideColorName={column?.type === CellType.COLOR ? column.cellConfig?.hideColorName : undefined}
        path={
          type === CellType.FILE && column.cellConfig?.filePathColumn
            ? value?.[column.cellConfig.filePathColumn]
            : value?.path
        }
        onCellDetailActionClick={onCellDetailActionClick}
        column={column}
        allColumns={allColumns}
        columnTableName={columnTableName}
        columnTablePath={columnTablePath}
        hideLinkIcon={hideLinkIcon}
        showTooltip={showTooltip}
        {...column?.cellConfig}
        {...booleanProps}
        {...dateProps}
        {...additionalValueFields}
      />
      <div className="absolute right-2 top-2">
        {hasCoordinatesOrProjectId && isHovered ? (
          value?.projectId && defaultTablePage ? (
            renderProjectExpandIcon(value, defaultTablePage.path)
          ) : (
            renderMapIcon(value)
          )
        ) : defaultTablePage?.path && isHovered ? (
          <IconButton
            data-testid="HoverLinkIcon"
            className={clsx("z-[1000] rounded-lg", !isHovered && "invisible")}
            icon={ChevronRight}
            color="primary"
            tag={
              !column.cellConfig?.link_open_location ||
              column.cellConfig?.link_open_location === LINK_OPEN_LOCATIONS.SIDEBAR
                ? "button"
                : "a"
            }
            target={column.cellConfig?.link_open_location === LINK_OPEN_LOCATIONS.NEW_TAB ? "_blank" : undefined}
            rel="nofollow"
            href={
              !column.cellConfig?.link_open_location ||
              column.cellConfig?.link_open_location === LINK_OPEN_LOCATIONS.SIDEBAR
                ? undefined
                : `${column.cellConfig?.recordLinkPage}/${finalValue[0]?.id}`
            }
            onClick={(e) => {
              e.stopPropagation();
              if (
                (!!column.cellConfig?.link_open_location &&
                  column.cellConfig?.link_open_location !== LINK_OPEN_LOCATIONS.SIDEBAR) ||
                !finalValue
              )
                return;
              const record = { ...finalValue };
              if (type === CellType.PROJECT) {
                record.id = finalValue.projectId;
              }
              onCellDetailActionClick?.(record, defaultTablePage.path, {
                redirectOnExpand: true
              });
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

CellDisplayHandler.displayName = "CellDisplayHandler";

export default CellDisplayHandler;
