const AviIcon = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-[#6A6F79] dark:fill-white"
    >
      <path d="M30.8 29.12C30.968 29.288 31.08 29.512 31.08 29.792C31.08 30.128 30.968 30.352 30.8 30.52C30.576 30.688 30.296 30.744 29.904 30.744H29.456V28.896H30.072C30.408 28.896 30.632 29.008 30.8 29.12Z" />
      <path d="M42.336 41.216H40.32C39.984 41.216 39.76 41.496 39.76 41.776V45.864C39.76 47.376 38.528 48.664 37.016 48.664L11.872 48.832C10.528 48.832 9.408 47.936 9.128 46.648C9.072 46.48 9.07201 46.256 9.07201 46.032L8.96001 20.104C8.96001 19.936 9.12801 19.768 9.29601 19.768L19.824 19.712C21.672 19.712 23.128 18.2 23.128 16.408L23.072 7.616C23.072 7.448 23.24 7.28 23.408 7.28L36.736 7.168C38.248 7.168 39.536 8.4 39.536 9.912L39.592 20.272C39.592 20.608 39.872 20.832 40.152 20.832H42.168C42.504 20.832 42.728 20.552 42.728 20.272L42.672 8.288C42.672 5.88 40.712 3.92 38.248 3.92L21.896 4.088C21.448 4.088 20.944 4.256 20.608 4.592L6.38401 17.64C5.99201 18.032 5.76801 18.536 5.76801 19.04L5.88001 47.6C5.88001 50.008 7.84 51.968 10.304 51.968L38.64 51.912C41.048 51.8 43.008 49.84 43.008 47.432V41.776C42.896 41.496 42.672 41.216 42.336 41.216Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.6568 23H48.3432C49.2821 23 50 23.7761 50 24.7911V37.209C50 38.1642 49.2821 39 48.3432 39H23.6568C22.7732 39 22 38.2239 22 37.209V24.7911C22 23.8358 22.7179 23 23.6568 23Z"
      />
      <path
        d="M29.9579 35H28.3102L30.8208 27.7273H32.8024L35.3095 35H33.6618L31.84 29.3892H31.7832L29.9579 35ZM29.8549 32.1413H33.747V33.3416H29.8549V32.1413ZM36.5906 27.7273L38.3484 33.2528H38.4158L40.1772 27.7273H41.8817L39.3746 35H37.3931L34.8825 27.7273H36.5906ZM44.2947 27.7273V35H42.7571V27.7273H44.2947Z"
        className="fill-white dark:fill-[#262626]"
      />
    </svg>
  );
};

export default AviIcon;
