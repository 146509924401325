const PPTIcon = ({ className }: { className: string }) => {
  return (
    <div className={className}>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-[#6A6F79] dark:fill-white"
      >
        <path d="M30.8001 29.12C30.9681 29.288 31.0801 29.512 31.0801 29.792C31.0801 30.128 30.9681 30.352 30.8001 30.52C30.5761 30.688 30.2961 30.744 29.9041 30.744H29.4561V28.896H30.0721C30.4081 28.896 30.6321 29.008 30.8001 29.12Z" />
        <path d="M42.3359 41.216H40.3199C39.9839 41.216 39.7599 41.496 39.7599 41.776V45.864C39.7599 47.376 38.5279 48.664 37.0159 48.664L11.8719 48.832C10.5279 48.832 9.40794 47.936 9.12794 46.648C9.07194 46.48 9.07194 46.256 9.07194 46.032L8.95994 20.104C8.95994 19.936 9.12794 19.768 9.29594 19.768L19.8239 19.712C21.6719 19.712 23.1279 18.2 23.1279 16.408L23.0719 7.616C23.0719 7.448 23.2399 7.28 23.4079 7.28L36.7359 7.168C38.2479 7.168 39.5359 8.4 39.5359 9.912L39.5919 20.272C39.5919 20.608 39.8719 20.832 40.1519 20.832H42.1679C42.5039 20.832 42.7279 20.552 42.7279 20.272L42.6719 8.288C42.6719 5.88 40.7119 3.92 38.2479 3.92L21.8959 4.088C21.4479 4.088 20.9439 4.256 20.6079 4.592L6.38394 17.64C5.99194 18.032 5.76794 18.536 5.76794 19.04L5.87994 47.6C5.87994 50.008 7.83994 51.968 10.3039 51.968L38.6399 51.912C41.0479 51.8 43.0079 49.84 43.0079 47.432V41.776C42.8959 41.496 42.6719 41.216 42.3359 41.216Z" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.6568 23H48.3432C49.2821 23 50 23.7761 50 24.7911V37.209C50 38.1642 49.2821 39 48.3432 39H23.6568C22.7732 39 22 38.2239 22 37.209V24.7911C22 23.8358 22.7179 23 23.6568 23Z"
        />
        <path
          d="M26.8176 35V27.7273H29.687C30.2386 27.7273 30.7085 27.8326 31.0968 28.0433C31.485 28.2517 31.781 28.5417 31.9846 28.9134C32.1905 29.2827 32.2935 29.7088 32.2935 30.1918C32.2935 30.6747 32.1893 31.1009 31.981 31.4702C31.7727 31.8395 31.4708 32.1271 31.0755 32.3331C30.6825 32.5391 30.2066 32.642 29.6479 32.642H27.8191V31.4098H29.3993C29.6953 31.4098 29.9391 31.3589 30.1309 31.2571C30.325 31.1529 30.4694 31.0097 30.5641 30.8274C30.6612 30.6428 30.7097 30.4309 30.7097 30.1918C30.7097 29.9503 30.6612 29.7396 30.5641 29.5597C30.4694 29.3774 30.325 29.2365 30.1309 29.1371C29.9367 29.0353 29.6905 28.9844 29.3922 28.9844H28.3553V35H26.8176ZM33.2923 35V27.7273H36.1616C36.7132 27.7273 37.1831 27.8326 37.5714 28.0433C37.9596 28.2517 38.2556 28.5417 38.4592 28.9134C38.6651 29.2827 38.7681 29.7088 38.7681 30.1918C38.7681 30.6747 38.6639 31.1009 38.4556 31.4702C38.2473 31.8395 37.9454 32.1271 37.5501 32.3331C37.1571 32.5391 36.6812 32.642 36.1225 32.642H34.2937V31.4098H35.8739C36.1699 31.4098 36.4137 31.3589 36.6055 31.2571C36.7996 31.1529 36.944 31.0097 37.0387 30.8274C37.1358 30.6428 37.1843 30.4309 37.1843 30.1918C37.1843 29.9503 37.1358 29.7396 37.0387 29.5597C36.944 29.3774 36.7996 29.2365 36.6055 29.1371C36.4113 29.0353 36.1651 28.9844 35.8668 28.9844H34.8299V35H33.2923ZM39.4899 28.995V27.7273H45.4629V28.995H43.2363V35H41.7164V28.995H39.4899Z"
          className="fill-white dark:fill-[#262626]"
        />
      </svg>
    </div>
  );
};

export default PPTIcon;
