import { testRule } from "components/DataGridView/utils";
import { getTextFromColumnWithFieldSelected } from "lib/utils";
import { ColumnConditionalRule, ColumnRule, MenuAction, PageAction, TableColumnType, ViewAction } from "types/baTypes";
import { RecordItem } from "types/common";
import { COLUMN_CONDITIONAL_VISIBILITY } from "components/Admin/utils";
import { FILTER_GROUP_TYPE } from "./constants";

export const formatActions = (actions?: PageAction[]): ViewAction[] | undefined => {
  if (!actions?.length) return undefined;
  return actions.map((pageAction) => {
    return {
      ...pageAction,
      ...(pageAction.ui_action_id || {}),
      ...(pageAction.action_properties || {}),
      id: pageAction.id,
      requireConfirmation: pageAction.ui_action_id?.requires_confirmation,
      visibility: pageAction.visibility,
      bulkSortOrder: pageAction.bulk_sort_order,
      waitForSuccess: !!pageAction.wait_for_success,
      hasDivider: !!pageAction.has_divider,
      isExposed: !!pageAction.is_exposed,
      ui_action_id: pageAction.ui_action_id
    };
  });
};

export const transformMenuItemActions = (action: MenuAction) => {
  return {
    id: action.id,
    name: action.ui_action_id?.name,
    type: action.ui_action_id?.type,
    properties: action.action_properties,
    icon: action.ui_action_id.icon,
    waitForSuccess: !!action.wait_for_success,
    hasDivider: !!action.has_divider,
    sort: action.sort,
    ui_action_id: action.ui_action_id
  };
};

export const getActionVisibilityRule = (
  col: TableColumnType,
  ruleCondition: ColumnConditionalRule,
  visibilityRule: ColumnRule,
  value?: RecordItem
) => {
  if (!visibilityRule || !ruleCondition) return;

  const valueToEvaluate = getTextFromColumnWithFieldSelected(col, value, ruleCondition.field);

  const passRule = testRule(valueToEvaluate, ruleCondition);

  if (passRule) {
    return visibilityRule.ruleConfig?.visibility;
  }

  return;
};

export const getFinalVisibilityFromVisibilityRule = ({
  visibilityRule,
  checkRulePassed
}: {
  visibilityRule?: ColumnRule;
  checkRulePassed: (rule: ColumnConditionalRule) => boolean;
}) => {
  const isAllRulesPassed = visibilityRule?.columnRules?.reduce(
    (acc: boolean, colRule: ColumnConditionalRule, index) => {
      const isPassed = checkRulePassed(colRule);
      if (index === 0) {
        // first rule
        return isPassed;
      }
      if (colRule.filterGroup === FILTER_GROUP_TYPE.OR) {
        return acc || isPassed;
      } else {
        return acc && isPassed;
      }
    },
    false
  );
  const isHidden =
    visibilityRule?.ruleConfig?.visibility === COLUMN_CONDITIONAL_VISIBILITY.HIDE
      ? isAllRulesPassed
      : !isAllRulesPassed;

  return isHidden ? COLUMN_CONDITIONAL_VISIBILITY.HIDE : COLUMN_CONDITIONAL_VISIBILITY.SHOW;
};
