import clsx from "clsx";
import Tooltip from "components/Tooltip";

const FormulaIcon = ({ className }: { className?: string }) => {
  return (
    <Tooltip
      title={
        <>
          <p>Formula calculated.</p>
          <p>Values can not be directly edited.</p>
        </>
      }
    >
      <div
        className={clsx(
          "border-separator inline-flex h-[18px] w-[18px] shrink-0 items-center justify-center rounded-full border",
          className
        )}
      >
        <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.98266 1.13273L7.21725 1.92817C7.19197 1.95394 7.14945 1.9516 7.12187 1.92231C7.11497 1.91528 7.11037 1.90708 7.10693 1.89771C7.05751 1.76651 6.98396 1.64936 6.88397 1.54744C6.80007 1.45958 6.68745 1.38577 6.54724 1.32603C5.99099 1.08822 5.54048 1.16788 5.19686 1.56501C5.02332 1.76533 4.90494 2.06055 4.84403 2.45065C4.76473 2.96025 4.73141 3.47336 4.7452 3.99232C4.74635 4.02747 4.77278 4.05441 4.80381 4.05441L6.18637 4.0345C6.27026 4.0345 6.33807 4.09307 6.33922 4.16687L6.33118 5.17786C6.33118 5.20949 6.30359 5.23527 6.27026 5.23527L4.67624 5.24229C4.62797 5.24229 4.5866 5.27978 4.582 5.32898C4.51419 6.08108 4.44639 6.83317 4.38088 7.58526C4.33376 8.13117 4.2786 8.50956 4.21654 8.72043C4.01771 9.39286 3.6534 9.88254 3.12129 10.1895C2.61677 10.48 2.08006 10.5655 1.50888 10.446C0.937697 10.3254 0.445814 10.0055 0.0332294 9.48658C-0.0115917 9.42917 -0.0115917 9.34951 0.0343787 9.30148L0.726233 8.57868C0.769905 8.53416 0.841159 8.53533 0.88598 8.58102C0.891727 8.58688 0.897473 8.59391 0.90207 8.60094C1.28707 9.20191 1.76172 9.40809 2.3283 9.21714C2.56275 9.13865 2.75238 9.0051 2.89833 8.81766C3.05578 8.61499 3.14543 8.38773 3.16726 8.13469C3.24886 7.19047 3.32701 6.2568 3.40171 5.3325C3.40516 5.28681 3.37183 5.24698 3.32701 5.24229C3.32471 5.24229 3.32241 5.24229 3.32011 5.24229H1.75367C1.68472 5.24229 1.62955 5.18841 1.62955 5.12163L1.6399 4.15164C1.6399 4.12001 1.66633 4.09307 1.70081 4.0919L3.46032 4.0427C3.49135 4.0427 3.51664 4.01692 3.51894 3.98529C3.56146 3.43704 3.60858 2.88996 3.66029 2.34405C3.7327 1.5779 4.02346 0.981612 4.53028 0.555192C5.03366 0.131115 5.60484 -0.0504648 6.24498 0.0116238C6.90581 0.0760553 7.48503 0.418128 7.98266 1.03901C8.00565 1.0683 8.00565 1.1093 7.98266 1.13273Z"
            fillOpacity="0.55"
            className="fill-[#1D2433] dark:fill-[#fff]"
          />
        </svg>
      </div>
    </Tooltip>
  );
};

export default FormulaIcon;
