import clsx from "clsx";
import { useMemo, useState } from "react";
import Skeleton from "components/Skeleton";
import { RecordItem } from "types/common";
import { Size } from "utils/constants";
import FileModal from "components/DetailViewSidebarModal/FileModal";
import CellEmpty from "./CellEmpty";
import CellFile from "./CellFile";

type CellFilesGalleryProps = {
  isLoading?: boolean;
  size?: Size;
  className?: string;
  inDetailOverview?: boolean;
  inCard?: boolean;
  row?: RecordItem;
  value?: RecordItem[];
  filePathColumn?: string;
};

const CellFilesGallery = ({
  isLoading = false,
  size = Size.SM,
  className,
  inDetailOverview = false,
  inCard = false,
  value,
  filePathColumn,
  row
}: CellFilesGalleryProps) => {
  const [fileModalState, setFileModalState] = useState<RecordItem>({});
  const valueToDisplay = useMemo(() => {
    if (!value) return [];
    return value.slice(0, 3);
  }, [value]);

  const handleFileClick = (file: RecordItem) => {
    setFileModalState({ isOpen: true, file });
  };

  const onNext = () => {
    const index = value?.findIndex((file) => file.id === fileModalState.file?.id);
    if (index !== undefined && index !== -1) {
      const nextIndex = index + 1;
      const nextFile = value?.[nextIndex];
      if (nextFile) {
        setFileModalState((prev) => ({ ...prev, file: nextFile }));
      }
    }
  };

  const onPrev = () => {
    const index = value?.findIndex((file) => file.id === fileModalState.file?.id);
    if (index && index !== -1) {
      const nextIndex = index - 1;
      const prevFile = value?.[nextIndex];
      if (prevFile) {
        setFileModalState((prev) => ({ ...prev, file: prevFile }));
      }
    }
  };

  if (isLoading) {
    return <Skeleton className="h-10 w-10" />;
  }

  if (!value?.length) {
    return <CellEmpty />;
  }

  return (
    <div
      className={clsx(className, "flex items-center gap-2")}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {valueToDisplay.map((cellValue, index) =>
        index === 2 && value.length > 3 ? (
          <div
            key={cellValue.id}
            className="relative cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              handleFileClick(cellValue);
            }}
          >
            <CellFile
              {...cellValue}
              value={cellValue as any}
              path={filePathColumn ? cellValue?.[filePathColumn] : cellValue?.path}
              size={size}
              inDetailOverview={inDetailOverview}
              inCard={inCard}
              disableFileClick
              row={row}
            />
            <div
              className={clsx(
                "absolute inset-0 flex items-center justify-center bg-base-primary/70",
                inDetailOverview ? " rounded-xl" : "rounded"
              )}
            >
              <span className=" text-sm font-medium text-white">{`+${value.length - 3}`}</span>
            </div>
          </div>
        ) : (
          <CellFile
            key={cellValue.id}
            {...cellValue}
            value={cellValue as any}
            path={filePathColumn ? cellValue?.[filePathColumn] : cellValue?.path}
            size={size}
            inDetailOverview={inDetailOverview}
            inCard={inCard}
            onFileClick={() => handleFileClick(cellValue)}
          />
        )
      )}
      <FileModal
        isOpen={fileModalState.isOpen}
        onClose={() => setFileModalState({ isOpen: false })}
        srcFile={fileModalState.file?.path || ""}
        fileType={fileModalState.file?.file_type}
        fileId={fileModalState.file?.id}
        fileName={fileModalState.file?.name}
        hideSidebar
        actions={[]}
        mimetype={fileModalState.file?.mimetype}
        onNext={onNext}
        onPrev={onPrev}
        isNextDisabled={fileModalState?.file?.id === value?.[value.length - 1]?.id}
        isPrevDisabled={fileModalState?.file?.id === value?.[0]?.id}
      />
    </div>
  );
};

export default CellFilesGallery;
