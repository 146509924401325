import React, { useEffect } from "react";
import AudioPlayer from "components/AudioPlayer";
import { AudioProvider } from "context/AudioPlayerContext";
import useAudioPlayer from "hooks/useAudioPlayer";
import { buildSupabasePublicUrl } from "components/Image/utils";

type AudioViewerProps = {
  title?: string;
  src: string;
  type?: string;
};

const AudioPlayerWrapper = ({ title, src, type }: AudioViewerProps) => {
  const isExternal = src.startsWith("http") || src.startsWith("blob");
  const finalSrc = isExternal ? src : buildSupabasePublicUrl(src);
  const player = useAudioPlayer({
    title: title || "",
    audio: {
      src: finalSrc || "",
      type: type || "audio/mp3",
    },
  });

  // if src is changed reset the player
  useEffect(() => {
    player.getPlayerReady();
  }, [finalSrc]);

  return (
    <div className="flex h-full w-full items-center justify-center">
      <AudioPlayer hideCloseButton />
    </div>
  );
};
const AudioViewer = ({ title, src, type }: AudioViewerProps) => {
  return (
    <AudioProvider>
      <AudioPlayerWrapper title={title} src={src} type={type} />
    </AudioProvider>
  );
};

export default React.memo(AudioViewer);
