import { useRef } from "react";
import clsx from "clsx";

type TitleEditProps = {
  title: string;
  onChange: (title: string) => void;
  editMode?: boolean;
  className?: string;
  placeholder?: string;
};

const TitleEdit = ({ title, onChange, editMode, className, placeholder }: TitleEditProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleEdit = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onChange(ref.current?.textContent || "");
    }
  };

  return (
    <div
      data-testid="TitleEdit"
      contentEditable={editMode}
      onKeyPress={handleEdit}
      onBlur={() => {
        onChange?.(ref.current?.textContent || "");
      }}
      className={clsx(
        editMode ? "underline-offset-4 hover:underline focus:underline focus:outline-none" : "",
        className
      )}
      ref={ref}
      placeholder={placeholder}
      suppressContentEditableWarning
    >
      {title}
    </div>
  );
};

export default TitleEdit;
