"use client";

import { createContext, useMemo, useReducer, useRef } from "react";
import { Player, PlayerState, PlayerMeta } from "types/common";

enum PlayerActionTypes {
  SET_META = "SET_META",
  PLAY = "PLAY",
  PAUSE = "PAUSE",
  TOGGLE_MUTE = "TOGGLE_MUTE",
  SET_CURRENT_TIME = "SET_CURRENT_TIME",
  SET_DURATION = "SET_DURATION",
  SET_PLAYBACK_RATE = "SET_PLAYBACK_RATE",
  CLOSE = "CLOSE",
}

type PlayerActionSetMeta = {
  type: PlayerActionTypes.SET_META;
  payload: PlayerMeta;
};
type PlayerActionPlay = { type: PlayerActionTypes.PLAY };
type PlayerActionPause = { type: PlayerActionTypes.PAUSE };
type PlayerActionToggleMute = { type: PlayerActionTypes.TOGGLE_MUTE };
type PlayerActionSetCurrentTime = { type: PlayerActionTypes.SET_CURRENT_TIME; payload: number };
type PlayerActionSetPlaybackRate = { type: PlayerActionTypes.SET_PLAYBACK_RATE; payload: number };
type PlayerActionSetDuration = { type: PlayerActionTypes.SET_DURATION; payload: number };
type PlayerActionClose = { type: PlayerActionTypes.CLOSE };

type PlayerReducerAction =
  | PlayerActionSetMeta
  | PlayerActionPlay
  | PlayerActionPause
  | PlayerActionToggleMute
  | PlayerActionSetCurrentTime
  | PlayerActionSetPlaybackRate
  | PlayerActionSetDuration
  | PlayerActionClose;

type PlayerRef = {
  currentSrc: string;
  playbackRate?: string;
  src: string;
  load: () => void;
  pause: () => void;
  play: () => void;
  currentTime: number;
};

const reducers = {
  [PlayerActionTypes.SET_META]: (state: PlayerState, action: PlayerActionSetMeta) => {
    return { ...state, meta: action.payload };
  },
  [PlayerActionTypes.PLAY]: (state: PlayerState) => {
    return { ...state, playing: true };
  },
  [PlayerActionTypes.PAUSE]: (state: PlayerState) => {
    return { ...state, playing: false };
  },
  [PlayerActionTypes.TOGGLE_MUTE]: (state: PlayerState) => {
    return { ...state, muted: !state.muted };
  },
  [PlayerActionTypes.SET_CURRENT_TIME]: (state: PlayerState, action: PlayerActionSetCurrentTime) => {
    return { ...state, currentTime: action.payload };
  },
  [PlayerActionTypes.SET_DURATION]: (state: PlayerState, action: PlayerActionSetDuration) => {
    return { ...state, duration: action.payload };
  },
  [PlayerActionTypes.CLOSE]: (state: PlayerState) => {
    return { ...state, meta: null, playing: false };
  },
};

const audioReducer = (state: PlayerState, action: PlayerReducerAction) => {
  // @ts-ignore
  return reducers[action.type](state, action);
};

export const AudioPlayerContext = createContext<Player | null>(null);

const getFinalUrl = (url: string) => {
  // Update url when the file comes from Google Drive
  if (url?.startsWith("https://drive.google.com/")) {
    const partialId = url.replace(/https:\/\/drive\.google\.com\/file\/d\//g, "");
    const id = partialId.split("/")[0];

    return `https://docs.google.com/uc?export=download&id=${id}`;
  }

  return url;
};

export const AudioProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(audioReducer, {
    playing: false,
    muted: false,
    duration: 0,
    currentTime: 0,
    meta: null,
  });
  const playerRef = useRef<PlayerRef | null>(null);

  const actions = useMemo(() => {
    return {
      play(data: PlayerMeta) {
        if (data) {
          dispatch({ type: PlayerActionTypes.SET_META, payload: data });
          const finalUrl = getFinalUrl(data.audio?.src);

          if (playerRef.current && playerRef.current?.currentSrc !== finalUrl) {
            const playbackRate = playerRef.current?.playbackRate;
            playerRef.current.src = finalUrl;
            playerRef.current.load();
            playerRef.current.pause();
            playerRef.current.playbackRate = playbackRate;
            playerRef.current.currentTime = 0;
          }
        }

        playerRef.current?.play();
      },
      pause() {
        playerRef.current?.pause();
      },
      toggle(data: PlayerMeta) {
        this.isPlaying(data) ? actions.pause() : actions.play(data);
      },
      seekBy(amount: number) {
        if (!playerRef.current) return;
        playerRef.current.currentTime += amount;
      },
      seek(time: number) {
        if (!playerRef.current) return;
        playerRef.current.currentTime = time;
      },
      playbackRate(rate: string) {
        if (!playerRef.current) return;
        playerRef.current.playbackRate = rate;
      },
      toggleMute() {
        dispatch({ type: PlayerActionTypes.TOGGLE_MUTE });
      },
      isPlaying(data: PlayerMeta) {
        const finalUrl = getFinalUrl(data?.audio?.src);
        return data ? state.playing && playerRef.current?.currentSrc === finalUrl : state.playing;
      },
      close() {
        playerRef.current?.pause();
        dispatch({ type: PlayerActionTypes.CLOSE });
      },
      getPlayerReady(data: PlayerMeta) {
        if (data) {
          dispatch({ type: PlayerActionTypes.SET_META, payload: data });
          const finalUrl = getFinalUrl(data.audio?.src);

          if (playerRef.current && playerRef.current?.currentSrc !== finalUrl) {
            const playbackRate = playerRef.current?.playbackRate;
            playerRef.current.src = finalUrl;
            playerRef.current.load();
            playerRef.current.pause();
            playerRef.current.playbackRate = playbackRate;
            playerRef.current.currentTime = 0;
          }
        }
      },
    };
  }, [state.playing]);

  const api = useMemo(() => ({ ...state, ...actions }), [state, actions]);

  return (
    <>
      <AudioPlayerContext.Provider value={api}>{children}</AudioPlayerContext.Provider>
      <audio
        ref={playerRef as any}
        onPlay={() => dispatch({ type: PlayerActionTypes.PLAY })}
        onPause={() => dispatch({ type: PlayerActionTypes.PAUSE })}
        onTimeUpdate={(event: any) => {
          dispatch({
            type: PlayerActionTypes.SET_CURRENT_TIME,
            payload: Math.floor(event.target.currentTime),
          });
        }}
        onDurationChange={(event: any) => {
          dispatch({
            type: PlayerActionTypes.SET_DURATION,
            payload: Math.floor(event.target.duration),
          });
        }}
        muted={state.muted}
      />
    </>
  );
};
