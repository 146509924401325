"use client";

import { useCallback, useState, useEffect, useMemo } from "react";
import { Command } from "cmdk";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { CheckCircle2 as CheckIcon } from "lucide-react";

import { SelectOption, SelectOptions, RecordItem } from "types/common";
import { ColumnCellAdditionalValueFields, TableColumnType } from "types/baTypes";

import "./CommandMenuOptions.css";
import { isColumnTextWithOptions } from "utils/columnUtils";
import { CellType } from "utils/constants";
import { sanitiseDataToString } from "utils/dataUtils";
import CellGeneric from "../Cells/CellGeneric";
import { getCellForType } from "../utils";
import SelectedCells from "./SelectedCells";

const CommandMenuOptions = ({
  isOpen,
  options,
  selected,
  type,
  column,
  handleSelect,
  placeholder,
  disabled,
  inForm,
  handleRemoveItem,
  additionalValueFields
}: {
  isOpen?: boolean;
  options?: SelectOption[];
  type?: CellType;
  isMultiColumnOptions?: boolean;
  hasGenericOptions?: boolean;
  handleSelect?: (option: SelectOption | SelectOptions) => void;
  column?: TableColumnType;
  selected?: RecordItem | SelectOption[] | SelectOption | null;
  placeholder?: string;
  disabled?: boolean;
  inForm?: boolean;
  handleRemoveItem?: (option: SelectOption) => void;
  additionalValueFields?: ColumnCellAdditionalValueFields;
}) => {
  const [open, setOpen] = useState(isOpen);
  const isEmptyValue =
    (!selected && !column?.isMultiple) || (!(selected as SelectOptions)?.length && column?.isMultiple);
  const placeholderText = disabled ? "-" : inForm ? placeholder : "Add";
  const Cell = column?.cellConfig?.genericConfigForColumnOptions ? CellGeneric : type ? getCellForType(type) : null;

  const finalOptions = useMemo(() => {
    const formattedOpts = options?.map((option) => {
      const isSelected = Array.isArray(selected)
        ? selected?.find((item) => item?.value === option?.value)
        : selected?.value === option?.value;
      return {
        ...option,
        title: sanitiseDataToString(option?.title || ""),
        isSelected
      } as RecordItem;
    });

    return formattedOpts;
  }, [options, selected]);

  const handleOpenChange = useCallback((open: boolean) => {
    setOpen(open);
  }, []);

  const handleOptionSelection = useCallback(
    (option: SelectOption) => {
      handleSelect?.(option);
      if (!column?.isMultiple) {
        handleOpenChange(false);
      }
    },
    [handleSelect, column, handleOpenChange]
  );

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen, handleOpenChange]);

  return (
    <>
      <div onClick={() => handleOpenChange(true)} className="flex w-full bg-baseinverse-secondary">
        {isEmptyValue && (
          <span className={clsx("text-base-disabled dark:text-base-dark-disabled")}>
            {open ? placeholder : placeholderText}
          </span>
        )}
        {Cell ? (
          <SelectedCells
            selected={selected}
            column={column}
            isMultiple={!!column?.isMultiple}
            cell={Cell}
            handleRemoveItem={handleRemoveItem}
            additionalValueFields={additionalValueFields}
          />
        ) : null}
      </div>
      <Command.Dialog open={open} onOpenChange={handleOpenChange} label="Command_Menu_Options">
        <Command.Input />
        <Command.List>
          <Command.Empty>No results found.</Command.Empty>

          {finalOptions?.map((option) => {
            return (
              <Command.Item
                key={option?.value}
                onSelect={() => handleOptionSelection?.(option as SelectOption)}
                className="my-3"
              >
                {Cell ? (
                  <Cell
                    {...(option?.record || {})}
                    value={
                      column?.type === CellType.GENERIC_CELL ||
                      type === CellType.GENERIC_CELL ||
                      !isEmpty(column?.cellConfig?.genericConfigForColumnOptions)
                        ? option?.record
                        : column && isColumnTextWithOptions(column)
                          ? option?.value
                          : sanitiseDataToString(option?.record?.value || "")
                    }
                    className="py-2 text-sm"
                    isEditable={false}
                    isInOptions
                    isSelected={option?.isSelected}
                    column={column}
                    tooltipProps={{ placement: "left-start" }}
                  />
                ) : (
                  option?.title
                )}
                {option?.isSelected && (
                  <CheckIcon className="absolute right-3 h-5 w-5 text-primary-700 dark:text-primary-dark-700" />
                )}
              </Command.Item>
            );
          })}
        </Command.List>
      </Command.Dialog>
    </>
  );
};

export default CommandMenuOptions;
