import React from "react";
import clsx from "clsx";
import isNumber from "lodash/isNumber";
import { formatCurrency } from "utils/format";
import Skeleton from "components/Skeleton";
import CellEmpty from "./CellEmpty";
import CellPlaceholder from "./CellPlaceholder";

export type CellCurrencyFields = {
  value: string;
};

type CellCurrencyProps = CellCurrencyFields & {
  isLoading?: boolean;
  isEditable?: boolean;
  onClick?: () => void;
  className?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  isHeader?: boolean;
  textColor?: string;
};

const CellCurrency = ({
  value = "",
  isLoading = false,
  isEditable = false,
  onClick,
  className = "",
  onKeyDown,
  isHeader = false,
  textColor
}: CellCurrencyProps) => {
  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 w-16" />
      </div>
    );
  }

  if (!value && !isNumber(value) && !isEditable) return <CellEmpty className={className} />;

  if (!value && !isNumber(value) && isEditable) {
    return <CellPlaceholder onClick={onClick} className={className} onKeyDown={onKeyDown} />;
  }

  return (
    <span
      className={clsx("text-primary w-full text-sm", className, isHeader && "font-semibold")}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={isEditable ? 0 : undefined}
      style={{
        color: textColor
      }}
    >
      ${formatCurrency(value)}
    </span>
  );
};

export default React.memo(CellCurrency);
