import React, { useRef } from "react";
import clsx from "clsx";
import { useHover } from "usehooks-ts";
import { ExternalLinkIcon } from "lucide-react";

import { formatUrl } from "utils/format";
import Skeleton from "components/Skeleton";
import IconButton from "components/IconButton";
import { LINK_OPEN_LOCATIONS } from "utils/constants";
import useOpenRecordInNestedView from "hooks/useOpenRecordInNestedView";
import { copyText } from "utils/texts";
import toast from "utils/toast";
import Tooltip from "components/Tooltip";
import EllipisWithTooltip from "components/EllipisWithTooltip";
import CellEmpty from "./CellEmpty";
import CellPlaceholder from "./CellPlaceholder";

export type CellUrlFields = {
  value: string;
};

type CellUrlProps = CellUrlFields & {
  isLoading?: boolean;
  isEditable?: boolean;
  onClick?: () => void;
  className?: string;
  inList?: boolean;
  inGrid?: boolean;
  inCard?: boolean;
  link_open_location?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  singleClickToCopy?: boolean;
};

const CellUrl = ({
  value = "",
  isLoading = false,
  isEditable = false,
  onClick,
  className = "",
  inList = false,
  inGrid = false,
  inCard = false,
  link_open_location = LINK_OPEN_LOCATIONS.NEW_TAB,
  onKeyDown,
  singleClickToCopy
}: CellUrlProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const { openRecordInNestedView } = useOpenRecordInNestedView();

  const handleUrlButtonClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    if (link_open_location !== LINK_OPEN_LOCATIONS.SIDEBAR) return;

    const urlParts = (value || "").split("/");
    if (urlParts.length > 2) {
      const recordId = urlParts[urlParts.length - 1];
      const tablePath = urlParts[urlParts.length - 2];

      if (isNaN(+recordId)) {
        // if url last part is not a number i.e not a result I, fallback to opening url in new tab
        window.open(value?.startsWith("http") ? value : `http://${value}`, "_blank");
      } else {
        // handle sidebar Opening
        openRecordInNestedView({ id: recordId }, `/${tablePath}`);
      }
    }
  };

  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 w-16" />
      </div>
    );
  }

  if (!value && !isEditable) return <CellEmpty className={className} />;

  if (!value && isEditable) {
    return <CellPlaceholder onClick={onClick} className={className} onKeyDown={onKeyDown} />;
  }

  return (
    <div
      className={clsx(
        "relative flex w-full items-center text-sm font-medium",
        (inGrid || inList || inCard) && isHovered && "pr-12",
        inList && "overflow-x-hidden",
        inGrid && "!my-0 py-4",
        className
      )}
      onClick={onClick}
      ref={hoverRef}
      onKeyDown={onKeyDown}
      tabIndex={isEditable || singleClickToCopy ? 0 : undefined}
    >
      {!isEditable && singleClickToCopy ? (
        <Tooltip title="Click to copy" followCursor arrow={false}>
          <span
            className="cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap"
            onClick={async (e) => {
              e.stopPropagation();
              await copyText(formatUrl(value));
              toast.success("Copied to clipboard");
            }}
          >
            {formatUrl(value)}
          </span>
        </Tooltip>
      ) : (
        <EllipisWithTooltip as="span" className="overflow-hidden overflow-ellipsis whitespace-nowrap">
          {formatUrl(value)}
        </EllipisWithTooltip>
      )}

      {(inList || inGrid || inCard) && (
        <IconButton
          className={clsx("absolute rounded-lg", inCard ? "right-0" : "right-2", !isHovered && "invisible")}
          icon={ExternalLinkIcon}
          onClick={handleUrlButtonClick}
          color="primary"
          tag={link_open_location === LINK_OPEN_LOCATIONS.SIDEBAR ? "button" : "a"}
          target={link_open_location === LINK_OPEN_LOCATIONS.NEW_TAB ? "_blank" : undefined}
          rel="nofollow"
          href={
            link_open_location === LINK_OPEN_LOCATIONS.SIDEBAR
              ? undefined
              : value?.startsWith("http")
                ? value
                : `http://${value}`
          }
        />
      )}
    </div>
  );
};

export default CellUrl;
