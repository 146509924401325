import { PureComponent as Component } from "react";

const isMouseOverElement = ({ elem, e }: { elem: HTMLElement | null; e: any }) => {
  if (!elem) {
    return false;
  }
  const { pageY, pageX } = e;
  const { left, right, bottom, top } = elem?.getBoundingClientRect();

  return pageX > left && pageX < right && pageY > top && pageY < bottom;
};

const isMouseHovering =
  <P extends object>(key = "isMouseHovering") =>
  (DecoratedComponent: React.ComponentType<P>) => {
    class IsMouseHovering extends Component<P, { isHoveringOver: boolean }> {
      private el: HTMLElement | null = null;
      constructor(props: any) {
        super(props);
        this.state = {
          isHoveringOver: false,
        };
      }

      componentDidMount() {
        document.addEventListener("mousemove", this.onMouseMove);
      }

      componentWillUnmount() {
        document.removeEventListener("mousemove", this.onMouseMove);
      }

      onMouseMove = (e: MouseEvent) => {
        const elem = this.el;

        this.setState({
          isHoveringOver: isMouseOverElement({ elem, e }),
        });
      };

      render() {
        const hocProps = {
          [key]: {
            innerRef: (el: HTMLElement) => (this.el = el),
            isHoveringOver: this.state.isHoveringOver,
          },
        };

        return <DecoratedComponent {...hocProps} {...this.props} />;
      }
    }

    (IsMouseHovering as any).displayName = `IsMouseHovering(${(DecoratedComponent as any).displayName})`;

    return IsMouseHovering;
  };

export default isMouseHovering;
