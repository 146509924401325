import React, { useMemo } from "react";
import clsx from "clsx";
import Skeleton from "components/Skeleton";
import { isJSONString } from "utils/texts";
import CellPlaceholder from "./CellPlaceholder";
import CellEmpty from "./CellEmpty";

type Segment = {
  text: string;
  speaker: string;
};

type TranscriptionData = {
  Id: string;
  segments: Segment[] | string;
  speakers?: string;
  title: string;
};

type TranscriptProps = TranscriptionData & {
  isLoading?: boolean;
  isEditable?: boolean;
  onClick?: () => void;
  className?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  inList?: boolean;
};

const SPEAKER_1 = "SPEAKER 1";
const SPEAKER_2 = "SPEAKER 2";

const Transcript: React.FC<TranscriptProps> = ({
  Id,
  segments,
  speakers,
  isLoading = false,
  isEditable = false,
  onClick,
  className = "",
  onKeyDown,
  title = "",
  inList = false,
}) => {
  const finalSegments: Segment[] = useMemo(() => {
    if (typeof segments === "string") {
      if (!isJSONString(segments)) {
        return [];
      }

      const segmentsJSON = JSON.parse(segments);
      return segmentsJSON?.segments?.map((segment: any) => {
        return {
          text: segment.transcript?.[0]?.text,
          speaker: segment?.speaker === "A" ? SPEAKER_1 : SPEAKER_2,
        };
      });
    }

    return segments;
  }, [segments]);

  const [speaker1, speaker2] = useMemo(() => {
    if (typeof speakers === "string") {
      if (!isJSONString(speakers)) {
        return ["", ""];
      }

      const speakersJSON = JSON.parse(speakers);
      return [speakersJSON?.speaker_a, speakersJSON?.speaker_b];
    }

    return title.split(" called ");
  }, [speakers, title]);

  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 max-w-full" />
      </div>
    );
  }

  if (!segments && !isEditable) return <CellEmpty className={className} />;

  if (!segments && isEditable) {
    return <CellPlaceholder onClick={onClick} className={className} onKeyDown={onKeyDown} />;
  }

  return (
    <section
      id={`transcript-${Id}`}
      className={clsx("text-left", inList && "!px-0", className)}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={isEditable ? 0 : undefined}
    >
      <div className="">
        {speaker1 && speaker2 && (
          <p className="pb-4 text-base text-primary-700">
            <span className="font-semibold text-green-800">{speaker1?.trim()}</span> took a call from{" "}
            <span className="font-semibold text-blue-800">{speaker2?.trim()}</span>
          </p>
        )}

        <div className="flex flex-col gap-y-6">
          {finalSegments.map((segment, index) => (
            <div key={index} className="flex items-stretch">
              <div
                className={`mr-3 w-[3px] min-w-[3px] rounded ${
                  segment.speaker === SPEAKER_1 ? "bg-green-700" : "bg-blue-800"
                }`}
              />
              <p className="flex-auto font-sans text-base text-primary-700">{segment.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Transcript;
