import { TableColumnType } from "types/baTypes";
import { RecordItem, SelectOption, SelectOptions } from "types/common";
import { isColumnTextWithOptions } from "utils/columnUtils";
import { CellType } from "utils/constants";
import { getCellForType } from "../utils";

const SelectedCells = ({
  selected,
  isMultiple,
  column,
  isLoading,
  additionalValueFields,
  handleRemoveItem,
  cell: Cell,
  isMultiColumnOptions
}: {
  selected?: RecordItem | SelectOption[] | SelectOption | null;
  isMultiple?: boolean;
  column?: TableColumnType;
  isLoading?: boolean;
  additionalValueFields?: RecordItem;
  handleRemoveItem?: (item: SelectOption) => void;
  cell: React.FC<any>;
  isMultiColumnOptions?: boolean;
}) => {
  const finalColumn = isMultiColumnOptions ? (selected as RecordItem)?.column : column;
  const FinalCell = isMultiColumnOptions ? getCellForType((selected as RecordItem)?.column?.type || "") : Cell;
  const finalType = isMultiColumnOptions ? (selected as RecordItem)?.column?.type : column?.type;
  return (
    <>
      {selected && !isMultiple && (
        <FinalCell
          value={
            finalType === CellType.GENERIC_CELL
              ? (selected as SelectOption)?.record
              : column && isColumnTextWithOptions(column)
                ? (selected as SelectOption)?.value
                : undefined
          }
          {...((selected as SelectOption)?.record || {})}
          isLoading={isLoading}
          column={finalColumn}
          hideColorName={column?.type === CellType.COLOR ? column.cellConfig?.hideColorName : undefined}
          {...column?.cellConfig}
          {...additionalValueFields}
        />
      )}
      {Array.isArray(selected) && !!selected?.length && isMultiple && (
        <div className="flex w-full flex-wrap gap-1">
          {(selected as SelectOptions)?.map((item, index) => (
            <Cell
              key={item.value || index}
              value={column?.type === CellType.GENERIC_CELL ? item?.record : undefined}
              column={column}
              {...(item?.record || {})}
              onRemoveClick={() => handleRemoveItem?.(item)}
              isLoading={isLoading}
              isMultiple={isMultiple}
              hideColorName={column?.type === CellType.COLOR ? column.cellConfig?.hideColorName : undefined}
              isEditable
              showRemoveIcon
              {...additionalValueFields}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default SelectedCells;
