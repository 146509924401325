import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const StyledKeyboardShortcut = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fafafa",
    padding: "8px",
    border: "1px solid #E7E8EA",
    borderRadius: "6px",
    marginTop: "8px !important",
    boxShadow: "0px 4px 12px 0px #00000029",
  },
}));

type KeyboardShortcutProps = {
  action: string;
  shortcuts: string[];
  children: any;
  disabled?: boolean;
};

const KeyboardShortcut = ({ action, shortcuts, disabled, children }: KeyboardShortcutProps) => {
  return (
    <StyledKeyboardShortcut
      title={
        <div className="flex items-center gap-x-2">
          <span className="text-xs text-base-disabled">{action}</span>
          <div className="flex items-center gap-x-1">
            {shortcuts.map((shortcut) => (
              <span
                key={shortcut}
                className="inline-flex rounded bg-neutral-200 px-1.5 py-0.5 text-xs text-base-secondary"
              >
                {shortcut}
              </span>
            ))}
          </div>
        </div>
      }
      disableHoverListener={disabled}
    >
      {disabled ? <>{children}</> : <div>{children}</div>}
    </StyledKeyboardShortcut>
  );
};

export default KeyboardShortcut;
