import { Listbox } from "@headlessui/react";
import clsx from "clsx";
import { CheckCircle2 as CheckIcon } from "lucide-react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useRef } from "react";
import { SelectOptions } from "types/common";
import { CellType } from "utils/constants";
import { TableColumnType } from "types/baTypes";
import { isColumnTextWithOptions } from "utils/columnUtils";
import { getCellForType } from "../utils";

type SelectOptionsVirtualProps = {
  showCreate?: boolean;
  isLoading?: boolean;
  options?: SelectOptions;
  Cell: React.FC<any>;
  type: CellType;
  column?: TableColumnType;
  OptionsCell?: React.FC<any>;
  hasGenericOptions?: boolean;
  isMultiColumnOptions?: boolean;
};

const getEstimateSize = (type: CellType) => {
  if (type === CellType.PROJECT || type === CellType.BADGE) {
    return 68;
  } else if (type === CellType.GENERIC_CELL) {
    return 60;
  }

  return 40;
};

const SelectOptionsVirtual = ({
  showCreate,
  isLoading,
  options = [],
  Cell,
  type,
  column,
  OptionsCell,
  hasGenericOptions,
  isMultiColumnOptions
}: SelectOptionsVirtualProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const parentOffsetRef = useRef(0);

  const count = options?.length || 0;
  const rowVirtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => getEstimateSize(type),
    scrollMargin: parentOffsetRef.current,
    overscan: 6
  });

  const items = rowVirtualizer.getVirtualItems();

  const translateY = items[0]?.start - rowVirtualizer.options.scrollMargin || 0;

  return (
    <main className={clsx("max-h-[250px] space-y-1 overflow-y-auto pb-1", !showCreate && "pt-1")} ref={parentRef}>
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize() || 40}px`
        }}
        className="relative w-full"
      >
        <div
          data-testid="SelectCellGroupOptions"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            transform: `translateY(${translateY}px)`
          }}
        >
          {isLoading
            ? [1, 2, 3, 4, 5].map((item) => (
                <Listbox.Option
                  key={item}
                  className={({ active }) =>
                    clsx(
                      "text-primary relative flex w-full cursor-pointer items-center  rounded-md px-2.5 py-2 font-medium hover:bg-gray-100 active:bg-neutral-200 dark:hover:bg-gray-700 dark:active:bg-neutral-dark-200",
                      active && "bg-neutral-100 dark:bg-neutral-dark-100"
                    )
                  }
                  value={item}
                  as="div"
                >
                  <Cell className="py-2 text-sm" isEditable={false} isInOptions isLoading />
                </Listbox.Option>
              ))
            : items?.map((virtualRow) => (
                <Listbox.Option
                  data-testid="SelectCellGroupOption"
                  key={virtualRow.key}
                  className={({ active }) =>
                    clsx(
                      "text-primary relative flex w-full cursor-pointer items-center  rounded-md px-2.5 py-2 font-medium hover:bg-gray-100 active:bg-neutral-200 dark:hover:bg-gray-700 dark:active:bg-neutral-dark-200",
                      active && "bg-neutral-100 dark:bg-neutral-dark-100"
                    )
                  }
                  value={options[virtualRow.index]}
                  as="div"
                >
                  {({ selected }) => {
                    const finalColumn = isMultiColumnOptions ? options?.[virtualRow.index]?.column : column;
                    const FinalCell = isMultiColumnOptions
                      ? getCellForType(options?.[virtualRow.index]?.column?.type || "")
                      : Cell;
                    const finalType = isMultiColumnOptions ? options?.[virtualRow.index]?.column?.type : type;
                    return (
                      <>
                        {OptionsCell ? (
                          <OptionsCell
                            {...(options?.[virtualRow.index]?.record || {})}
                            value={
                              finalType === CellType.GENERIC_CELL || hasGenericOptions
                                ? options?.[virtualRow.index]?.record
                                : column && isColumnTextWithOptions(column)
                                  ? options?.[virtualRow.index]?.value
                                  : undefined
                            }
                            className="py-2 text-sm"
                            isEditable={false}
                            isInOptions
                            isSelected={selected}
                            column={finalColumn}
                          />
                        ) : (
                          <FinalCell
                            value={
                              finalType === CellType.GENERIC_CELL
                                ? options?.[virtualRow.index]?.record
                                : column && isColumnTextWithOptions(column)
                                  ? options?.[virtualRow.index]?.value
                                  : undefined
                            }
                            {...(options?.[virtualRow.index]?.record || {})}
                            className="py-2 text-sm"
                            isEditable={false}
                            isInOptions
                            isSelected={selected}
                            column={finalColumn}
                          />
                        )}
                        {selected && (
                          <CheckIcon className="absolute right-3 h-5 w-5 text-primary-700 dark:text-primary-dark-700" />
                        )}
                      </>
                    );
                  }}
                </Listbox.Option>
              ))}

          {!options?.length && !isLoading && (
            <div className="p-3 text-sm font-medium text-base-disabled dark:text-base-dark-disabled">No results</div>
          )}
        </div>
      </div>
    </main>
  );
};

export default SelectOptionsVirtual;
