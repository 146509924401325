import clsx from "clsx";
import noop from "lodash/noop";

import Skeleton from "components/Skeleton";
import CellEmpty from "./CellEmpty";

export type CellStatusFields = {
  color: string;
  name: string;
};

type CellStatusProps = CellStatusFields & {
  isLoading?: boolean;
  isHeader?: boolean;
  onClick?: () => void;
};

// @FAKEDATA: Update this with the correct color or mapping to a status
const DEFAULT_COLOR = "#2F6FED";

const CellStatus = ({ color, name, isLoading = false, isHeader = false, onClick = noop }: CellStatusProps) => {
  if (isLoading) {
    return (
      <div className="flex items-center gap-x-2">
        <Skeleton className="h-2 w-2 rounded-full" />
        <Skeleton className="h-3 w-16" />
      </div>
    );
  }

  if (!name) return <CellEmpty />;

  return (
    <div data-testid="CellStatus" className="flex items-center gap-x-2 py-0.5" onClick={onClick}>
      <div className="h-2 w-2 shrink-0 rounded-full" style={{ backgroundColor: color || DEFAULT_COLOR }} />
      <span className={clsx("whitespace-nowrap text-sm", isHeader ? "font-semibold" : "font-medium")}>{name}</span>
    </div>
  );
};

export default CellStatus;
