import React from "react";
import clsx from "clsx";

import Skeleton from "components/Skeleton";
import Tooltip from "components/Tooltip";
import { htmlToPlainText, isHTMLStringEmpty } from "utils/texts";
import CellPlaceholder from "./CellPlaceholder";
import CellEmpty from "./CellEmpty";

type CellLongTextFields = {
  value: string;
};

type CellLongTextProps = CellLongTextFields & {
  isLoading?: boolean;
  isEditable?: boolean;
  onClick?: () => void;
  className?: string;
  inGrid?: boolean;
  inCard?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  autoHeight?: boolean;
};

const CellLongText = ({
  value = "",
  isLoading = false,
  onClick,
  isEditable = false,
  className = "",
  inGrid,
  inCard,
  onKeyDown,
  autoHeight
}: CellLongTextProps) => {
  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 max-w-full" />
      </div>
    );
  }

  const valueIsEmptyHTML = isHTMLStringEmpty(value);

  if (valueIsEmptyHTML && !isEditable) return <CellEmpty className={className} />;

  if (valueIsEmptyHTML && isEditable) {
    return <CellPlaceholder onClick={onClick} className={className} onKeyDown={onKeyDown} />;
  }

  if ((inGrid || inCard) && !autoHeight) {
    return (
      <Tooltip title={htmlToPlainText(value)} disableInteractive>
        <div
          className={clsx(
            "line-clamp-2 flex max-h-[40px] w-full overflow-hidden border border-transparent bg-transparent text-sm",
            className
          )}
          onClick={onClick}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      </Tooltip>
    );
  }

  return (
    <div
      className={clsx("w-full border border-transparent bg-transparent text-sm", className)}
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: value.replace(/\n/g, "<br />") }}
      onKeyDown={onKeyDown}
      tabIndex={isEditable ? 0 : undefined}
    />
  );
};

export default CellLongText;
