"use client";

import { useCallback, useEffect, useState } from "react";
import dynamic from "next/dynamic";

import clsx from "clsx";
import { FileTextIcon } from "lucide-react";
import { buildSupabasePublicUrl } from "components/Image/utils";
import PPTIcon from "components/MimeTypeIcons/PPTIcon";
import useMultiFilesUpload from "hooks/useMultiFilesUpload";
import useTableActionsState from "hooks/useTableActionsState";
import useUpdateRecord from "hooks/useUpdateRecord";
import { processFilesForUpload } from "utils/file";

const ThumbnailGenerator = dynamic(() => import("./ThumbnailGenerator"), { ssr: false });

type DocumentProps = {
  src: string;
  mimeType?: string;
  fileId?: string;
  thumbnailPath?: string;
  thumbnailSizes?: string;
  size?: "small" | "medium";
  recordId?: string;
  disableThumbnailSave?: boolean;
};

const DocumentThumbnailPreview = ({
  src,
  mimeType,
  fileId,
  thumbnailPath,
  thumbnailSizes,
  size,
  recordId,
  disableThumbnailSave
}: DocumentProps) => {
  const { updateRecordAsync } = useUpdateRecord();
  const { startUpload } = useMultiFilesUpload();
  const { triggerAction, updateTriggerAction } = useTableActionsState();
  const [regenThumbnail, setRegenThumbnail] = useState(false);

  const isExternal = src.startsWith("http") || src.startsWith("blob");
  const finalSrc = isExternal ? src : buildSupabasePublicUrl(src);

  const thumbnailHandler = useCallback(
    async (snapshot: string) => {
      if (snapshot?.length && fileId && (!thumbnailPath || regenThumbnail) && !disableThumbnailSave) {
        const fetchResp = await fetch(snapshot);
        const fileBlob = await fetchResp.blob();
        const file = new File([fileBlob], "thumbnail.png", { type: fileBlob.type });

        const finalFileData = processFilesForUpload([file], "thumbnails");
        if (finalFileData.length === 0) return;
        const uploadResp = await startUpload(finalFileData, true);
        if (uploadResp.uploadCount) {
          // ##HARDCODED
          await updateRecordAsync({
            tableName: "files",
            input: {
              id: fileId,
              thumbnail_path: finalFileData[0].path
            }
          });
          setRegenThumbnail(false);
        }
      }
    },
    [fileId, thumbnailPath, updateRecordAsync, startUpload, regenThumbnail, disableThumbnailSave]
  );

  useEffect(() => {
    if (!triggerAction?.recordId || !recordId || triggerAction.recordId !== recordId) return;
    setRegenThumbnail(true);
  }, [triggerAction, updateTriggerAction, recordId]);

  if (!finalSrc)
    return (
      <FileTextIcon
        className={clsx("h-14 w-14 text-[#6A6F79] dark:text-white", size === "small" ? "scale-[60%]" : "")}
      />
    );

  if (mimeType === "application/pdf") {
    return (
      <ThumbnailGenerator
        filePath={finalSrc}
        thumbnailHandler={thumbnailHandler}
        thumbnailPath={thumbnailPath}
        thumbnailSizes={thumbnailSizes}
        regenThumbnail={regenThumbnail}
      />
    );
  }

  const pptMimeTypes = [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  ];

  return (
    <div className="flex h-full w-full items-center justify-center">
      {!!(mimeType && pptMimeTypes.includes(mimeType)) ? (
        <PPTIcon className={size === "small" ? "scale-[60%]" : ""} />
      ) : (
        <FileTextIcon
          className={clsx("h-14 w-14 text-[#6A6F79] dark:text-white", size === "small" ? "scale-[60%]" : "")}
        />
      )}
    </div>
  );
};

export default DocumentThumbnailPreview;
