import clsx from "clsx";

const CellEmpty = ({
  className,
  onClick,
  emptyLabel
}: { className?: string; emptyLabel?: string } & React.ComponentPropsWithoutRef<"div">) => {
  return (
    <div className={clsx("text-sm text-base-disabled dark:text-base-dark-disabled", className)} onClick={onClick}>
      {emptyLabel ? emptyLabel : "-"}
    </div>
  );
};

export default CellEmpty;
