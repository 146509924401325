import { useState, useRef, useCallback, useEffect } from "react";

import ThumbnailImage from "components/DocumentThumbnailPreview/ThumbnailImage";
import { buildSupabasePublicUrl } from "components/Image/utils";
import AviIcon from "components/MimeTypeIcons/AVIIcon";

type VideoThumbnailProps = {
  thumbnailHandler: (snapshot: string) => void;
  src: string;
  className?: string;
  showPreview?: boolean;
  thumbnailPath?: string;
  thumbnailSizes?: string;
  mimeType?: string;
};

const VideoThumbnailDisplay = ({
  src,
  thumbnailHandler,
  className = "",
  showPreview = false,
  thumbnailPath,
  thumbnailSizes,
  mimeType,
  ...rest
}: VideoThumbnailProps) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [metadataLoaded, setMetadataLoaded] = useState(false);
  const [snapshot, setSnapshot] = useState(thumbnailPath);

  const videoEl = useRef<HTMLVideoElement>(null);
  const canvasEl = useRef<HTMLCanvasElement>(null);

  const isExternal = src.startsWith("http") || src.startsWith("blob");
  const finalSrc = isExternal ? src : buildSupabasePublicUrl(src);

  const getSnapShot = useCallback(() => {
    if (thumbnailPath) return;
    try {
      const video = videoEl.current;
      const canvas = canvasEl.current;
      if (!canvas || !video) {
        return;
      }
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      const canvasContext = canvas.getContext("2d");
      if (!canvasContext) {
        return;
      }
      canvasContext.drawImage(video, 0, 0);
      const thumbnail = canvas.toDataURL("image/png");

      // Remove video & canvas elements as snapshot image will be displayed instead
      video.src = ""; // setting to empty string stops video from loading
      video.remove();
      canvas.remove();

      setSnapshot(thumbnail);

      // pass the thumbnail url back to parent component's thumbnail handler (if any)
      thumbnailHandler?.(thumbnail);
    } catch (e) {
      console.error(e);
    }
  }, [thumbnailHandler, thumbnailPath]);

  useEffect(() => {
    if (dataLoaded && metadataLoaded && !thumbnailPath && !snapshot) {
      videoEl.current?.setAttribute("crossorigin", "anonymous");
      getSnapShot();
    }
  }, [dataLoaded, metadataLoaded, getSnapShot, thumbnailPath, snapshot]);

  if (mimeType === "video/avi") {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <AviIcon />
      </div>
    );
  }

  if (!snapshot) {
    return (
      <div>
        <canvas ref={canvasEl} className="snapshot-generator"></canvas>
        <video
          ref={videoEl}
          src={`${finalSrc}${showPreview ? "#t=5,10" : ""}`}
          className={className}
          preload="metadata"
          onLoadedMetadata={() => setMetadataLoaded(true)}
          onLoadedData={() => setDataLoaded(true)}
          crossOrigin="anonymous"
          {...rest}
        ></video>
      </div>
    );
  }

  return <ThumbnailImage snapshot={snapshot as string} thumbnailSizes={thumbnailSizes} />;
};

export default VideoThumbnailDisplay;
