import clsx from "clsx";
import isEmpty from "lodash/isEmpty";

import sortBy from "lodash/sortBy";
import ActionsHandler from "components/ActionsHandler";
import Icon from "components/Icon";
import Image from "components/Image";
import Skeleton from "components/Skeleton";

import { GenericCellColumn } from "types/apiTypes";
import { TableColumnType, ViewAction } from "types/baTypes";
import { RecordItem } from "types/common";

import { COLUMN_CONDITIONAL_VISIBILITY } from "components/Admin/utils";
import { getCheckConditionRulePassedFn, getFinalVisibilityFromColumnRule } from "utils/columnUtils";
import { CellType, GENERIC_CELL_LAYOUTS, ViewOption } from "utils/constants";
import { formatCurrency, formatPhoneNumber, formatUrl } from "utils/format";

import CellDisplayHandler from "../CellDisplayHandler";
import CellEmpty from "./CellEmpty";

// ##HARDCODED default color for now
const DEFAULT_COLOR = "#2F6FED";

export type CellGenericValueProps =
  | {
      title?: string;
      subTitle?: string;
      display?: string;
      displayType?: "color" | "icon" | "file";
      layout: GENERIC_CELL_LAYOUTS.DEFAULT;
    }
  | {
      [key: string]: string | undefined;
      layout: GENERIC_CELL_LAYOUTS.COMMA_SEPARATED | GENERIC_CELL_LAYOUTS.SEARCH_RESULT;
    }
  | {
      [key: string]: string | CombinationCellValue | undefined;
      layout: GENERIC_CELL_LAYOUTS.COMBINATION_CELL;
    };

type CombinationCellValue = {
  column: TableColumnType;
  value: RecordItem;
};
type CellGenericProps = {
  value: CellGenericValueProps;
  isLoading?: boolean;
  isEditable?: boolean; // Is always false for this cell for now
  onClick?: () => void;
  className?: string;
  additionalText?: string;
  row?: RecordItem;
  column?: TableColumnType;
  columnTableName?: string;
  columnTablePath?: string;
  allColumns?: TableColumnType[];
  actions?: ViewAction[];
  inList?: boolean;
  isHeader?: boolean;
};

const CellGeneric = ({
  value,
  isLoading = false,
  onClick,
  isEditable = false,
  isHeader = false,
  className = "",
  allColumns = [],
  actions = [],
  ...props
}: CellGenericProps) => {
  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 max-w-full" />
      </div>
    );
  }

  if (value?.layout === GENERIC_CELL_LAYOUTS.COMMA_SEPARATED) {
    const finalValue = Object.keys(value)
      ?.filter(
        (key) =>
          key !== "layout" &&
          (props.column?.cellConfig?.genericConfigForColumnOptions?.columns?.find((col) => col.columnName === key) ||
            props.column?.cellConfig?.genericConfig?.columns?.find((col) => col.columnName === key))
      )
      ?.map((key) => {
        const keyValue = value[key];
        const col = props.column?.cellConfig?.genericConfig?.columns?.find((col) => col.columnName === key);

        if (col?.cellType === CellType.CURRENCY && keyValue) {
          return `$${formatCurrency(keyValue)}`;
        }

        if (col?.cellType === CellType.URL && keyValue) {
          return formatUrl(keyValue);
        }

        if (col?.cellType === CellType.PHONE && keyValue) {
          return formatPhoneNumber(keyValue);
        }

        if (col?.cellType === CellType.PERCENT && keyValue) {
          return `${keyValue}%`;
        }

        return keyValue;
      })
      ?.filter(Boolean)
      .join(", ");

    if (!finalValue) {
      return <CellEmpty className={className} />;
    }

    return <div className="line-clamp-2 text-sm">{finalValue}</div>;
  }

  if (value?.layout === GENERIC_CELL_LAYOUTS.SEARCH_RESULT) {
    if (!value.title && !value?.subTitle) return <CellEmpty className={className} />;

    return (
      <div className={clsx("flex items-center gap-2", className)} onClick={onClick}>
        <div className="h-5 w-5">{value.icon && <Icon name={value.icon as any} className="shrink-0" />}</div>
        <div className="flex flex-col">
          <div className={clsx("text-sm", isHeader && "font-semibold")}>{value.title}</div>
          <div className="text-xs">{value.subTitle}</div>
        </div>
      </div>
    );
  }

  if (value?.layout === GENERIC_CELL_LAYOUTS.COMBINATION_CELL) {
    const columns = sortBy(
      allColumns.filter((col) => props.column?.cellConfig?.genericConfig?.columns?.find((c) => c.columnId === col.id)),
      (col) => {
        return props.column?.cellConfig?.genericConfig?.columns?.findIndex((c) => c.columnId === col.id);
      }
    );

    if (!!columns?.length && columns?.every((col) => col.type === CellType.BUTTON)) {
      return (
        <>
          {columns.map((col) => {
            const action = actions?.find((action) => action.id === col.cellConfig?.action);

            if (!col || !props.columnTableName || !props.row) {
              return null;
            }

            const selectedColumnId =
              col.conditionalViewRules?.columnRules?.[0]?.column?.id ||
              col.conditionalViewRules?.columnRules?.[0]?.columnId;

            if (col && col.conditionalViewRules?.ruleConfig?.views?.includes(ViewOption.GRID) && selectedColumnId) {
              if (
                getFinalVisibilityFromColumnRule({
                  checkRulePassed: getCheckConditionRulePassedFn({
                    columnOptions: allColumns,
                    row: { ...props.row },
                    column: col,
                    view: ViewOption.CARD
                  }),
                  columnRule: col.conditionalViewRules
                }) === COLUMN_CONDITIONAL_VISIBILITY.HIDE
              ) {
                return null;
              }
            }
            const isCellButtonAction = col.type === CellType.BUTTON && !col.cellConfig?.isLink;

            if (isCellButtonAction && action) {
              const actionProps = {
                actions: action ? [action] : [],
                tableName: props.columnTableName || "",
                tablePath: props.columnTablePath || "",
                formattedRecord: props.row?.formattedRecordVal,
                record: props.row
              };
              return (
                <ActionsHandler
                  key={col.id}
                  displayMode={CellDisplayHandler}
                  additionalProps={{
                    value,
                    column: col,
                    row: props.row,
                    type: col.type,
                    cellWrapperClassName: "flex flex-row !w-auto"
                  }}
                  {...actionProps}
                />
              );
            }

            return (
              <CellDisplayHandler
                key={col.id}
                type={col.type}
                {...props}
                column={col}
                isHeader={isHeader}
                onClick={onClick}
              />
            );
          })}
        </>
      );
    }

    // Temporarily setting show all Cells to true
    if (props.column?.cellConfig?.genericConfig?.showAllCells) {
      const showIcon =
        props.column?.cellConfig?.genericConfig?.isSeparatorIcon || !props.column?.cellConfig?.genericConfig?.separator;
      const someValuesPresent = columns.some((col) => !isEmpty((value?.[col.id] as CombinationCellValue)?.value));
      if (!someValuesPresent && isEditable) {
        return <CellEmpty className={className} emptyLabel={isEditable ? "Add" : ""} onClick={onClick} />;
      }
      return (
        <div className="flex w-full items-center gap-x-2">
          {columns.map((col, index) => {
            const finalValue = value?.[col.id] as CombinationCellValue;
            return (
              <>
                <CellDisplayHandler
                  key={`generic_${col.id}`}
                  {...props}
                  type={col.type}
                  value={finalValue?.value}
                  column={col}
                  className={clsx(props.inList && "!min-h-fit flex-1 !whitespace-normal !py-0")}
                  isHeader={isHeader}
                  onClick={onClick}
                  hideEmpty
                />
                {index !== columns.length - 1 &&
                !isEmpty((value?.[columns?.[index]?.id] as CombinationCellValue)?.value) &&
                !isEmpty((value?.[columns?.[index + 1]?.id] as CombinationCellValue)?.value) ? (
                  showIcon ? (
                    <Icon
                      name={props.column?.cellConfig?.genericConfig?.separator || ("corner-down-right" as any)}
                      className="h-4 w-4 shrink-0 text-base-disabled"
                    />
                  ) : props.column?.cellConfig?.genericConfig?.separator ? (
                    <span>{props.column.cellConfig?.genericConfig?.separator}</span>
                  ) : null
                ) : null}
              </>
            );
          })}
        </div>
      );
    }

    const dataFieldKeys = Object.keys(value)?.filter((key) => key !== "layout");
    const displayValueKey = dataFieldKeys.find(
      (key) => key === props.column?.cellConfig?.genericConfig?.display?.columnName || ""
    );
    const titleValueKey = dataFieldKeys.find(
      (key) => key === props.column?.cellConfig?.genericConfig?.title?.columnName || ""
    );
    const subTitleValueKey = dataFieldKeys.find(
      (key) => key === (props.column?.cellConfig?.genericConfig?.subTitle as GenericCellColumn)?.columnName || ""
    );

    if (!dataFieldKeys.length) {
      return <CellEmpty className={className} emptyLabel={isEditable ? "Add" : ""} />;
    }

    return (
      <div className={clsx("flex w-full flex-row gap-x-2 overflow-hidden", "!py-0", className)} onClick={onClick}>
        {displayValueKey ? (
          <div className="mr-1 flex items-center justify-center pl-1 text-sm">
            <CellDisplayHandler
              {...props}
              type={(value[displayValueKey] as CombinationCellValue)?.column.type}
              value={(value[displayValueKey] as CombinationCellValue).value}
              column={(value[displayValueKey] as CombinationCellValue).column}
              className={clsx(props.inList && "!min-h-fit !py-0")}
              isHeader={isHeader}
              onClick={onClick}
            />
          </div>
        ) : null}
        {titleValueKey && value?.[titleValueKey] ? (
          <div className={clsx("flex w-full flex-1 flex-col", !subTitleValueKey && "justify-center")}>
            <div className={clsx("line-clamp-1 text-md", isHeader && "font-semibold")}>
              <CellDisplayHandler
                {...props}
                type={(value[titleValueKey] as CombinationCellValue)?.column.type}
                value={(value[titleValueKey] as CombinationCellValue).value}
                column={(value[titleValueKey] as CombinationCellValue).column}
                // classNameContainer="w-full !min-h-fit !my-0"
                className={clsx(props.inList && "!min-h-fit !py-0")}
                isHeader={isHeader}
                onClick={onClick}
              />
            </div>
            {subTitleValueKey && value?.[subTitleValueKey] ? (
              <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm">
                {" "}
                <CellDisplayHandler
                  {...props}
                  type={(value[subTitleValueKey] as CombinationCellValue)?.column.type}
                  value={(value[subTitleValueKey] as CombinationCellValue).value}
                  column={(value[subTitleValueKey] as CombinationCellValue).column}
                  // classNameContainer="w-full !min-h-fit !my-0"
                  className={clsx(props.inList && "!min-h-fit !py-0")}
                  isHeader={isHeader}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }

  if (!value?.title && !value?.subTitle)
    return <CellEmpty className={className} emptyLabel={isEditable ? "Add" : ""} />;

  return (
    <div className={clsx("flex w-full flex-row gap-x-2 overflow-hidden", "!py-0", className)} onClick={onClick}>
      {value?.display ? (
        <div className="mr-1 flex items-center justify-center pl-1 text-sm">
          {value?.displayType === "color" && (
            <div
              className="h-2 w-2 shrink-0 rounded-full"
              style={{ backgroundColor: value?.display || DEFAULT_COLOR }}
            />
          )}
          {value?.displayType === "file" && (
            <Image
              alt=""
              src={value?.display}
              className="h-11 !w-11 shrink-0 rounded object-cover"
              width={44}
              height={44}
            />
          )}
          {/* TODO: Extend support for icon will be available in value.display */}
        </div>
      ) : null}
      <div className={clsx("flex w-full flex-1 flex-col", !value?.subTitle && "justify-center")}>
        <div className={clsx("line-clamp-1 text-md", isHeader && "font-semibold")}>{value?.title}</div>
        {value?.subTitle ? (
          <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm">{value?.subTitle}</div>
        ) : null}
      </div>
    </div>
  );
};

export default CellGeneric;
