import React from "react";
import clsx from "clsx";
import Skeleton from "components/Skeleton";
import CellEmpty from "./CellEmpty";
import CellPlaceholder from "./CellPlaceholder";

export type CellPercentFields = {
  value: string;
};

type CellPercentProps = CellPercentFields & {
  isLoading?: boolean;
  isEditable?: boolean;
  onClick?: () => void;
  className?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  isHeader?: boolean;
  textColor?: string;
};

const CellPercent = ({
  value,
  isLoading = false,
  isEditable = false,
  onClick,
  className = "",
  onKeyDown,
  isHeader = false,
  textColor
}: CellPercentProps) => {
  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 w-16" />
      </div>
    );
  }

  if ((value === undefined || value === null) && !isEditable) return <CellEmpty className={className} />;

  if ((value === undefined || value === null) && isEditable) {
    return <CellPlaceholder onClick={onClick} className={className} onKeyDown={onKeyDown} />;
  }

  return (
    <span
      className={clsx("primary w-full text-sm", className, isHeader && "font-semibold")}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={isEditable ? 0 : undefined}
      style={{
        color: textColor
      }}
    >
      {value}%
    </span>
  );
};

export default React.memo(CellPercent);
