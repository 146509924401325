import { useMemo } from "react";
import clsx from "clsx";
import noop from "lodash/noop";

import Image from "components/Image";
import Skeleton from "components/Skeleton";
import { FALLBACK_IMAGE_SRC, Size } from "utils/constants";
import CellEmpty from "./CellEmpty";

export type CellProductFields = {
  name: string;
  tags: string[];
  path: string;
  id: string;
  products_files?: {
    file_id: {
      path: string;
    };
  }[];
};

type CellProductProps = CellProductFields & {
  isLoading?: boolean;
  isInOptions?: boolean;
  size?: Size;
  isHovered?: boolean;
  isHeader?: boolean;
  onClick?: () => void;
};

const CellProduct = ({
  name: name,
  tags,
  path: featuredImage,
  isInOptions = false,
  isLoading = false,
  size = Size.SM,
  isHovered,
  isHeader = false,
  products_files: productFiles,
  onClick = noop
}: CellProductProps) => {
  const sizeProps = {
    [Size.SM]: {
      className: "w-8 h-8 shrink-0",
      containerClassName: "items-center grid-cols-[32px_minmax(100px,1fr)]",
      width: 32,
      height: 32
    },
    [Size.MD]: {
      className: "w-8 h-8 shrink-0",
      containerClassName: "items-center grid-cols-[32px_minmax(100px,1fr)]",
      width: 32,
      height: 32
    },
    [Size.LG]: {
      className: "w-[117px] h-[117px] shrink-0",
      containerClassName: "items-start grid-cols-[117px_minmax(100px,1fr)]",
      width: 117,
      height: 117
    }
  };

  const finalFeaturedImage = useMemo(() => {
    if (featuredImage) {
      return featuredImage;
    }

    if (productFiles?.length) {
      return productFiles?.[0]?.file_id?.path;
    }

    return FALLBACK_IMAGE_SRC;
  }, [productFiles, featuredImage]);

  if (isLoading) {
    return (
      <div className="flex items-center gap-x-3">
        <Skeleton className="h-8 w-8 rounded" />
        <div className="max-w-[160px] space-y-2">
          <Skeleton className="h-3 w-full" />
          <Skeleton className="h-3 w-[90%]" />
        </div>
      </div>
    );
  }

  if (!name) {
    return <CellEmpty />;
  }

  return (
    <div
      className={clsx(
        "relative grid w-full gap-x-3 py-0.5 text-sm",
        sizeProps[size]?.containerClassName,
        isHovered && "pr-12"
      )}
      onClick={onClick}
    >
      <Image
        src={finalFeaturedImage}
        alt={name}
        width={sizeProps[size]?.width}
        height={sizeProps[size]?.height}
        className={clsx("shrink-0 overflow-hidden rounded", sizeProps[size]?.className)}
      />
      <div className="max-w-full overflow-hidden text-sm font-normal">
        <div
          className={clsx(
            "text-primary overflow-hidden text-ellipsis whitespace-nowrap antialiased ",
            isInOptions ? "max-w-[190px]" : "",
            isHeader ? "font-semibold" : "font-medium"
          )}
        >
          {name}
        </div>
        {tags?.length > 0 && (
          <div
            className={clsx(
              "overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium text-base-disabled antialiased dark:text-base-dark-disabled",
              isInOptions ? "max-w-[190px]" : ""
            )}
          >
            {tags?.join(", ")}
          </div>
        )}
      </div>
    </div>
  );
};

export default CellProduct;
