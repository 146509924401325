import { DollarSign, PercentIcon } from "lucide-react";

import isEmpty from "lodash/isEmpty";
import { CellType } from "utils/constants";
import { formatUrl, removeHttpFromUrl } from "utils/format";

import {
  getColumnOptionsLookupPropAndColumnName,
  readCellFilesLookupFields,
  readCellProjectWithFiles,
  readLookupFields
} from "lib/utils";
import { ApiRecordType } from "types/apiTypes";
import { BAFile, TableColumnType } from "types/baTypes";
import { RecordItem, SelectOptions } from "types/common";
import { getGenericCellValuesFromRecord } from "utils/dataUtils";
import { ExtendedSchema } from "utils/schema";
import CellAddress from "./Cells/CellAddress";
import CellAudio from "./Cells/CellAudio";
import CellBadge from "./Cells/CellBadge";
import CellBeforeAfter from "./Cells/CellBeforeAfter";
import CellBoolean from "./Cells/CellBoolean";
import CellButton from "./Cells/CellButton";
import CellColor from "./Cells/CellColor";
import CellCompany, { CellCompanyFields } from "./Cells/CellCompany";
import CellCoordinate from "./Cells/CellCoordinate";
import CellCurrency from "./Cells/CellCurrency";
import CellDate from "./Cells/CellDate";
import CellDateTime from "./Cells/CellDateTime";
import CellEmail from "./Cells/CellEmail";
import CellExist from "./Cells/CellExist";
import CellFile from "./Cells/CellFile";
import CellGeneric from "./Cells/CellGeneric";
import CellIcon from "./Cells/CellIcon";
import CellJSON from "./Cells/CellJSON";
import CellLongText from "./Cells/CellLongText";
import CellNumber from "./Cells/CellNumber";
import CellPeople, { CellPeopleFields } from "./Cells/CellPeople";
import CellPercent from "./Cells/CellPercent";
import CellPhone from "./Cells/CellPhone";
import CellProduct from "./Cells/CellProduct";
import CellProgress from "./Cells/CellProgress";
import CellProject, { CellProjectFields } from "./Cells/CellProject";
import CellRating from "./Cells/CellRating";
import CellSelect from "./Cells/CellSelect";
import CellSpace from "./Cells/CellSpace";
import CellState from "./Cells/CellState";
import CellStatus, { CellStatusFields } from "./Cells/CellStatus";
import CellText from "./Cells/CellText";
import CellTranscript from "./Cells/CellTranscript";
import CellUrl from "./Cells/CellUrl";

export const getCellForType = (type: string) => {
  switch (type) {
    case CellType.PROGRESS:
      return CellProgress;
    case CellType.PEOPLE:
      return CellPeople;
    case CellType.STATUS:
      return CellStatus;
    case CellType.SPACES:
      return CellSpace;
    case CellType.SELECT:
      return CellSelect;
    case CellType.STATE:
      return CellState;
    case CellType.ADDRESS:
      return CellAddress;
    case CellType.PROJECT:
      return CellProject;
    case CellType.CURRENCY:
      return CellCurrency;
    case CellType.URL:
      return CellUrl;
    case CellType.EMAIL:
      return CellEmail;
    case CellType.FILE:
      return CellFile;
    case CellType.BUTTON:
      return CellButton;
    case CellType.BOOLEAN:
      return CellBoolean;
    case CellType.COMPANY:
      return CellCompany;
    case CellType.TRANSCRIPT:
      return CellTranscript;
    case CellType.PHONE:
      return CellPhone;
    case CellType.RATING:
      return CellRating;
    case CellType.DATE:
      return CellDate;
    case CellType.DATETIME:
      return CellDateTime;
    case CellType.NUMBER:
      return CellNumber;
    case CellType.PERCENT:
      return CellPercent;
    case CellType.LONG_TEXT:
      return CellLongText;
    case CellType.BADGE:
      return CellBadge;
    case CellType.AUDIO:
      return CellAudio;
    case CellType.PRODUCT:
      return CellProduct;
    case CellType.GENERIC_CELL:
      return CellGeneric;
    case CellType.COORDINATE:
      return CellCoordinate;
    case CellType.COLOR:
      return CellColor;
    case CellType.JSON:
      return CellJSON;
    case CellType.EXIST:
      return CellExist;
    case CellType.ICON:
      return CellIcon;
    case CellType.BEFORE_AFTER:
      return CellBeforeAfter;
    case CellType.TEXT:
    default:
      return CellText;
  }
};

export const getCellProps = (type: string) => {
  switch (type) {
    case CellType.CURRENCY:
      return {
        prefix: DollarSign,
        type: "text",
        maskOptions: {
          mask: Number,
          scale: 2,
          signed: true,
          thousandsSeparator: ",",
          radix: "."
        }
      };
    case CellType.URL:
      return {
        init: removeHttpFromUrl,
        type: "text",
        pattern: `(https?:\\/\\/(www\.)?)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)`,
        title: "Please enter a valid URL",
        maskOptions: {},
        leadingAddOn: "https://",
        transform: removeHttpFromUrl,
        transformBeforeToSave: formatUrl
      };
    case CellType.EMAIL:
      return {
        type: "email",
        pattern: `^\\w+([\\.\+-]?\\w+)*@\\w+([\.-]?\\w+)*(\\.\\w{2,3})+$`,
        title: "Please enter a valid email",
        maskOptions: {}
      };
    case CellType.PHONE:
      return {
        type: "phone",
        pattern: "\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}",
        maskOptions: {
          mask: "(000) 000-0000"
        },
        init: (value: string) => value?.replaceAll("-", "")
      };
    case CellType.PERCENT:
      return {
        type: "text",
        suffix: PercentIcon,
        maskOptions: {
          mask: Number,
          scale: 2,
          signed: false,
          radix: "."
        }
      };
    case CellType.NUMBER:
      return {
        type: "text",
        maskOptions: {
          mask: Number,
          radix: ".",
          signed: false,
          normalizeZeros: true,
          scale: 6
        }
      };
    default:
      return {
        maskOptions: {}
      };
  }
};

export const classNameError = "border-red-700 outline-red-700";

export type CellGroupValueType = {
  displayName: string;
  id: string | number;
  record?: CellGroupValueType;
  optionData?: CellGroupValueType;
  [property: string]: string | number | CellGroupValueType | undefined;
} & (CellPeopleFields | CellStatusFields | CellProjectFields | CellCompanyFields);

/**
 * Value for cell group will be one of:
 * boolean
 * string
 * number
 * function
 * array of object --> may or may not have record property
 * object
 * undefined/null
 * array of string
 */
export type CellGroupValueInput =
  | string
  | number
  | boolean
  | CellGroupValueType[]
  | CellGroupValueType
  | null
  | undefined
  | (() => void)
  | Array<Record<string, any>>
  | Record<string, any>
  | string[]
  | any;

export const getFeaturedOrFirstImage = (images: Array<{ path: string; is_featured?: boolean }>) => {
  if (!Array.isArray(images) || !images.length) return null;

  const featuredImage = images.find((image) => !!image?.is_featured);
  if (featuredImage) return [featuredImage];
  return [images[0]];
};

// Temporary method to return column without files
export const getLookupColumnForSpecialViewCells = (column: TableColumnType) => {
  if (column.type === CellType.COMPANY) {
    return "name";
  }
  if (column.type === CellType.PEOPLE) {
    return "full_name";
  }
  if (column.type === CellType.PROJECT) {
    return "address_line_1";
  }
  return null;
};

export const transformRecordItemToSelectOptions = ({
  items,
  column,
  matchingRecordType,
  recordTypeColumns,
  isFromColOptions,
  addColumn,
  extendedSchema
}: {
  items: RecordItem[] | RecordItem;
  column: TableColumnType;
  matchingRecordType?: ApiRecordType;
  recordTypeColumns?: TableColumnType[];
  isFromColOptions?: boolean;
  addColumn?: boolean; // This appends the source column to the option,
  extendedSchema?: ExtendedSchema;
}): SelectOptions => {
  let lookupColumn = "";
  let columnLookupColumn: string | string[] = "";

  let options: SelectOptions = [];

  if (column.cellConfig?.filterOptionsView) {
    // Data is coming from a view will have all properties needed in record
    const colOpts = getColumnOptionsLookupPropAndColumnName({ ...column, columnOptionsLookUp: undefined }, true);
    const lookupColumn = getLookupColumnForSpecialViewCells(column) || colOpts?.lookupCols || "";
    const extraProps: { path?: string; projectId?: string } = {};
    items.forEach((row: RecordItem) => {
      if (row.hasOwnProperty("paths")) {
        // Files are aggregated into a paths column in views
        extraProps.path = row.paths?.[0] || "";
      }
      if (row.hasOwnProperty("project_id")) {
        // Project id is a column in views
        extraProps.projectId = row.project_id || "";
      }
      options.push({
        value: row["id"] || "",
        title: `${row?.[lookupColumn] || ""}`,
        record: {
          ...(row || {}),
          ...extraProps,
          value: row?.[lookupColumn] || ""
        },
        optionData: { ...row }
      });
    });

    return options;
  }
  // In the below case id is directly row?.id as it's reading from the base record type table
  const isColOptionRecordType = isFromColOptions && column?.columnOptionsLookUp?.["0"]?.isRecordTypeOptions;
  if (matchingRecordType?.page_id && recordTypeColumns?.length) {
    const hasImageColumnWithBasicColumns =
      matchingRecordType?.image_column?.id &&
      !matchingRecordType?.lookup_column?.id &&
      !!Object.keys(matchingRecordType?.config || {}).length;
    options = items.map((row: RecordItem) => {
      let finalValue: RecordItem = {};
      let finalId = row?.id;
      // The columns come from record type
      recordTypeColumns.forEach((col) => {
        let val = !col?.isLookup && col?.name ? { [col.name]: row[col.name] } : readLookupFields(col, row);
        // Image column should return a single value
        val = col?.type === CellType.FILE && col?.isLookup && Array.isArray(val) ? val[0] : val;
        if (!isEmpty(val)) {
          finalValue = { ...finalValue, ...val, id: row?.id };
        }
        if (hasImageColumnWithBasicColumns) {
          // We need to add all basic columns from the column base level as
          // they have been added to level 0
          const colLabel = col.lookupPath?.["0"]?.lookupColumnLabel || "";
          if (colLabel) {
            Object.keys(matchingRecordType?.config || {}).forEach((key) => {
              finalValue = {
                ...finalValue,
                [key]: row?.[colLabel]?.[key]
              };
            });
          }
        }

        if (matchingRecordType?.image_column?.id) {
          const colLabel = col.lookupPath?.["0"]?.lookupColumnLabel || "";
          if (colLabel.startsWith("image_")) {
            finalId = row?.[colLabel]?.id;
            finalValue = {
              ...finalValue
            };
          }
          if (!isColOptionRecordType) {
            finalValue["id"] = row?.[colLabel]?.id;
          }
        }
      });

      let titlePropName = "";
      if (column.type === CellType.PROJECT) {
        titlePropName = "address_line_1";
      }
      if (column.type === CellType.PEOPLE) {
        titlePropName = "full_name";
      }
      if (column.type === CellType.COMPANY || column.type === CellType.PRODUCT) {
        titlePropName = "name";
      }
      if (!isEmpty(finalValue)) {
        const finalTitle =
          (column.type === CellType.COMPANY || column.type === CellType.PRODUCT) && row.name
            ? row.name
            : finalValue?.[titlePropName] || "";
        return {
          value: finalId || row?.id || "",
          title: finalTitle,
          record: { ...finalValue, value: finalTitle },
          optionData: { ...row },
          column: addColumn ? column : undefined
        };
      }
      return null;
    });

    return options;
  }

  const isPeopleCell = column.type === CellType.PEOPLE;
  if (isPeopleCell) {
    columnLookupColumn = "id";
  }
  if (Object.keys(column?.columnOptionsLookUp || {}).length === 1) {
    columnLookupColumn =
      column?.columnOptionsLookUp?.["0"]?.lookupDisplayColumn ||
      column?.columnOptionsLookUp?.["0"].lookupColumns?.[0] ||
      "";
  }
  if (Object.keys(column?.columnOptionsLookUp || {}).length > 1) {
    const colOpts = getColumnOptionsLookupPropAndColumnName(column);
    columnLookupColumn = colOpts?.finalPropName || "";
    lookupColumn = colOpts?.lookupCols || "";
  }
  if (column.type === CellType.GENERIC_CELL || (column.cellConfig?.genericConfigForColumnOptions && isFromColOptions)) {
    const colOpts = getColumnOptionsLookupPropAndColumnName(column);
    lookupColumn = colOpts?.lookupCols || "";

    options = items
      .map((row: RecordItem) => {
        const rowFinal = getGenericCellValuesFromRecord({
          column,
          recordData: row,
          isInOptions: true
        });

        if (!rowFinal?.[0]) return;

        let propName = "";

        if (columnLookupColumn.length === 1) {
          propName = columnLookupColumn[0];
        }

        const record = rowFinal[0];
        const finalOptsLookup = {
          value: row.id,
          title:
            record.title ||
            record?.[propName as keyof typeof record]?.[lookupColumn] ||
            record?.[lookupColumn as keyof typeof record] ||
            "",
          record: {
            id: row.id,
            ...record,
            value:
              record.title ||
              record?.[propName as keyof typeof record]?.[lookupColumn] ||
              record?.[lookupColumn as keyof typeof record] ||
              ""
          },
          optionData: { ...row },
          column: addColumn ? column : undefined
        };

        return finalOptsLookup;
      })
      ?.filter(Boolean);
    return options;
  } else if (Array.isArray(columnLookupColumn)) {
    // Add check for People Cell
    if ([CellType.PEOPLE, CellType.COMPANY, CellType.PRODUCT].includes(column.type)) {
      options = items
        .map((row: RecordItem) => {
          let finalOptsLookup = null;
          if (column.columnOptionsLookUp) {
            finalOptsLookup = { ...column.columnOptionsLookUp };
            delete finalOptsLookup["0"];
          }
          const isLookupBaseJoinTable = finalOptsLookup?.[0]
            ? !!extendedSchema?.[finalOptsLookup[0]?.lookupTableName].compositePk?.length
            : false;
          const value = finalOptsLookup
            ? readCellFilesLookupFields({
                lookupPath: finalOptsLookup,
                recordData: row,
                isLookupBaseJoinTable
              })
            : null;
          if (!isEmpty(value)) {
            const finalTitleVal = column.type === CellType.PEOPLE ? value?.full_name : value?.name;
            return {
              value: value?.id || "",
              title: finalTitleVal || "",
              record: { ...value, value: finalTitleVal },
              optionData: { ...row },
              column: addColumn ? column : undefined
            };
          }
          return null;
        })
        .filter(Boolean) as SelectOptions;

      if (isEmpty(options)) {
        options = [];
      }
    } else if (column.type === CellType.PROJECT) {
      options = items
        .map((row: RecordItem) => {
          let finalOptsLookup = null;
          if (column.columnOptionsLookUp) {
            finalOptsLookup = { ...column.columnOptionsLookUp };
            delete finalOptsLookup["0"];
          }
          const value = finalOptsLookup
            ? readCellProjectWithFiles({
                lookupPath: finalOptsLookup,
                recordData: row,
                colData: column,
                recordTypesData: matchingRecordType
              })
            : null;
          if (!isEmpty(value)) {
            return {
              value: value?.id || "",
              title: value?.address_line_1 || "",
              record: { ...value, value: value?.address_line_1 || "" },
              optionData: { ...row },
              column: addColumn ? column : undefined
            };
          }
          return null;
        })
        .filter(Boolean) as SelectOptions;

      if (isEmpty(options)) {
        options = [];
      }
    } else {
      // Nested Lookup
      options = items.map((row: RecordItem) => {
        let propName = "";
        let finalObj = row;

        if (columnLookupColumn.length === 1) {
          propName = columnLookupColumn[0];
        } else {
          (columnLookupColumn as string[]).forEach((col) => {
            if (!finalObj) return;
            if (Array.isArray(finalObj)) {
              finalObj = finalObj[0]?.[col];
            } else {
              finalObj = finalObj[col];
            }
          });
        }

        const title = finalObj?.[propName]?.[lookupColumn] || finalObj?.[lookupColumn] || "";
        const value =
          finalObj?.[propName]?.id || finalObj?.[propName]?.[lookupColumn] || finalObj?.[lookupColumn] || "";
        const id = finalObj?.[propName]?.id || finalObj?.id;

        return {
          value: column.type === CellType.SPACES ? finalObj?.id || value : value,
          title: column.type === CellType.SPACES ? finalObj?.nickname || title : title,
          record: {
            ...(finalObj?.[propName] || finalObj || {}),
            id: column.type === CellType.SPACES ? finalObj?.id || id : id,
            nickname: column.type === CellType.SPACES ? row?.nickname : undefined,
            value: finalObj?.[propName]?.[lookupColumn] || finalObj?.[lookupColumn]
          },
          optionData: { ...row },
          column: addColumn ? column : undefined
        };
      });
    }
  } else {
    options = items
      .map((row: RecordItem) => {
        return {
          value: row["id"] || row[columnLookupColumn as string],
          title: `${row[columnLookupColumn as string]}`,
          record: { ...row, value: row[columnLookupColumn as string] },
          optionData: { ...row },
          column: addColumn ? column : undefined
        };
      })
      .filter(Boolean);
  }

  return options;
};

export const sanitizeValue = (value: string) => {
  return value?.replaceAll(/\p{Cc}/gu, "");
};

export const isBAFile = (file: File | BAFile) => "id" in file;

export const CellsWithOnlyVerticalDirection = [CellType.TRANSCRIPT];
