import { Listbox, Transition } from "@headlessui/react";
import Popper from "@mui/material/Popper";
import clsx from "clsx";
import { ChevronRight, FocusIcon, PlusIcon, XIcon } from "lucide-react";
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHover } from "usehooks-ts";

import orderBy from "lodash/orderBy";
import { useRouter } from "next/navigation";
import Button from "components/Button";
import IconButton from "components/IconButton";
import { IconButtonSize } from "components/IconButton/utils";
import useCurrentUser from "hooks/useCurrentUser";
import useOpenRecordInNestedView from "hooks/useOpenRecordInNestedView";
import useTableActionsState from "hooks/useTableActionsState";
import { CellRules, ColumnCellAdditionalValueFields, DefaultValue, Page, TableColumnType } from "types/baTypes";
import { RecordItem, SelectOption, SelectOptions, SelectValue } from "types/common";
import {
  ButtonAppearance,
  COLUMN_OPTIONS_UI,
  CellType,
  KEY_TO_BLOCK_IN_SEARCH_SELECT,
  USER_TYPE
} from "utils/constants";
import { isColumnFileTag, isColumnNestedFileTag } from "utils/dataUtils";
import { isColumnFileType } from "utils/filterUtils";
import { CellTypesAvailableForUrl } from "utils/constants";
import { CellGroupValueType } from "../utils";
import CommandMenuOptions from "./CommandMenuOptions";
import SelectOptionsVirtual from "./SelectOptionsVirtual";
import SelectedCells from "./SelectedCells";

type BadgeValue = {
  value: string;
  color: string;
};

type SelectCellGroupProps = {
  value?: CellGroupValueType[] | string[] | BadgeValue[];
  options?: SelectOptions;
  cell: React.FC<any>;
  showSearch?: boolean;
  showCreate?: boolean;
  isMultiple?: boolean;
  isLoading?: boolean;
  onEdit?: (values: SelectOptions | string | boolean | number | SelectOption) => void;
  type: CellType;
  inForm?: boolean;
  inCard?: boolean;
  inGrid?: boolean;
  placeholder?: string;
  disabled?: boolean;
  rules?: CellRules;
  hasRequiredError?: boolean;
  createPageId?: string;
  isColumnOptionsJoinTable?: boolean;
  isColumnJoinTable?: boolean;
  isJoinTable?: boolean;
  row?: RecordItem;
  isFocused?: boolean;
  createPageDefaultValues?: { [colId: string]: DefaultValue };
  isReadOnly?: boolean;
  sortField?: string;
  column?: TableColumnType;
  isInFilters?: boolean;
  isInSeparatedFilter?: boolean;
  isColumnTextWithOptions?: boolean;
  inList?: boolean;
  inDetailOverview?: boolean;
  classNamePlaceholder?: string;
  className?: string;
  expandSelect?: boolean;
  handleFilterButtonClick?: () => void;
  onClick?: () => void;
  additionalValueFields?: ColumnCellAdditionalValueFields;
  isSeparatedFilterActive?: boolean;
  OptionsCell?: React.FC<any>;
  hasGenericOptions?: boolean;
  isMultiColumnOptions?: boolean; // When options come from multiple columns
  onExit?: () => void;
  sortDescending?: boolean;
  defaultTablePage?: Page;
};

const compareId = (a: SelectOption, b: SelectOption) => a?.record?.id === b?.record?.id;
const compareIdAndColumn = (a: SelectOption, b: SelectOption) =>
  a?.record?.id === b?.record?.id && a?.column?.id === b?.column?.id;
const SelectCellGroup = ({
  options,
  value,
  cell: Cell,
  showCreate,
  showSearch,
  isMultiple,
  onEdit,
  isLoading = false,
  inForm = false,
  placeholder = "Add",
  disabled = false,
  hasRequiredError,
  createPageId,
  isJoinTable,
  isColumnOptionsJoinTable,
  isColumnJoinTable,
  row,
  type,
  isFocused,
  createPageDefaultValues,
  isReadOnly,
  sortField,
  column,
  isInFilters = false,
  isInSeparatedFilter = false,
  inCard = false,
  inGrid = false,
  classNamePlaceholder = "",
  className = "",
  expandSelect = false,
  handleFilterButtonClick,
  onClick,
  additionalValueFields,
  isSeparatedFilterActive = false,
  OptionsCell,
  hasGenericOptions,
  isMultiColumnOptions = false,
  sortDescending = false,
  onExit,
  defaultTablePage
}: SelectCellGroupProps) => {
  const [isInitOpen] = useState(expandSelect);
  const router = useRouter();
  const { updateInlineCreationState } = useTableActionsState();
  const { openRecordInNestedView } = useOpenRecordInNestedView();
  const currentUser = useCurrentUser();
  const isStaffUser = currentUser?.type === USER_TYPE.STAFF;

  const [selected, setSelected] = useState<SelectValue | undefined | object>();
  const [query, setQuery] = useState("");
  const [hasUpdated, setHasUpdated] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [commandMenuOpen, setCommandMenuOpen] = useState(false);
  const [targetElement, setTargetElement] = useState<null | HTMLElement>(null);
  const openRef = useRef(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const optionsRef = useRef<HTMLDivElement>(null);
  const removeBtnRef = useRef<HTMLButtonElement>(null);

  const optionsFiltered = useMemo(() => {
    return orderBy(
      options,
      (option) => (sortField ? option?.record?.[sortField] : (option.title || "").toString().toLowerCase()),
      sortDescending ? "desc" : "asc"
    ).filter((option) => (option.title || "").toString().toLowerCase().includes(query.toLowerCase()));
  }, [options, query, sortField, sortDescending]);

  const searchRef = (node: HTMLInputElement) => {
    if (node !== null) {
      node?.focus();
    }
  };

  const handleRemoveItem = useCallback(
    (deletedItem: SelectOption) => {
      const newSelected = (selected as SelectOptions)?.filter((item) => item.value !== deletedItem.value);
      setSelected(newSelected);
      onEdit?.(newSelected);
    },
    [onEdit, selected]
  );

  const handleSelect = useCallback(
    (items: SelectOptions | SelectOption) => {
      setErrorState(false);
      if (isMultiColumnOptions && !(items as SelectOption).value) {
        setSelected(undefined);
      } else {
        setSelected(items);
      }

      if (isMultiple) {
        setHasUpdated(true);
      } else {
        onEdit?.(items);
      }
    },
    [isMultiple, onEdit, isMultiColumnOptions]
  );

  const handleCommandMenuSelect = (items: SelectOptions | SelectOption) => {
    setErrorState(false);
    setSelected(items);
    if (column?.isMultiple && column?.cellConfig?.columnOptionsUI === COLUMN_OPTIONS_UI.COMMAND_MENU) {
      let allUpdated = Array.isArray(items)
        ? [...(selected as SelectOptions), ...items]
        : [...(selected as SelectOptions), items];
      if (Array.isArray(selected)) {
        if (!Array.isArray(items)) {
          const alreadyExists = selected.find((item) => item?.value === items?.value);
          if (alreadyExists) {
            allUpdated = allUpdated.filter((item) => item?.value !== items?.value);
          }
        }
      }

      onEdit?.(allUpdated);
      return;
    }

    onEdit?.(items);
    setCommandMenuOpen(false);
  };

  const placeholderText = disabled ? "-" : inForm ? placeholder : "Add";
  const isEmpty = (!selected && !isMultiple) || (!(selected as SelectOptions)?.length && isMultiple);

  const handleInlineCreateSuccess = useCallback(
    (record?: RecordItem) => {
      if (!record) return;

      const optionCreated = options?.find((option) => option?.record?.id === record.id);

      if (optionCreated) {
        if (isMultiple) {
          const newSelected = (selected as SelectOptions)?.concat(optionCreated);
          setSelected(newSelected);
          onEdit?.(newSelected);
        } else {
          setSelected(optionCreated);
          onEdit?.(optionCreated);
        }
      } else {
        const newOption = {
          value: record.id,
          title: record.id,
          optionData: record,
          record
        };
        if (isMultiple) {
          const newSelected = (selected as SelectOptions)?.concat(newOption);
          setSelected(newSelected);
          onEdit?.(newSelected);
        } else {
          setSelected(newOption);
          onEdit?.(newOption);
        }
      }
    },
    [isMultiple, onEdit, options, selected]
  );

  const handleCellActionClick = useCallback(() => {
    const record =
      type === CellType.PROJECT
        ? { ...(selected as SelectOption)?.record, id: (selected as SelectOption)?.record?.projectId }
        : (selected as SelectOption)?.record;

    if (record && defaultTablePage?.path) {
      openRecordInNestedView(record, defaultTablePage?.path);
    }
  }, [openRecordInNestedView, selected, type, defaultTablePage?.path]);

  const handleRecordExpandClick = useCallback(() => {
    const record = { ...(selected as SelectOption)?.record };
    const recordId = type === CellType.PROJECT ? record?.projectId : record?.id;
    if (recordId && defaultTablePage?.path) {
      router.push(`/r${defaultTablePage?.path}/${recordId}`);
    }
  }, [selected, type, router, defaultTablePage?.path]);

  useEffect(() => {
    if (options && options?.length > 0) {
      if (!value?.length) {
        setSelected(isMultiple ? [] : null);
        return;
      } else {
        const isFilesTagColumn = column ? isColumnFileTag(column) : false;
        if (isMultiple) {
          const valueIds = value.map((currentValue) => {
            if (type === CellType.BADGE) {
              return (currentValue as RecordItem)?.id || (currentValue as BadgeValue)?.value;
            }
            if (isFilesTagColumn && (currentValue as CellGroupValueType)?.tag_id) {
              return (currentValue as CellGroupValueType)?.tag_id;
            }
            if (isJoinTable && !isColumnOptionsJoinTable && (currentValue as CellGroupValueType)?.optionData?.id) {
              return (currentValue as CellGroupValueType)?.optionData?.id;
            }
            if (isColumnJoinTable && isColumnOptionsJoinTable && (currentValue as CellGroupValueType)?.joinTableId) {
              return (currentValue as CellGroupValueType)?.joinTableId;
            }
            if (isColumnOptionsJoinTable && (currentValue as CellGroupValueType)?.record?.id) {
              // This case happens in Bulk edit
              return (currentValue as CellGroupValueType)?.record?.id;
            }
            const finalVal = currentValue as CellGroupValueType;
            // finalVal can have record and value fields when a selection is made from options
            return finalVal?.record?.id || finalVal?.id || finalVal?.value;
          });

          const optionsSelected = options.filter((option) => {
            let finalId = option?.record?.id;
            const finalColumn = isMultiColumnOptions ? option?.column : column;
            const isFileTagNestedColumn = finalColumn ? isColumnNestedFileTag(finalColumn) : false;
            if ((isFileTagNestedColumn || isFilesTagColumn) && option?.record?.tag_id) {
              finalId = option?.record?.tag_id;
            }
            if (type === CellType.BADGE) {
              finalId = option?.title;
            } else if (isJoinTable && !isColumnOptionsJoinTable && option?.optionData?.id) {
              finalId = option?.optionData?.id;
            }

            return valueIds.includes(finalId) || valueIds.includes(`${finalId}`);
          });

          setSelected(optionsSelected);
        } else {
          const val = typeof value[0] === "string" ? (value[0] as string) : (value[0] as CellGroupValueType);
          let optionSelected;
          // In case the column options come from a join table, we need to use record even if base table is a join table
          if (typeof val === "string" || typeof val === "number") {
            optionSelected = options.find((option) => option?.title === val?.toString());
          } else if (type === CellType.BADGE && column?.isLookup && (val?.value || val?.id)) {
            optionSelected = options.find((option) => {
              const finalColumn = isMultiColumnOptions && option?.column ? option?.column : column;
              const isFileTypeForeignKey = finalColumn ? isColumnFileType(finalColumn) : false;
              return (isInSeparatedFilter || isInFilters) && !isFileTypeForeignKey
                ? `${option.record?.id}` === `${val?.value || val?.id}`
                : option?.title === val?.value || option?.title === val?.id;
            });
          } else if (isColumnJoinTable && isColumnOptionsJoinTable && val?.joinTableId) {
            const finalValueId = val?.joinTableId;

            optionSelected = options.find(
              (option) =>
                String(option?.optionData?.id) === String(finalValueId) ||
                String(option?.optionData?.joinTableId) === String(finalValueId)
            );
          } else if (isJoinTable && !isColumnOptionsJoinTable) {
            // Value can have optionData when a selection is made from options
            // and table is a join table, needed so we don't use record.id
            const finalValueId = val?.optionData?.id || val?.id;

            optionSelected = options.find((option) => String(option?.optionData?.id) === String(finalValueId));
          } else {
            let finalId =
              isColumnJoinTable && isColumnOptionsJoinTable && val?.joinTableId
                ? val?.joinTableId
                : isColumnOptionsJoinTable && val?.record?.id
                  ? val?.record?.id
                  : val?.id || val?.record?.id; // This case happens in Bulk edit

            let isProjectIdCheck = false;
            let isProjectIdInVal = false;
            // This case is for handling pre-selected project in form
            if (type === CellType.PROJECT && inForm && val?.id && !val.isFromOptions) {
              isProjectIdCheck = true; // Record id in this case is place_id
            }
            // projectId is added separately as id is places id in this case
            if (type === CellType.PROJECT && val?.projectId) {
              isProjectIdInVal = true;
              finalId = val.projectId as string;
            }
            if (isFilesTagColumn && val?.tag_id) {
              finalId = val.tag_id as string;
            }

            optionSelected = options.find((option) => {
              if (isProjectIdInVal && option?.record?.projectId) {
                return `${option?.record?.projectId || ""}` === `${finalId}`;
              }
              if (isFilesTagColumn && val?.tag_id && option?.record?.tag_id) {
                return `${option?.record?.tag_id || ""}` === `${finalId}`;
              }
              return isProjectIdCheck && option?.optionData?.id
                ? `${option?.optionData?.id || ""}` === `${finalId}`
                : `${option?.record?.id || ""}` === `${finalId}`;
            });
          }

          if (isMultiColumnOptions) {
            let selectedComboCellValue:
              | {
                  columnId: string;
                  value: RecordItem;
                }
              | undefined;
            column?.cellConfig?.genericConfig?.comboEditColumnIds?.forEach((colId) => {
              const columnValue = ((val as CellGroupValueType)?.[colId] as RecordItem)?.value;
              if (!Array.isArray(columnValue) && columnValue) {
                selectedComboCellValue = {
                  columnId: colId,
                  value: columnValue
                };
                return;
              } else if (columnValue?.length) {
                selectedComboCellValue = {
                  columnId: colId,
                  value: columnValue[0] as RecordItem
                };
                return;
              }
            });
            if (selectedComboCellValue) {
              optionSelected = options.find(
                (option) =>
                  option.column?.id === selectedComboCellValue?.columnId &&
                  (option?.optionData?.id
                    ? `${option?.optionData?.id || ""}` === `${selectedComboCellValue?.value?.id}`
                    : `${option?.record?.id || ""}` === `${selectedComboCellValue?.value?.id}`)
              );
            }
          }

          setSelected(optionSelected);
        }
      }
    }
  }, [
    options,
    isMultiple,
    value,
    isJoinTable,
    isColumnOptionsJoinTable,
    type,
    inForm,
    isInFilters,
    column,
    isColumnJoinTable,
    isInSeparatedFilter,
    isMultiColumnOptions
  ]);

  useEffect(() => {
    if (hasRequiredError) {
      setErrorState(true);
    }
  }, [hasRequiredError]);

  useEffect(() => {
    setQuery("");
  }, [row?.id]);

  useEffect(() => {
    if (isFocused) {
      buttonRef.current?.focus();
    }
  }, [isFocused]);

  useEffect(() => {
    if (isInitOpen && buttonRef?.current && optionsFiltered?.length && !openRef.current) {
      buttonRef.current?.click();
      openRef.current = true;
    }
    if (
      isInitOpen &&
      column?.cellConfig?.columnOptionsUI === COLUMN_OPTIONS_UI.COMMAND_MENU &&
      optionsFiltered?.length &&
      !openRef.current
    ) {
      setCommandMenuOpen(true);
      openRef.current = true;
    }
  }, [targetElement, isInitOpen, buttonRef, optionsFiltered, column]);

  // Save isMultiple when navigation with keyboard
  useEffect(() => {
    if (
      !targetElement &&
      isMultiple &&
      hasUpdated &&
      column?.cellConfig?.columnOptionsUI !== COLUMN_OPTIONS_UI.COMMAND_MENU
    ) {
      onEdit?.(selected as SelectOptions);
      setHasUpdated(false);
    }
  }, [targetElement, selected, onEdit, isMultiple, hasUpdated, column?.cellConfig?.columnOptionsUI]);

  // Save isMultiple when click outside
  useEffect(() => {
    if (column?.cellConfig?.columnOptionsUI) {
      return;
    }
    const handleClickOutside = (event: TouchEvent | MouseEvent) => {
      const isInOptionsOrRemoveBtnOrTargetElementClicked =
        optionsRef.current?.contains(event.target as Node) ||
        removeBtnRef.current?.contains(event.target as Node) ||
        targetElement?.contains(event.target as Node);

      if (!isInOptionsOrRemoveBtnOrTargetElementClicked && targetElement && isMultiple && hasUpdated) {
        onEdit?.(selected as SelectOptions);
        setHasUpdated(false);
      }
      if (!isInOptionsOrRemoveBtnOrTargetElementClicked && targetElement) {
        onExit?.();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [targetElement, selected, onEdit, isMultiple, hasUpdated, onExit, column?.cellConfig?.columnOptionsUI]);
  const btnLabel = column?.header || "";
  const separatedFilterBtnLabel =
    !isMultiple && (selected as SelectOption)?.value
      ? `${btnLabel}: ${(selected as SelectOption)?.record?.value}`
      : isMultiple && Array.isArray(selected) && selected.length
        ? selected.length === 1
          ? `${btnLabel}: ${(selected as SelectOptions)[0]?.record?.value}`
          : `${btnLabel}: (${selected.length})`
        : btnLabel;

  const renderListBoxOptions = () => {
    return (
      <div className="my-1" ref={optionsRef}>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options
            className={clsx(
              "bg-dropdown border-dropdown min-w-[250px] origin-top-right rounded-lg border p-1 shadow-100 focus:outline-none dark:shadow-dark100 sm:min-w-[300px]"
            )}
            static
          >
            {showSearch && (optionsFiltered?.length > 6 || !!query) && (
              <header>
                <input
                  type="text"
                  className="bg-background text-primary mb-2 w-full rounded-lg border-none p-3 text-xs focus:ring-0 focus:blur-0"
                  placeholder="Search.."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.code === "Tab") {
                      buttonRef.current?.click();
                      e.preventDefault();
                      e.stopPropagation();
                      return;
                    }
                    if (!KEY_TO_BLOCK_IN_SEARCH_SELECT.includes(e.code)) {
                      e.stopPropagation();
                    }
                  }}
                  ref={searchRef}
                />
              </header>
            )}

            <SelectOptionsVirtual
              options={optionsFiltered}
              showCreate={showCreate}
              isLoading={isLoading}
              Cell={Cell}
              type={type}
              column={column}
              OptionsCell={OptionsCell}
              hasGenericOptions={hasGenericOptions}
              isMultiColumnOptions={isMultiColumnOptions}
            />

            {showCreate && !!createPageId && (
              <footer className="border-t border-neutral-200 px-3 py-2 dark:border-neutral-dark-200">
                <Listbox.Button as={Fragment}>
                  <Button
                    label="Create New"
                    appearance={ButtonAppearance.SECONDARY}
                    icon={PlusIcon}
                    onClick={() =>
                      updateInlineCreationState({
                        isOpen: true,
                        onSuccess: handleInlineCreateSuccess,
                        createPageId,
                        createPageDefaultValues,
                        valueToPrefill: query,
                        columnToPrefill: column
                      })
                    }
                  />
                </Listbox.Button>
              </footer>
            )}
          </Listbox.Options>
        </Transition>
      </div>
    );
  };

  if (column?.cellConfig?.columnOptionsUI === COLUMN_OPTIONS_UI.COMMAND_MENU && !isInFilters && !isInSeparatedFilter) {
    return (
      <CommandMenuOptions
        options={options}
        isOpen={commandMenuOpen}
        type={type}
        isMultiColumnOptions={isMultiColumnOptions}
        column={column}
        hasGenericOptions={hasGenericOptions}
        handleSelect={handleCommandMenuSelect}
        handleRemoveItem={handleRemoveItem}
        selected={selected}
        additionalValueFields={additionalValueFields}
      />
    );
  }

  return (
    <Listbox
      as="div"
      data-testid="SelectCellGroup"
      className="relative flex w-full overflow-hidden"
      value={selected || []}
      onChange={handleSelect}
      multiple={isMultiple}
      disabled={disabled || isReadOnly}
      by={isMultiColumnOptions ? compareIdAndColumn : compareId}
    >
      {({ open }) => {
        const id = open ? "popper" : undefined;

        return (
          <>
            <Listbox.Button ref={buttonRef} className="w-full text-left focus-visible:rounded-lg">
              <div ref={hoverRef} className="w-full" aria-describedby={id}>
                {isInSeparatedFilter ? (
                  <div ref={setTargetElement} className="relative">
                    <Button
                      label={separatedFilterBtnLabel}
                      appearance={ButtonAppearance.SECONDARY}
                      className="whitespace-nowrap"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleFilterButtonClick?.();
                        setTargetElement(e.currentTarget);
                      }}
                      showCountIndicator={!isEmpty || isSeparatedFilterActive}
                    />
                  </div>
                ) : (
                  <div
                    data-testid="SelectCellGroupButton"
                    className={clsx(
                      "text-primary flex w-full cursor-pointer items-center rounded-lg border border-transparent font-medium",
                      "text-sm",
                      !inCard && "min-h-[56px] px-3 py-[11px]",
                      !open && !inForm && "hover:bg-neutral-200 dark:hover:bg-neutral-dark-200",
                      inForm &&
                        "border-transparent bg-gray-100 transition-colors focus-within:ring-2 focus-within:ring-primary-700 hover:bg-gray-150 focus:bg-gray-150 dark:bg-gray-600/60 dark:focus-within:ring-primary-dark-700 dark:hover:bg-gray-600 dark:focus:bg-gray-600",
                      open &&
                        inForm &&
                        !errorState &&
                        "!bg-transparent ring-2 ring-primary-700 dark:ring-primary-dark-700",
                      inForm &&
                        errorState &&
                        "border border-red-700 ring-1 ring-red-700 dark:border-red-dark-700 dark:ring-red-dark-700",
                      className
                    )}
                    ref={open ? setTargetElement : undefined}
                    onClick={onClick}
                  >
                    <SelectedCells
                      selected={selected}
                      isMultiple={isMultiple}
                      column={column}
                      cell={Cell}
                      handleRemoveItem={handleRemoveItem}
                      isLoading={isLoading}
                      additionalValueFields={additionalValueFields}
                      isMultiColumnOptions={isMultiColumnOptions}
                    />
                    {isEmpty && (
                      <span className={clsx("text-base-disabled dark:text-base-dark-disabled", classNamePlaceholder)}>
                        {open ? placeholder : placeholderText}
                      </span>
                    )}

                    {isStaffUser &&
                      !isMultiple &&
                      isHovered &&
                      !open &&
                      !inForm &&
                      selected &&
                      CellTypesAvailableForUrl.includes(type) &&
                      defaultTablePage && (
                        <div className="absolute right-2 flex gap-x-1">
                          <IconButton
                            data-testid="RecordExpandIcon"
                            icon={FocusIcon}
                            className="bg-background"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRecordExpandClick();
                            }}
                          />
                          <IconButton
                            className="rounded-lg"
                            icon={ChevronRight}
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCellActionClick();
                            }}
                            tabIndex={-1}
                          />
                        </div>
                      )}

                    {!isMultiple && selected ? (
                      <IconButton
                        ref={removeBtnRef}
                        className={clsx("absolute right-0 z-50", open || inGrid ? "" : "!h-0 !w-0 flex-shrink-0")}
                        icon={XIcon}
                        color="transparent"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isMultiColumnOptions) {
                            // if column is generic combo edit
                            handleSelect({
                              column: (selected as SelectOption)?.column
                            } as SelectOption);
                          } else {
                            handleSelect({} as SelectOption);
                          }
                        }}
                        size={IconButtonSize.XS}
                        type="button"
                        tabIndex={-1}
                      />
                    ) : null}
                  </div>
                )}
              </div>
            </Listbox.Button>

            <Popper
              open={isInitOpen || open}
              id={id}
              anchorEl={targetElement}
              disablePortal={false}
              className="z-[150]"
              placement="bottom-start"
            >
              {renderListBoxOptions()}
            </Popper>
          </>
        );
      }}
    </Listbox>
  );
};

export default SelectCellGroup;
