import { useRef } from "react";
import clsx from "clsx";
import { useHover } from "usehooks-ts";
import { XIcon } from "lucide-react";
import noop from "lodash/noop";

import Skeleton from "components/Skeleton";
import CellEmpty from "./CellEmpty";

export type CellColorFields = {
  name?: string;
  value?: string; // hex or rgb color
  hex?: string;
  rgb?: string;
  color?: string;
};

type CellColorProps = CellColorFields & {
  onRemoveClick?: () => void;
  isEditable?: boolean;
  isLoading?: boolean;
  className?: string;
  hideColorName?: boolean;
  isMultiple?: boolean;
  isHeader?: boolean;
  isCentered?: boolean;
  onClick?: () => void;
};

const CellColor = ({
  value,
  hex,
  rgb,
  color,
  name,
  isEditable,
  className,
  isLoading,
  hideColorName = false,
  onRemoveClick,
  isMultiple = false,
  isHeader = false,
  isCentered = false,
  onClick = noop
}: CellColorProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 w-24" />
      </div>
    );
  }

  if (!hex && !rgb && !value) return <CellEmpty className={clsx(className, isCentered && "text-center")} />;

  const finalColor = hex || rgb || value || color;

  if (name && !hideColorName) {
    return (
      <div
        className={clsx(
          "relative inline-flex flex-nowrap items-center gap-x-2 overflow-hidden rounded-full px-2 py-1",
          isMultiple
            ? "border border-neutral-300 bg-neutral-0 dark:border-neutral-dark-300 dark:bg-transparent"
            : "bg-neutral-100 dark:bg-neutral-dark-0"
        )}
        ref={hoverRef}
        onClick={onClick}
      >
        <div className="h-3 w-3 shrink-0 rounded-full" style={{ backgroundColor: finalColor }} />
        <div className="relative flex">
          {/* Ghost text to know the text width to show the remove icon without increase width */}
          <div
            className={clsx(
              "overflow-hidden overflow-ellipsis whitespace-nowrap text-sm !leading-4",
              isEditable && "opacity-0",
              isHeader ? "font-semibold" : "font-medium"
            )}
          >
            {name}
          </div>
          {isEditable && (
            <div className="absolute inset-0 flex overflow-hidden">
              <div
                className={
                  "text-primary overflow-hidden overflow-ellipsis whitespace-nowrap text-sm font-medium !leading-4"
                }
              >
                {name}
              </div>
              <button
                className={clsx("", isHovered ? "block" : "hidden")}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveClick?.();
                }}
              >
                <XIcon className="h-4 w-4" />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "relative inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-full border border-neutral-300 dark:border-neutral-dark-300",
        isCentered && "mx-auto"
      )}
      ref={hoverRef}
      onClick={onClick}
    >
      <div className="h-3 w-3 shrink-0 rounded-full" style={{ backgroundColor: finalColor }} />
      {isEditable && (
        <button
          className={clsx(
            "absolute -right-1 -top-1 rounded-full bg-base-disabled p-0.5",
            isHovered ? "block" : "hidden"
          )}
          onClick={(e) => {
            e.stopPropagation();
            onRemoveClick?.();
          }}
        >
          <XIcon className="h-2 w-2 text-white" />
        </button>
      )}
    </div>
  );
};

export default CellColor;
