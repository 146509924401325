import React, { useRef } from "react";
import clsx from "clsx";
import { useHover } from "usehooks-ts";
import { MessageCircleIcon, Volume1Icon } from "lucide-react";

import Skeleton from "components/Skeleton";
import IconButton from "components/IconButton";
import { formatPhoneNumber } from "utils/format";
import CellPlaceholder from "./CellPlaceholder";
import CellEmpty from "./CellEmpty";

type CellPhoneFields = {
  value: string;
};

type CellPhoneProps = CellPhoneFields & {
  isLoading?: boolean;
  isEditable?: boolean;
  onClick?: () => void;
  className?: string;
  inGrid?: boolean;
  inList?: boolean;
  inCard?: boolean;
  isHeader?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

const CellPhone = ({
  value = "",
  isLoading = false,
  isEditable = false,
  onClick,
  className = "",
  inGrid = false,
  inList = false,
  inCard = false,
  isHeader = false,
  onKeyDown
}: CellPhoneProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 max-w-full" />
      </div>
    );
  }

  if (!value && !isEditable) return <CellEmpty className={className} />;

  if (!value && isEditable) {
    return <CellPlaceholder onClick={onClick} className={className} onKeyDown={onKeyDown} />;
  }

  return (
    <div
      className={clsx(
        "relative flex w-full items-center text-sm",
        inGrid && "pr-12",
        className,
        isHeader && "font-semibold"
      )}
      onClick={onClick}
      ref={hoverRef}
      onKeyDown={onKeyDown}
      tabIndex={isEditable ? 0 : undefined}
    >
      <span className="whitespace-nowrap">{formatPhoneNumber(value)}</span>

      {(inGrid || inList || inCard) && (
        <div
          className={clsx(
            "absolute flex items-center gap-2",
            inCard ? "right-0" : "right-2",
            !isHovered && "invisible"
          )}
        >
          <IconButton
            className="rounded-lg"
            icon={Volume1Icon}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}
            color="primary"
            tag="a"
            target="_blank"
            href={`tel:${value}`}
          />
          <IconButton
            className="rounded-lg"
            icon={MessageCircleIcon}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}
            color="primary"
            tag="a"
            target="_blank"
            href={`sms:${value}`}
          />
        </div>
      )}
    </div>
  );
};

export default CellPhone;
