import clsx from "clsx";
import noop from "lodash/noop";

import Image from "components/Image";
import Skeleton from "components/Skeleton";
import { FALLBACK_IMAGE_SRC, Size } from "utils/constants";
import { formatAddress } from "utils/format";
import CellEmpty from "./CellEmpty";

export type CellProjectFields = {
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip_code: string;
  path: string;
  projectId: string;
};

type CellProjectProps = CellProjectFields & {
  isLoading?: boolean;
  isInOptions?: boolean;
  size?: Size;
  isHovered?: boolean;
  isHeader?: boolean;
  onClick?: () => void;
};

const isNotEmpty = (addressLine1: string, addressLine2: string, city: string, state: string, zipCode: string) =>
  addressLine1 || addressLine2 || city || state || zipCode;

const sizeProps = {
  [Size.SM]: {
    className: "w-12 h-12 shrink-0",
    containerClassName: "items-center grid-cols-[48px_minmax(100px,1fr)]",
    width: 48,
    height: 48
  },
  [Size.MD]: {
    className: "w-12 h-12 shrink-0",
    containerClassName: "items-center grid-cols-[48px_minmax(100px,1fr)]",
    width: 48,
    height: 48
  },
  [Size.LG]: {
    className: "w-[117px] h-[117px] shrink-0",
    containerClassName: "items-start grid-cols-[117px_minmax(100px,1fr)]",
    width: 117,
    height: 117
  }
};

const CellProject = ({
  address_line_1: addressLine1,
  address_line_2: addressLine2,
  city,
  state,
  zip_code: zipCode,
  path: featuredImage,
  isInOptions = false,
  isLoading = false,
  size = Size.SM,
  isHovered,
  isHeader = false,
  onClick = noop
}: CellProjectProps) => {
  const secondRow =
    city && state
      ? `${city}, ${state} ${zipCode || ""}`.trim()
      : formatAddress({
          city,
          state,
          zip_code: zipCode
        });

  if (isLoading) {
    return (
      <div className="flex items-center gap-x-3">
        <Skeleton className="h-12 w-12 rounded" />
        <div className="max-w-[160px] space-y-2">
          <Skeleton className="h-3 w-full" />
          <Skeleton className="h-3 w-[90%]" />
        </div>
      </div>
    );
  }

  if (!isNotEmpty(addressLine1, addressLine2, city, state, zipCode)) {
    return <CellEmpty />;
  }

  return (
    <div
      data-testid="CellProject"
      className={clsx(
        "relative grid w-full gap-x-3 py-0.5 text-sm",
        sizeProps[size]?.containerClassName,
        isHovered && "pr-20"
      )}
      onClick={onClick}
    >
      <Image
        data-testid="CellProjectImage"
        src={featuredImage || FALLBACK_IMAGE_SRC}
        alt={`${addressLine1}, ${city}, ${state} ${zipCode}`}
        width={sizeProps[size]?.width}
        height={sizeProps[size]?.height}
        className={clsx("shrink-0 overflow-hidden rounded", sizeProps[size]?.className)}
      />
      <div className="max-w-full overflow-hidden text-sm font-normal">
        <div
          data-testid="CellProjectAddress"
          className={clsx(
            "text-primary overflow-hidden text-ellipsis whitespace-nowrap antialiased ",
            isInOptions ? "max-w-[190px]" : "",
            isHeader ? "font-semibold" : "font-medium"
          )}
        >
          {addressLine1}
          {addressLine2 && `, ${addressLine2}`}
        </div>
        {secondRow && (
          <div
            className={clsx(
              "text-primary overflow-hidden text-ellipsis whitespace-nowrap antialiased",
              isInOptions ? "max-w-[190px]" : "",
              isHeader ? "font-semibold" : "font-medium"
            )}
          >
            {secondRow}
          </div>
        )}
      </div>
    </div>
  );
};

export default CellProject;
