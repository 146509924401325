import { MapPinIcon } from "lucide-react";

import { MapState } from "context/TableActionsContext";
import IconButton from "components/IconButton";
import Tooltip from "components/Tooltip";
import { AddressComplete } from "types/common";

const DetailMapIcon = ({
  value,
  updateMapState
}: {
  value?: AddressComplete;
  updateMapState: (mapState: Partial<MapState>) => void;
}) => {
  if (!value) {
    return null;
  }
  return (
    <Tooltip title="View on map">
      <IconButton
        icon={MapPinIcon}
        className="bg-background"
        onClick={(e) => {
          e.stopPropagation();

          updateMapState({
            isOpen: true,
            items: [
              {
                id: value?.id,
                longitude: value?.longitude || 0,
                latitude: value?.latitude || 0
              }
            ],
            address: {
              address_line_1: value?.address_line_1,
              address_line_2: value?.address_line_2,
              city: value?.city,
              state: value?.state,
              zip_code: value?.zip_code
            }
          });
        }}
      />
    </Tooltip>
  );
};

export default DetailMapIcon;
