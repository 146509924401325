import { useCallback } from "react";

import { buildSupabasePublicUrl } from "components/Image/utils";
import { processFilesForUpload } from "utils/file";
import useUpdateRecord from "hooks/useUpdateRecord";
import useMultiFilesUpload from "hooks/useMultiFilesUpload";
import VideoThumbnailDisplay from "./VideoThumbnailGen";

type VideoProps = {
  src: string;
  className?: string;
  showPreview?: boolean;
  fileId?: string;
  thumbnailPath?: string;
  thumbnailSizes?: string;
  mimeType?: string;
} & React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>;

const Video = ({
  src,
  className = "",
  showPreview = false,
  fileId,
  thumbnailPath,
  thumbnailSizes,
  mimeType,
  ...rest
}: VideoProps) => {
  const { startUpload } = useMultiFilesUpload();
  const { updateRecordAsync } = useUpdateRecord();

  const thumbnailHandler = useCallback(
    async (snapshot: string) => {
      if (snapshot?.length && fileId && !thumbnailPath) {
        const fetchResp = await fetch(snapshot);
        const fileBlob = await fetchResp.blob();
        const file = new File([fileBlob], "sample.png", { type: fileBlob.type });

        const finalFileData = processFilesForUpload([file], "thumbnails");
        if (finalFileData.length === 0) return;
        const uploadResp = await startUpload(finalFileData, true);
        if (uploadResp.uploadCount) {
          // ##HARDCODED
          await updateRecordAsync({
            tableName: "files",
            input: {
              id: fileId,
              thumbnail_path: finalFileData[0].path
            }
          });
        }
      }
    },
    [fileId, startUpload, updateRecordAsync, thumbnailPath]
  );

  if (!src) return null;

  const isExternal = src.startsWith("http") || src.startsWith("blob");
  const finalSrc = isExternal ? src : buildSupabasePublicUrl(src);

  if (showPreview) {
    return (
      <VideoThumbnailDisplay
        src={finalSrc}
        className={className}
        showPreview={showPreview}
        thumbnailHandler={thumbnailHandler}
        thumbnailPath={thumbnailPath ? buildSupabasePublicUrl(thumbnailPath) : ""}
        thumbnailSizes={thumbnailSizes}
        mimeType={mimeType}
        {...rest}
      />
    );
  }
  return (
    <video
      src={`${finalSrc}${showPreview ? "#t=5,10" : ""}`}
      className={className}
      preload="metadata"
      {...rest}
    ></video>
  );
};

export default Video;
