import useAudioPlayer from "hooks/useAudioPlayer";
import { RecordItem } from "types/common";
import { formatPhoneNumber } from "utils/format";
import CellEmpty from "./CellEmpty";

type CellAudioProps = {
  value: string;
  row: RecordItem;
  className?: string;
};

const CellAudio = ({ value, row, className }: CellAudioProps) => {
  const player = useAudioPlayer({
    title:
      row?.from_phone && row?.to_phone
        ? `${formatPhoneNumber(row?.from_phone)} to ${formatPhoneNumber(row?.to_phone)}`
        : "",
    audio: {
      src: value,
      type: "audio/mp3",
    },
  });

  if (!value) return <CellEmpty className={className} />;

  return (
    <div className={className}>
      <button
        className="flex flex-nowrap items-center gap-x-3 fill-primary-700 text-primary-700 hover:fill-primary-900 hover:text-primary-900 dark:fill-primary-dark-700 dark:text-primary-dark-700"
        onClick={(e) => {
          e.stopPropagation();
          player.toggle();
        }}
      >
        {player?.playing ? (
          <svg width="12" height="14" viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.00008 0.333313C1.73341 0.333313 0.666748 1.39998 0.666748 2.66665V11.3333C0.666748 12.6 1.73341 13.6666 3.00008 13.6666C4.26675 13.6666 5.33341 12.6 5.33341 11.3333V2.66665C5.33341 1.39998 4.26675 0.333313 3.00008 0.333313ZM9.00008 0.333313C7.73341 0.333313 6.66675 1.39998 6.66675 2.66665V11.3333C6.66675 12.6 7.73341 13.6666 9.00008 13.6666C10.2667 13.6666 11.3334 12.6 11.3334 11.3333V2.66665C11.3334 1.39998 10.2667 0.333313 9.00008 0.333313Z" />
          </svg>
        ) : (
          <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3334 4L3.93345 0.333333C3.60011 0.133333 3.20011 0 2.80011 0C1.53345 0 0.533447 1 0.533447 2.26667V9.73333C0.533447 11 1.53345 12 2.80011 12C3.20011 12 3.60011 11.8667 3.93345 11.6667L10.4001 7.93333C10.7334 7.73333 11.0001 7.46667 11.2001 7.13333C11.8001 6.06667 11.4668 4.66667 10.3334 4Z" />
          </svg>
        )}
        <span className="text-sm font-semibold">Listen</span>
      </button>
    </div>
  );
};

export default CellAudio;
