import clsx from "clsx";
import { UserSquareIcon } from "lucide-react";
import Tooltip from "components/Tooltip";

const AdminIcon = ({ className, label }: { className?: string; label?: string }) => {
  return (
    <Tooltip title={label || "Only visible to admins"}>
      <div className={clsx("inline-flex h-[18px] w-[18px] shrink-0 items-center justify-center", className)}>
        <UserSquareIcon className="h-[18px] w-[18px]" />
      </div>
    </Tooltip>
  );
};

export default AdminIcon;
