"use client";

import clsx from "clsx";
import sortBy from "lodash/sortBy";
import { ArrowLeftIcon, ChevronDown, ChevronUp, FocusIcon, LockIcon, XIcon } from "lucide-react";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useMediaQuery } from "usehooks-ts";
import { v4 } from "uuid";

import useRecordEdit from "hooks/useRecordEdit";
import useSearchQueryParams from "hooks/useSearchQueryParams";
import useTableActionsState from "hooks/useTableActionsState";

import ActionsHandler from "components/ActionsHandler";
import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import IconButton from "components/IconButton";
import Image from "components/Image";
import { buildSupabasePublicUrl } from "components/Image/utils";
import KeyboardShortcut from "components/KeyboardShortcut";
import Skeleton from "components/Skeleton";
import TitleEdit from "components/TitleEdit";

import { formatActions } from "utils/actions";
import { ButtonAppearance, ViewOption } from "utils/constants";
import { getPageViewRoute } from "utils/pageUtils";

import { NestedViewState } from "context/NestedViewContext";
import { ApiRecordType } from "types/apiTypes";
import { Page, PageAction, TableColumnType, TableViewConfig, ViewAction } from "types/baTypes";
import { AddressComplete, FeaturedImageDetails, RecordItem } from "types/common";
import { ExtendedSchema } from "utils/schema";
import DetailMapIcon from "./DetailMapIcon";
import DetailHeaderMultiTitle from "./DetailHeaderMultiTitle";

export type DetailHeaderAdditionalMultiTitleConfig = {
  config?: TableViewConfig; // detailView Config
  columns: TableColumnType[];
  recordData: RecordItem;
  extendedSchema?: ExtendedSchema;
  recordTypesData?: ApiRecordType[];
};

type DetailTitleControlsHeaderProps = {
  onClose?: () => void;
  hideNavigation?: boolean;
  isExpanded?: boolean;
  isPrevDisabled?: boolean;
  isNextDisabled?: boolean;
  onPrev?: () => void;
  onNext?: () => void;
  nestedViewState?: NestedViewState;
  onExpandClick?: (isExpanded: boolean) => void;
  title?: string;
  additionalConfigForMultiTitle?: DetailHeaderAdditionalMultiTitleConfig;
  pageTitle?: string;
  inNestedView?: boolean;
  actions?: PageAction[];
  viewActions?: ViewAction[];
  recordData?: RecordItem;
  tableName?: string;
  featuredImage?: FeaturedImageDetails | null;
  showRecordNavigationControl?: boolean;
  hideBreadcrumb?: boolean;
  isLoading?: boolean;
  hideExpandButton?: boolean;
  hideControls?: boolean;
  inSidebar?: boolean;
  tablePath?: string;
  className?: string;
  classNameHeader?: string;
  classNameTitle?: string;
  showHeroHeader?: boolean;
  titleCol?: TableColumnType;
  disablePopperPortal?: boolean;
  allowNavigation?: boolean;
  address?: AddressComplete;
  isRecordLocked?: boolean;
  disableEscapeEvent?: boolean;
  formattedRecord?: RecordItem;
  pageData?: Page;
  recordViewSlug?: string;
  isHandleViewCloseInternal?: boolean; // When true, the view close is handled internally by the component,
  addToSearchContext?: boolean;
};

const DetailTitleControlsHeader = ({
  hideNavigation = false,
  hideBreadcrumb = false,
  onClose,
  isExpanded,
  nestedViewState,
  onExpandClick,
  title,
  pageTitle,
  inNestedView = false,
  actions = [],
  viewActions = [],
  recordData,
  tableName,
  tablePath,
  featuredImage,
  isLoading = false,
  hideExpandButton = false,
  hideControls = false,
  inSidebar = false,
  className,
  classNameHeader,
  classNameTitle,
  showHeroHeader = false,
  titleCol,
  disablePopperPortal = false,
  isRecordLocked = false,
  address,
  formattedRecord,
  pageData,
  recordViewSlug,
  isHandleViewCloseInternal,
  addToSearchContext,
  isPrevDisabled,
  isNextDisabled,
  onPrev,
  onNext,
  showRecordNavigationControl,
  additionalConfigForMultiTitle
}: DetailTitleControlsHeaderProps) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const router = useRouter();

  const { updateMapState, detailViewRecordTriggeredByPath, updateDetailViewRecordTriggeredByPath } =
    useTableActionsState();
  const { resetActiveSearchContexts } = useSearchQueryParams();
  const contentContainerRef = useRef<HTMLDivElement>(null);

  const finalTitleCol = useMemo(() => {
    if (!titleCol) return;

    return {
      ...titleCol,
      views: titleCol.views?.[ViewOption.DETAIL_MAIN]?.id
        ? titleCol.views
        : {
            ...titleCol.views,
            [ViewOption.DETAIL_MAIN]: {
              id: "detailview"
            }
          }
    };
  }, [titleCol]);

  const handleViewCloseBypass = useCallback(() => {
    if (detailViewRecordTriggeredByPath) {
      const pathToNavigate = detailViewRecordTriggeredByPath;
      updateDetailViewRecordTriggeredByPath(undefined);
      router.push(pathToNavigate);
      return;
    }

    const viewToBack = getPageViewRoute(pageData, isMobile);
    router.push(`/table/${recordViewSlug}/${viewToBack.replace("_", "")}`);
  }, [
    router,
    recordViewSlug,
    pageData,
    detailViewRecordTriggeredByPath,
    updateDetailViewRecordTriggeredByPath,
    isMobile
  ]);

  const { onRecordEdit } = useRecordEdit({
    tableName: tableName || "",
    view: ViewOption.DETAIL_MAIN,
    pageColumns: finalTitleCol ? [finalTitleCol] : [],
    recordData
  });

  const handleCloseOrBack = useCallback(() => {
    if (isHandleViewCloseInternal) {
      handleViewCloseBypass();
      return;
    }
    if (inNestedView) {
      onClose?.();
    } else if (nestedViewState?.pageData) {
      router.back();
    } else {
      onClose?.();
    }
  }, [inNestedView, onClose, nestedViewState, router, isHandleViewCloseInternal, handleViewCloseBypass]);

  const sortedActions = formatActions(sortBy(actions, "sort_order"));

  const finalActions = sortedActions || viewActions;

  const handleEditTitle = useCallback(
    (newTitle: string) => {
      if (titleCol?.id) {
        onRecordEdit?.({
          values: newTitle,
          keyInitial: titleCol?.name,
          id: titleCol?.id,
          recordDataInRecord: recordData
        });
      }
    },
    [titleCol, recordData, onRecordEdit]
  );

  // This only adds the base record context to the search context
  useEffect(() => {
    if (!addToSearchContext || !tableName) return;
    resetActiveSearchContexts({
      id: v4(),
      title: title || "",
      tableName,
      recordId: recordData?.id
    });
  }, [addToSearchContext, resetActiveSearchContexts, tableName, title, recordData?.id]);

  if (isLoading) {
    return (
      <div className="flex flex-col gap-y-2 px-4 py-8 lg:px-8">
        <div className="flex justify-between">
          <div className="flex gap-x-2">
            <Skeleton className="!w-5" />
            <Skeleton className="!w-5" />
            <Skeleton className="!w-5" />
          </div>
          <Skeleton />
        </div>
        <Skeleton className="!w-1/2" />
      </div>
    );
  }

  if (showHeroHeader) {
    const isExternal = featuredImage?.value?.startsWith("http") || featuredImage?.value?.startsWith("blob");
    const coverSrc = isExternal
      ? featuredImage?.value
      : featuredImage?.value
        ? buildSupabasePublicUrl(featuredImage?.value)
        : "";

    return (
      <div
        className={clsx(
          "relative flex h-[220px] w-full flex-col",
          coverSrc ? "bg-cover bg-no-repeat" : "bg-base-primary",
          inNestedView ? "justify-end" : "justify-between"
        )}
        style={
          coverSrc
            ? {
                backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(87.84deg, rgba(0, 0, 0, 0.4) 0.4%, rgba(0, 0, 0, 0) 100%), url(${coverSrc})`
              }
            : {}
        }
      >
        {!inNestedView && (
          <div className="flex pl-4 pt-4">
            {/* Fake focus button */}
            <button className="!h-0 !w-0 opacity-0"></button>
            <KeyboardShortcut action="Close" shortcuts={["Esc"]}>
              <IconButton
                icon={inNestedView ? XIcon : ArrowLeftIcon}
                className={clsx("mr-3")}
                onClick={handleCloseOrBack}
              />
            </KeyboardShortcut>
          </div>
        )}
        <div className="p-8">
          <span className="text-xs font-semibold text-white">{pageTitle}</span>

          {!!additionalConfigForMultiTitle ? (
            <>
              <DetailHeaderMultiTitle
                {...additionalConfigForMultiTitle}
                type="title"
                textClassName="text-3xl font-semibold text-white lg:text-4xl"
              />
              <DetailHeaderMultiTitle {...additionalConfigForMultiTitle} type="subtitle" />
            </>
          ) : (
            <h1 className="max-w-[530px] text-3xl font-semibold text-white lg:text-4xl">{title}</h1>
          )}
          {!coverSrc && (
            <Image
              width={194}
              height={220}
              src="/common/buildappeal-logo-letter.png"
              className="absolute right-4 top-0 lg:right-20"
              alt="BuildAppeal Logo"
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "flex flex-col gap-y-3",
        isExpanded && "border-separator border-b",
        !(hideControls && !title && contentContainerRef.current?.clientHeight === 0) && "py-5",
        className
      )}
    >
      {!hideControls && (
        <header
          className={clsx(
            "flex items-center justify-between",
            "border-separator border-b pb-2 lg:border-none lg:pb-0",
            isExpanded ? "px-5 lg:px-6" : "px-4 lg:px-8",
            classNameHeader
          )}
        >
          <div className="flex">
            {/* Fake focus button */}
            <button className="!h-0 !w-0 opacity-0"></button>
            <KeyboardShortcut action="Close" shortcuts={["Esc"]}>
              <IconButton
                icon={inNestedView ? XIcon : ArrowLeftIcon}
                className={clsx("mr-3")}
                onClick={handleCloseOrBack}
              />
            </KeyboardShortcut>
            {showRecordNavigationControl && (
              <>
                <KeyboardShortcut action="Move up" shortcuts={["K"]} disabled={isPrevDisabled}>
                  <IconButton
                    icon={ChevronUp}
                    className="mr-1"
                    disabled={isPrevDisabled}
                    onClick={onPrev}
                    title={`Previous`}
                  />
                </KeyboardShortcut>
                <KeyboardShortcut action="Move down" shortcuts={["J"]} disabled={isNextDisabled}>
                  <IconButton
                    icon={ChevronDown}
                    className="mr-3"
                    disabled={isNextDisabled}
                    onClick={onNext}
                    title={`Next`}
                  />
                </KeyboardShortcut>
              </>
            )}
          </div>
          <div className="flex gap-x-2">
            {!isExpanded && !hideNavigation && !hideExpandButton && (
              <KeyboardShortcut action="Expand" shortcuts={["O"]}>
                <Button
                  label="Expand"
                  icon={FocusIcon}
                  appearance={ButtonAppearance.SECONDARY}
                  onClick={() => onExpandClick?.(!isExpanded)}
                />
              </KeyboardShortcut>
            )}
            {!!finalActions?.length && recordData?.id && inSidebar ? (
              <ActionsHandler
                tableName={tableName || ""}
                record={recordData}
                actions={finalActions}
                tablePath={tablePath || ""}
                isLocked={!!recordData?.is_locked}
                disablePopperPortal={disablePopperPortal}
                formattedRecord={formattedRecord}
              />
            ) : null}
          </div>
        </header>
      )}

      <div
        ref={contentContainerRef}
        className={clsx(
          "flex items-center justify-between gap-x-2",
          isExpanded ? "px-5 lg:px-6" : "px-4 lg:px-8",
          classNameTitle
        )}
      >
        <div className="flex w-full flex-col gap-y-2">
          {!hideBreadcrumb && (
            <Breadcrumb
              items={nestedViewState?.navItems ?? []}
              className="flex w-[calc(100vw-48px)] scroll-p-4 overflow-x-auto text-2xs lg:hidden"
            />
          )}
          <div className="flex w-full justify-between gap-x-2">
            <div className="flex w-full items-center gap-x-3 lg:gap-x-5">
              {featuredImage?.value && featuredImage?.type === "Image" && (
                <div className="aspect-[1/1] w-[75px] shrink-0 rounded-lg">
                  <div className="relative h-full w-full overflow-hidden rounded-lg">
                    <Image
                      src={featuredImage.value}
                      alt={title as string}
                      fill
                      priority
                      className="rounded-lg"
                      sizes="100vw"
                    />
                  </div>
                </div>
              )}

              <div className="space-y-2">
                {!hideBreadcrumb && (
                  <Breadcrumb
                    items={nestedViewState?.navItems ?? []}
                    className="hidden overflow-hidden text-2xs lg:inline-flex"
                  />
                )}
                <div className="flex items-center gap-4">
                  {!titleCol && additionalConfigForMultiTitle ? (
                    <DetailHeaderMultiTitle
                      {...additionalConfigForMultiTitle}
                      type="title"
                      textClassName="text-primary line-clamp-2 break-all text-xl"
                    />
                  ) : (
                    <TitleEdit
                      className="text-primary line-clamp-2 break-all text-xl"
                      title={title || ""}
                      editMode={
                        !!tableName &&
                        !!recordData?.id &&
                        !!titleCol &&
                        titleCol.isEditable &&
                        !titleCol?.isLookup &&
                        !titleCol?.isFormula &&
                        !titleCol?.isRollup
                      }
                      onChange={handleEditTitle}
                    />
                  )}
                  {!!(isRecordLocked || recordData?.is_locked) && (
                    <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-[#f5bc1d]">
                      <LockIcon className="inline-block h-4 w-4 text-white" />
                    </div>
                  )}
                </div>
                {!!additionalConfigForMultiTitle && (
                  <DetailHeaderMultiTitle {...additionalConfigForMultiTitle} type="subtitle" />
                )}
              </div>
              <div className="flex items-center gap-2 self-start">
                <DetailMapIcon value={address} updateMapState={updateMapState} />
                {!!finalActions?.length && recordData?.id && !inSidebar ? (
                  <ActionsHandler
                    tableName={tableName || ""}
                    tablePath={tablePath || ""}
                    record={recordData}
                    formattedRecord={formattedRecord}
                    actions={finalActions}
                    isInDetailHeader
                    isInDetailOverviewHeader={!inNestedView}
                    isLocked={!!recordData?.is_locked}
                    disablePopperPortal={disablePopperPortal}
                    showExposedActions
                    hideDropdownOnShowExposedActions
                  />
                ) : null}
              </div>
            </div>
            {!!finalActions?.length && recordData?.id && !inSidebar ? (
              <ActionsHandler
                tableName={tableName || ""}
                tablePath={tablePath || ""}
                record={recordData}
                formattedRecord={formattedRecord}
                actions={finalActions}
                isInDetailHeader
                isInDetailOverviewHeader={!inNestedView}
                isLocked={!!recordData?.is_locked}
                disablePopperPortal={disablePopperPortal}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailTitleControlsHeader;
