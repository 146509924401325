import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import dynamic from "next/dynamic";

import isEmpty from "lodash/isEmpty";
import { InfoIcon, AlertCircle } from "lucide-react";
import Skeleton from "components/Skeleton";
import ActionsHandler from "components/ActionsHandler";
import { getColumnOptionsLookupPropAndColumnName } from "lib/utils";
import {
  BAFile,
  CellRules,
  ColumnCellAdditionalValueFields,
  Page,
  TableColumnType,
  TableViewType,
  ViewAction
} from "types/baTypes";
import { AddressValue, SelectOption, SelectOptions, RecordItem } from "types/common";
import { isColumnTextWithOptions } from "utils/columnUtils";
import {
  CellGroupDirection,
  CellType,
  GENERIC_CELL_LAYOUTS,
  SelectCellTypes,
  Size,
  TEXT_TYPE_CELLS
} from "utils/constants";
import Tooltip from "components/Tooltip";
import { NestedViewState } from "context/NestedViewContext";
import CellDisplayHandler from "./CellDisplayHandler";
import {
  CellGroupValueInput,
  CellGroupValueType,
  CellsWithOnlyVerticalDirection,
  getFeaturedOrFirstImage
} from "./utils";
import CellIcon from "./Cells/CellIcon";
import FormulaIcon from "./FormulaIcon";
import AdminIcon from "./AdminIcon";

const CellEditHandler = dynamic(() => import("./CellEditHandler"), { ssr: false });

// TODO: Update any types to be more specific
export type CellGroupProps = {
  label?: string;
  type?: CellType;
  isMultiple?: boolean;
  value?: CellGroupValueInput;
  options?: SelectOptions;
  direction?: CellGroupDirection;
  className?: string;
  classNameContainer?: string;
  isLoading?: boolean;
  allowEdit?: boolean;
  isEditable?: boolean;
  onEdit?: (
    values: SelectOptions | string | boolean | number | Array<File | BAFile> | SelectOption | AddressValue,
    scopeId?: string,
    col?: TableColumnType,
    additionalValues?: { textContent?: string }
  ) => void;
  scopeId?: string;
  inForm?: boolean;
  column: TableColumnType;
  placeholder?: string;
  disabled?: boolean;
  showSearch?: boolean;
  rules?: CellRules;
  hasRequiredError?: boolean;
  inList?: boolean;
  inGrid?: boolean;
  inCard?: boolean;
  inDetailOverview?: boolean;
  ignoreValidation?: boolean;
  autoFocus?: boolean;
  size?: Size;
  onEmailClick?: (row?: RecordItem) => void;
  row?: RecordItem;
  isJoinTable?: boolean;
  isInFilters?: boolean;
  isInSeparatedFilter?: boolean;
  columnTableName?: string;
  columnTablePath?: string;
  tableFiltersOption?: TableViewType;
  isFocused?: boolean;
  isReadOnly?: boolean;
  onCellDetailActionClick?: (record: RecordItem, tablePath: string, additionalProps?: Partial<NestedViewState>) => void;
  displayWithIcons?: boolean;
  count?: number;
  actionProps?: {
    actions: ViewAction[];
    record: RecordItem;
    tableName: string;
    tablePath: string;
    formattedRecord?: RecordItem;
    parentRecordSlug?: string;
  };
  allColumns?: TableColumnType[];
  onClickCell?: (e: any) => void;
  getColumnOptions?: (colId: string, options: SelectOptions) => void; // This method passes up all selection options up to Filters component as they are needed for special filters
  cellTypeIcon?: string;
  columnWidth?: number;
  inAddMany?: boolean;
  setEditMode?: boolean; // Used to bypass the isEditing setting
  expandSelect?: boolean;
  filterForTableSlug?: string; // When used in nested view exposed filter we cannot use currentTable prop from context
  useOnlyType?: boolean;
  loadOnClick?: boolean; // Prevent select options fetch till filter is clicked
  actions?: ViewAction[];
  isHeader?: boolean;
  autoHeight?: boolean;
  hideEmpty?: boolean; // Hide empty cells
  hideLinkIcon?: boolean;
  additionalValueFields?: ColumnCellAdditionalValueFields;
  isSeparatedFilterActive?: boolean;
  gridInlineEditable?: boolean;
  overrideColumnType?: boolean; // Use type prop instead of column.type
  isInTable?: boolean; // If cell is being loaded in /table view
  onEditDisabled?: (row: RecordItem, column: TableColumnType) => void;
  onLexicalInitState?: () => void;
  defaultTablePage?: Page;
  containerStyles?: React.CSSProperties;
};

const containerClassName = {
  [CellGroupDirection.HORIZONTAL]: "grid grid-cols-[24%_1fr] gap-2 items-center",
  [CellGroupDirection.VERTICAL]: "flex flex-col items-start min-h-[60px] justify-center"
};

const labelClassName = (direction: CellGroupDirection, inForm?: boolean) =>
  direction === CellGroupDirection.HORIZONTAL
    ? "flex nowrap items-center text-sm my-2 text-secondary font-medium"
    : clsx(
        inForm
          ? "text-sm flex items-center mb-2 font-semibold text-secondary"
          : "text-2xs text-secondary align-middle text-3xs font-medium mb-0.5"
      );

const CellGroup = ({
  label,
  type = CellType.TEXT,
  isMultiple = false,
  value,
  options,
  direction = CellGroupDirection.HORIZONTAL,
  className = "",
  classNameContainer = "",
  isLoading = false,
  isEditable = false, // tells if cellgroup can be edited or not
  onEdit,
  scopeId,
  inForm = false,
  inGrid = false,
  inCard = false,
  gridInlineEditable = false,
  column,
  placeholder,
  disabled = false,
  showSearch = true,
  rules,
  hasRequiredError = false,
  inList = false,
  ignoreValidation = false,
  autoFocus = false,
  size,
  inDetailOverview = false,
  onEmailClick,
  onCellDetailActionClick,
  row,
  isJoinTable,
  isInFilters = false,
  isInSeparatedFilter = false,
  columnTableName,
  columnTablePath,
  tableFiltersOption,
  isFocused = false,
  isReadOnly = false,
  displayWithIcons = false,
  count,
  actionProps,
  allColumns,
  onClickCell,
  getColumnOptions,
  cellTypeIcon,
  columnWidth,
  inAddMany = false,
  setEditMode = false,
  expandSelect = true,
  filterForTableSlug,
  useOnlyType = false,
  loadOnClick = false,
  isHeader = false,
  actions = [],
  autoHeight = false,
  hideEmpty = false,
  hideLinkIcon = false,
  additionalValueFields,
  isSeparatedFilterActive,
  overrideColumnType = false,
  isInTable = false,
  onEditDisabled,
  onLexicalInitState,
  defaultTablePage,
  containerStyles
}: CellGroupProps) => {
  const finalDirection = CellsWithOnlyVerticalDirection.includes(type) ? CellGroupDirection.VERTICAL : direction;
  const [isEditing, setIsEditing] = useState(setEditMode || false);

  const onChange = useCallback(
    (
      values: SelectOptions | string | number | boolean | Array<File | BAFile> | SelectOption | AddressValue,
      additionalValues?: { textContent?: string }
    ) => {
      let finalCol = column;
      // For Generic cell the final edit cell is a different column
      if (column.type === CellType.GENERIC_CELL && column?.cellConfig?.genericConfig?.editColumnId) {
        const editCol = allColumns?.find((col) => col.id === column?.cellConfig?.genericConfig?.editColumnId);
        if (editCol) {
          finalCol = editCol;
        }
      }

      onEdit?.(values, scopeId, finalCol, additionalValues);
      if (
        column.type === CellType.GENERIC_CELL &&
        (column?.cellConfig?.genericConfig?.editColumnId ||
          column?.cellConfig?.genericConfig?.comboEditColumnIds?.length)
      ) {
        setIsEditing(false);
      }
    },
    [onEdit, scopeId, column, allColumns]
  );

  const isCellButtonAction = type === CellType.BUTTON && !column.cellConfig?.isLink;

  const showEditHandlerOnly =
    (SelectCellTypes.includes(type) ||
      type === CellType.BOOLEAN ||
      type === CellType.FILE ||
      type === CellType.RATING ||
      type === CellType.DATE ||
      type === CellType.DATETIME ||
      type === CellType.ADDRESS ||
      type === CellType.GENERIC_CELL ||
      column.isSelect) &&
    isEditable;
  const isEditMode = (inForm || isEditing) && isEditable;
  const isGenericEditableCell =
    type === CellType.GENERIC_CELL &&
    ((column.cellConfig?.genericConfig?.layout !== GENERIC_CELL_LAYOUTS.COMBINATION_CELL &&
      !!column?.cellConfig?.genericConfig?.editColumnId) ||
      (column.cellConfig?.genericConfig?.layout === GENERIC_CELL_LAYOUTS.COMBINATION_CELL &&
        !!column?.cellConfig?.genericConfig?.comboEditColumnIds?.length));

  const handleEnableEditing = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleExtraActions = useCallback(() => {
    if (type === CellType.EMAIL) {
      onEmailClick?.(row);
    }
  }, [onEmailClick, type, row]);

  const handleDisableEditing = useCallback(() => {
    setIsEditing(false);
    if (row && column) {
      onEditDisabled?.(row, column);
    }
  }, [row, column, onEditDisabled]);

  // final fields value to be passed to cell Handlers
  const finalFieldValue: CellGroupValueInput = useMemo(() => {
    let finalVal;

    if (type === CellType.JSON) {
      return value;
    }

    if (type === CellType.FILE && typeof value === "string") {
      return value;
    }
    if (
      type === CellType.FILE &&
      Array.isArray(value) &&
      !isEditMode &&
      (isMultiple || column.cellConfig?.renderGalleryView)
    ) {
      return value;
    }
    if (type === CellType.FILE && Array.isArray(value) && !isEditMode && !isMultiple) {
      return getFeaturedOrFirstImage(value);
    }

    if (type === CellType.CURRENCY && column?.cellConfig?.isAggregate) {
      return value;
    }
    // if it is multiple or selectcell type or Image type always return array of value
    if (isMultiple || (SelectCellTypes.includes(type) && !isColumnTextWithOptions(column)) || type === CellType.FILE) {
      if (!value) {
        finalVal = [];
      } else if (!Array.isArray(value)) {
        const val = value as CellGroupValueType;
        if (isEmpty(val)) {
          finalVal = [];
        } else {
          finalVal = [val?.record && !isJoinTable ? { ...val.record, isFromOptions: true } : val]; // Join table should return full value to include optionData
        }
      } else {
        // isFromOptions is to indicate value comes from options selection and not from DB value
        // the id matched is different for both
        finalVal = value.map((val) => (val?.record && !isJoinTable ? { ...val.record, isFromOptions: true } : val)); // Join table should return full value to include optionData
      }
    } else {
      if (Array.isArray(value)) {
        const val = value[0] as CellGroupValueType;
        finalVal = val?.record ? { ...val.record, isFromOptions: true } : val;
      } else {
        finalVal = value;
      }
    }

    if (TEXT_TYPE_CELLS.includes(type) && column.isLookup) {
      const finalProperty: null | { lookupCols: string } =
        type === CellType.SELECT || TEXT_TYPE_CELLS.includes(type)
          ? getColumnOptionsLookupPropAndColumnName(column, false, true)
          : null;

      if (column.isMultiple && CellType.BADGE && Array.isArray(value) && finalProperty?.lookupCols) {
        finalVal = value.map((val) => ({
          value: val?.record?.[finalProperty.lookupCols] || val?.[finalProperty.lookupCols],
          color: val?.record?.color || val?.color
        }));
      } else {
        const finalValue = Array.isArray(value) ? value[0] : value;
        const finalPropertyValue = finalProperty?.lookupCols && finalValue ? finalValue[finalProperty.lookupCols] : "";
        const finalPropertyValueFromOptions =
          finalProperty?.lookupCols && finalValue ? finalValue.record?.[finalProperty?.lookupCols] : "";

        const finalValueText = finalPropertyValue || finalPropertyValueFromOptions;

        if (type === CellType.BADGE && finalValueText) {
          finalVal = {
            value: finalValueText,
            color: finalValue?.color
          };
        } else if (type === CellType.BOOLEAN && typeof value === "boolean") {
          finalVal = value;
        } else if (finalValueText) {
          finalVal = finalValueText;
        } else {
          finalVal = type === CellType.BOOLEAN ? finalValueText : null;
        }

        // When the column is select we need to return an array
        if (finalValueText && column.isSelect) {
          finalVal = [finalVal];
        }
      }
    }

    if (TEXT_TYPE_CELLS.includes(type) && column.isFormula) {
      finalVal = value;
    }

    if (TEXT_TYPE_CELLS.includes(type) && isInFilters) {
      if (column.isSelect) {
        finalVal = Array.isArray(value) ? value : [value];
      } else {
        finalVal = value;
      }
    }

    if ((SelectCellTypes.includes(type) || column.isSelect) && isColumnTextWithOptions(column)) {
      if (typeof value === "string" || typeof value === "number") {
        finalVal = [value];
      } else {
        finalVal = value?.title ? [value.title] : value;
      }
    }

    if (type === CellType.GENERIC_CELL || type === CellType.COORDINATE) {
      if (value && column.isSelect && column.isEditable && !Array.isArray(value)) {
        finalVal = [value];
      } else {
        finalVal = value;
      }
    }
    if (type === CellType.EXIST) {
      if (column.name && !column.isLookup && value) {
        return [value];
      }
      return value;
    }

    return finalVal;
  }, [value, isMultiple, type, column, isEditMode, isJoinTable, isInFilters]);

  useEffect(() => {
    if (!isEditable) {
      return;
    }
    if (isFocused) {
      handleEnableEditing();
    }
  }, [isFocused, handleEnableEditing, isEditable]);

  const finalOptions = useMemo(() => {
    if (options) {
      return options;
    }
    if (
      column?.columnOptionsLookUp?.["0"]?.isCustomSelectOptions &&
      column?.columnOptionsLookUp?.["0"]?.customSelectOptions?.length
    ) {
      return column?.columnOptionsLookUp?.["0"]?.customSelectOptions.map((option: string, index) => ({
        title: option,
        value: option,
        record: { id: index }
      }));
    }
    return undefined;
  }, [options, column]);

  // For Generic cell the final edit cell is a different column
  const finalEditCellColumn =
    column?.type !== CellType.GENERIC_CELL || !column?.cellConfig?.genericConfig?.editColumnId
      ? column
      : allColumns?.find((col) => col.id === column.cellConfig?.genericConfig?.editColumnId);

  const showLabel = displayWithIcons && inCard ? false : label && !isLoading;

  // Only sent across for Admin users currently
  if (value?.error?.message && column.type !== CellType.JSON) {
    return (
      <div className="align-center flex w-full justify-center p-3">
        <Tooltip title={`Error displaying cell contents - ${value.error.message}`}>
          <AlertCircle className="text-red-800" />
        </Tooltip>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        displayWithIcons && inCard
          ? "my-2 flex min-h-[26px] items-center gap-x-3 gap-y-3"
          : !!label
            ? containerClassName[finalDirection]
            : "my-2 min-h-[26px]",
        isInSeparatedFilter && "empty:hidden",
        classNameContainer
      )}
      style={containerStyles}
      onClick={onClickCell}
    >
      {showLabel && (
        <label className={labelClassName(finalDirection, inForm)}>
          <span className="overflow-hidden text-ellipsis">{label}</span>
          {inForm && rules?.required && <span className="text-red-700 dark:text-red-dark-700"> *</span>}
          {column.isFormula && !inCard && <FormulaIcon className="ml-2" />}
          {column.isAdminColumn && !inCard && <AdminIcon className="ml-2" />}
          {!!column.description && !inCard ? (
            <Tooltip title={column.description} disableInteractive>
              <InfoIcon className="text-secondary ml-2 inline-block h-[18px] w-[18px]" />
            </Tooltip>
          ) : null}
        </label>
      )}
      {displayWithIcons && inCard && <CellIcon label={label as string} iconName={cellTypeIcon} />}
      {label && isLoading && <Skeleton className="mb-0.5 h-3 w-12" />}

      {(isEditMode || (showEditHandlerOnly && type !== CellType.COORDINATE)) &&
      !isInFilters &&
      !isInSeparatedFilter &&
      type === CellType.GENERIC_CELL &&
      ((column.cellConfig?.genericConfig?.layout !== GENERIC_CELL_LAYOUTS.COMBINATION_CELL &&
        !column?.cellConfig?.genericConfig?.editColumnId) ||
        (column.cellConfig?.genericConfig?.layout === GENERIC_CELL_LAYOUTS.COMBINATION_CELL &&
          !column?.cellConfig?.genericConfig?.comboEditColumnIds?.length)) ? (
        <div className="text-xs text-red-800">
          Cell configured incorrectly, the Edit column selection is missing/incorrect.
        </div>
      ) : null}

      {(isEditMode || (showEditHandlerOnly && type !== CellType.COORDINATE)) && finalEditCellColumn ? (
        <CellEditHandler
          column={finalEditCellColumn}
          type={overrideColumnType ? type : finalEditCellColumn?.type}
          value={finalFieldValue}
          isMultiple={isMultiple}
          options={finalOptions}
          isLoading={isLoading}
          onEdit={onChange}
          inForm={inForm}
          inList={inList}
          inCard={inCard}
          inGrid={inGrid}
          placeholder={placeholder}
          disabled={disabled}
          showSearch={showSearch}
          rules={rules}
          hasRequiredError={hasRequiredError}
          onExit={handleDisableEditing}
          ignoreValidation={ignoreValidation}
          autoFocus={autoFocus}
          inDetailOverview={inDetailOverview}
          isJoinTable={isJoinTable}
          row={row}
          isEditing={isEditing}
          isInFilters={isInFilters}
          isInSeparatedFilter={isInSeparatedFilter}
          columnTableName={columnTableName}
          tableFiltersOptions={tableFiltersOption}
          isFocused={isFocused}
          isReadOnly={isReadOnly}
          allColumns={allColumns}
          getColumnOptions={getColumnOptions}
          columnWidth={columnWidth}
          inAddMany={inAddMany}
          expandSelect={expandSelect && setEditMode && (SelectCellTypes.includes(type) || isGenericEditableCell)}
          filterForTableSlug={filterForTableSlug}
          useOnlyType={useOnlyType}
          loadOnClick={loadOnClick}
          additionalValueFields={additionalValueFields}
          isSeparatedFilterActive={isSeparatedFilterActive}
          parentGenericColumn={
            column.type === CellType.GENERIC_CELL && column.cellConfig?.genericConfig?.editColumnId ? column : undefined
          }
          handleEnableEditing={handleEnableEditing}
          isInTable={isInTable}
          onLexicalInitState={onLexicalInitState}
          defaultTablePage={defaultTablePage}
        />
      ) : isCellButtonAction && actionProps ? (
        <ActionsHandler
          displayMode={CellDisplayHandler}
          additionalProps={{
            value: finalFieldValue,
            column,
            row,
            type
          }}
          {...actionProps}
        />
      ) : (
        <CellDisplayHandler
          value={finalFieldValue}
          type={type}
          isLoading={isLoading}
          column={column}
          columnTableName={columnTableName}
          columnTablePath={columnTablePath}
          className={clsx(
            className,
            inList ? "text-primary min-h-[56px] rounded px-3 py-4 font-medium" : "text-primary font-medium",
            isEditable ? "hover:bg-neutral-200 dark:hover:bg-gray-750" : "",
            displayWithIcons && inCard ? "!w-[calc(100%_-_28px)]" : "w-full"
          )}
          onClick={handleEnableEditing}
          isMultiple={isMultiple}
          isEditable={isEditable}
          inGrid={inGrid}
          inCard={inCard}
          gridInlineEditable={gridInlineEditable}
          inList={inList}
          inDetailOverview={inDetailOverview}
          size={size}
          handleExtraActions={handleExtraActions}
          row={row}
          onCellDetailActionClick={onCellDetailActionClick}
          count={count}
          actions={actions}
          allColumns={allColumns}
          isHeader={isHeader}
          autoHeight={autoHeight}
          hideEmpty={hideEmpty}
          hideLinkIcon={hideLinkIcon}
          label={label}
          additionalValueFields={additionalValueFields}
          defaultTablePage={defaultTablePage}
        />
      )}
    </div>
  );
};

CellGroup.displayName = "CellGroup";

export default CellGroup;
