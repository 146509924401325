import React, { useRef } from "react";
import clsx from "clsx";
import { useHover } from "usehooks-ts";
import { MailPlusIcon } from "lucide-react";

import Skeleton from "components/Skeleton";
import IconButton from "components/IconButton";
import Tooltip from "components/Tooltip";
import { copyText } from "utils/texts";
import toast from "utils/toast";
import EllipisWithTooltip from "components/EllipisWithTooltip";
import CellPlaceholder from "./CellPlaceholder";
import CellEmpty from "./CellEmpty";

type CellEmailFields = {
  value: string;
};

type CellEmailProps = CellEmailFields & {
  isLoading?: boolean;
  isEditable?: boolean;
  onClick?: () => void;
  className?: string;
  inList?: boolean;
  inGrid?: boolean;
  inCard?: boolean;
  handleExtraActions?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  isHeader?: boolean;
  singleClickToCopy?: boolean;
};

const CellEmail = ({
  value = "",
  isLoading = false,
  isEditable = false,
  onClick,
  className = "",
  inGrid,
  inList,
  inCard,
  handleExtraActions,
  onKeyDown,
  isHeader = false,
  singleClickToCopy
}: CellEmailProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 max-w-full" />
      </div>
    );
  }

  if (!value && !isEditable) return <CellEmpty className={className} />;

  if (!value && isEditable) {
    return <CellPlaceholder onClick={onClick} className={className} onKeyDown={onKeyDown} />;
  }

  return (
    <div
      className={clsx(
        "relative flex w-full items-center text-sm font-medium",
        isHovered && (inGrid || inCard || inList) && "pr-12",
        className
      )}
      onClick={onClick}
      ref={hoverRef}
      onKeyDown={onKeyDown}
      tabIndex={isEditable || singleClickToCopy ? 0 : undefined}
    >
      {!isEditable && singleClickToCopy ? (
        <Tooltip title="Click to copy" followCursor arrow={false}>
          <div
            className={clsx(
              "w-full cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap",
              isHeader && "font-semibold"
            )}
            onClick={async (e) => {
              e.stopPropagation();
              await copyText(value);
              toast.success("Copied to clipboard");
            }}
          >
            {value}
          </div>
        </Tooltip>
      ) : (
        <EllipisWithTooltip
          className={clsx("w-full overflow-hidden overflow-ellipsis whitespace-nowrap", isHeader && "font-semibold")}
        >
          {value}
        </EllipisWithTooltip>
      )}

      {(inList || inGrid || inCard) && (
        <IconButton
          className={clsx("absolute rounded-lg", inCard ? "right-0" : "right-2", !isHovered && "invisible")}
          icon={MailPlusIcon}
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            handleExtraActions?.();
          }}
        />
      )}
    </div>
  );
};

export default CellEmail;
