import { Popover } from "@headlessui/react";
import clsx from "clsx";
import dayjs from "dayjs";
import noop from "lodash/noop";
import { useCallback, useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { useHover } from "usehooks-ts";

import ActionsHandler from "components/ActionsHandler";
import Avatar from "components/Avatar";
import Tooltip from "components/Tooltip";
import { ViewAction } from "types/baTypes";
import { RecordItem } from "types/common";
import { AvatarSize, UI_ACTIONS } from "utils/constants";
import { formatDateFull, formatDateTime } from "utils/format";
import generateInitials from "utils/generateInitials";
import { IAnnotation } from "../types";
import { IAnnotationType } from "../types/enums";

const LexicalTextEditor = dynamic(() => import("components/LexicalTextEditor"), { ssr: false });

type EditorProps = {
  annotation: IAnnotation;
  onSubmit?: (annotation: IAnnotation) => void;
  onChange?: (annotation: IAnnotation) => void;
  isEditable?: boolean;
  currentUser?: RecordItem;
  actions?: ViewAction[];
  tableName?: string;
  tablePath?: string;
};

const Editor = ({
  annotation,
  onSubmit = noop,
  onChange = noop,
  isEditable = true,
  currentUser,
  actions,
  tableName,
  tablePath
}: EditorProps) => {
  const { geometry } = annotation;
  const note = annotation.data?.[IAnnotationType.NOTE];
  const [isEditing, setIsEditing] = useState(false);
  const [body, setBody] = useState(note?.message || "");

  const noteRef = useRef<HTMLDivElement>(null);
  const isHovered = useHover(noteRef);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!note?.id && isEditable) {
      setIsEditing(true);
    }
  }, [note?.id, isEditable]);

  const handleOnChange = (newValue: string) => {
    setBody(newValue);
  };

  const handleSave = () => {
    const newValue = {
      geometry: annotation.geometry,
      data: {
        ...annotation.data,
        [IAnnotationType.NOTE]: {
          ...annotation.data?.[IAnnotationType.NOTE],
          message: body
        },
        type: IAnnotationType.NOTE
      }
    };
    onChange({});
    onSubmit(newValue);
    setIsEditing(false);
  };

  const handleOnBlur = () => {
    onChange({});
  };

  const handleActionClick = useCallback((action: ViewAction) => {
    if (action.name === UI_ACTIONS.EDIT) {
      setIsEditing(true);
    }
  }, []);

  if (!geometry) return null;

  return (
    <Popover>
      <Popover.Panel
        static
        className="z-[100] flex max-h-72 min-w-[350px] max-w-[448px] flex-col overflow-y-auto rounded-xl bg-white px-2 py-3 shadow-lg"
      >
        <div className={clsx(" gap-2 p-[1px]")} ref={noteRef}>
          {!isEditing && (
            <>
              <div className="flex items-center justify-between">
                {note?.id && (
                  <div className="item-center flex gap-3">
                    <div>
                      <Avatar
                        size={AvatarSize.SM}
                        src={note?.created_by?.path}
                        initials={generateInitials(note?.created_by?.full_name)}
                        type={note?.created_by?.type}
                      />
                    </div>
                    <div className="flex flex-col gap-0.5">
                      <span className="text-sm font-semibold text-base-primary dark:text-base-dark-primary">
                        {note?.created_by?.full_name}
                      </span>
                      <Tooltip title={formatDateFull(dayjs(note?.created_at).toString())} className="inline">
                        <span className="w-fit text-xs text-base-secondary dark:text-base-dark-secondary">
                          {formatDateTime(dayjs(note?.created_at).toString())}
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                )}

                {!!note?.id && !isEditing && !!actions?.length && (
                  <div className="z-[2]">
                    <ActionsHandler
                      actions={actions}
                      iconProps={{ size: "xs" }}
                      record={annotation?.data}
                      tableName={tableName || ""}
                      tablePath={tablePath || ""}
                      hidePortal={false}
                      isHovered={isHovered}
                      showOnlyInHovered
                      onActionsClick={handleActionClick}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          <div className={clsx("relative", isEditable && note?.id && !isEditing && "cursor-pointer")}>
            <div className="flex items-start gap-3">
              <div>
                {((isEditable && !note?.id) || (note?.id && isEditing)) && (
                  <Avatar
                    size={AvatarSize.SM}
                    src={note?.id ? note?.created_by?.path : currentUser?.file?.path}
                    initials={
                      note?.id
                        ? generateInitials(note?.created_by?.full_name)
                        : generateInitials(currentUser?.full_name)
                    }
                    type={note?.id ? note?.created_by?.type : currentUser?.type}
                    hasTooltip
                  />
                )}
              </div>
              <div
                className={clsx("relative flex w-full flex-1")}
                onClick={() => isEditable && setIsEditing(true)}
                ref={wrapperRef}
              >
                {
                  <LexicalTextEditor
                    className={clsx(
                      (isEditing || (isEditable && !note?.id)) && " bg-neutral-200",
                      !isEditing && note?.id && "!min-h-min",
                      "!border-none ring-0"
                    )}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    initialValue={body}
                    hideControls={!isEditing && note?.id}
                    placeholder="Write something"
                    placeholderClassName="!text-base-primary/40 dark:!text-base-dark-primary/40 pl-1 !text-sm"
                    onSave={handleSave}
                    autoFocus={!note?.id && isEditing ? true : false}
                    isEditing={isEditing}
                    isEditable={isEditable}
                    wrapperRef={wrapperRef}
                    showSaveButton
                    showExpandViewIcon
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default Editor;
