import { useMemo, useState } from "react";
import clsx from "clsx";
import Skeleton from "components/Skeleton";
import Image from "components/Image";
import { FALLBACK_IMAGE_SRC, FileCellProps, FileType, Size } from "utils/constants";
import useTableActionsState from "hooks/useTableActionsState";
import FileCardPreview from "components/FileCardPreview";
import { RecordItem } from "types/common";
import { isBAFile } from "../utils";
import CellEmpty from "./CellEmpty";

export type CellFileFields = {
  value: string | File;
  path: string | string[];
};

type CellFileProps = CellFileFields & {
  isLoading?: boolean;
  size?: Size;
  className?: string;
  id?: string;
  inDetailOverview?: boolean;
  fileId?: string;
  fileType?: FileType;
  inCard?: boolean;
  thumbnail_path?: string;
  row?: RecordItem;
  mimetype?: string;
  onFileClick?: () => void;
  disableFileClick?: boolean;
};

const CellFile = ({
  value = "",
  path = "",
  isLoading = false,
  size = Size.SM,
  id,
  className,
  inDetailOverview = false,
  fileId,
  fileType = FileType.IMAGE,
  inCard = false,
  thumbnail_path,
  row,
  mimetype,
  onFileClick,
  disableFileClick
}: CellFileProps) => {
  const [hasLoadError, setHasLoadError] = useState(false);
  const { updateSingleFileViewState } = useTableActionsState();

  const src = useMemo(() => {
    if (hasLoadError) return FALLBACK_IMAGE_SRC;

    if (value && typeof value !== "string" && !Array.isArray(value) && !isBAFile(value))
      return URL.createObjectURL(value);
    if (path && typeof path === "string") return path;
    if (Array.isArray(path)) return path[0];
    if (typeof value === "string") return value;
    return null;
  }, [path, value, hasLoadError]);

  if (isLoading) {
    return <Skeleton className="h-10 w-10" />;
  }

  if (!src) {
    return <CellEmpty />;
  }

  return (
    <div className={className}>
      <div
        className={clsx(
          "relative cursor-pointer",
          inCard
            ? FileCellProps.cardClassName
            : inDetailOverview
              ? FileCellProps.detailClassName
              : FileCellProps.sizeClassName[size]
        )}
        onClick={(e) => {
          e.stopPropagation();
          if (disableFileClick) return;
          if (onFileClick) {
            onFileClick();
          } else {
            updateSingleFileViewState({ isOpen: true, src, id: fileId || id, name: row?.name });
          }
        }}
      >
        {!src && (
          <Image
            alt=""
            src={FALLBACK_IMAGE_SRC}
            {...(inCard
              ? FileCellProps.cardProps
              : inDetailOverview
                ? FileCellProps.detailProps
                : FileCellProps.sizeProps[size] || {})}
          />
        )}
        {!!src && fileType === FileType.IMAGE && (
          <Image
            alt=""
            src={src}
            {...(inCard
              ? FileCellProps.cardProps
              : inDetailOverview
                ? FileCellProps.detailProps
                : FileCellProps.sizeProps[size] || {})}
            onError={() => setHasLoadError(true)}
          />
        )}
        {!!src && (fileType === FileType.VIDEO || fileType === FileType.DOCUMENT) && (
          <FileCardPreview
            src={src}
            classNameContainer={
              inCard
                ? FileCellProps.cardProps.className
                : inDetailOverview
                  ? FileCellProps.detailProps.className
                  : FileCellProps.sizeProps[size]?.className || ""
            }
            fileType={fileType}
            fileId={row?.fileId || fileId || id}
            thumbnailPath={row?.thumbnail_path || thumbnail_path}
            mimeType={row?.mimetype || mimetype}
            recordId={row?.id}
            size="small"
          />
        )}
      </div>
    </div>
  );
};

export default CellFile;
