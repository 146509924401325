import { useRef } from "react";
import clsx from "clsx";
import { useHover } from "usehooks-ts";
import { PenLineIcon } from "lucide-react";

import IconButton from "components/IconButton";
import useTableActionsState from "hooks/useTableActionsState";
import { AdditionalFormActions, STATIC_SIDEBAR_IDS, SidebarContainer } from "utils/constants";
import { TableColumnType } from "types/baTypes";
import { RecordItem } from "types/common";
import EllipisWithTooltip from "components/EllipisWithTooltip";

type CellJSONProps = {
  row: RecordItem;
  value: any;
  className?: string;
  column?: TableColumnType;
  columnTableName?: string;
  inGrid?: boolean;
  inList?: boolean;
  inCard?: boolean;
  autoHeight?: boolean;
};

const CellJSON = ({
  row,
  value,
  className,
  column,
  columnTableName,
  inGrid,
  inList,
  inCard,
  autoHeight
}: CellJSONProps) => {
  const { updateSidebarState } = useTableActionsState();

  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  const handleOpenEditJSONEditor = () => {
    updateSidebarState(
      {
        isOpen: true,
        action: AdditionalFormActions.EDIT_JSON,
        tableName: columnTableName,
        record: row,
        valueToUpdate: value,
        columnToUpdate: column,
        container: SidebarContainer.Sidebar
      },
      STATIC_SIDEBAR_IDS.MAIN_SIDEBAR
    );
  };

  return (
    <div className={clsx("relative", inGrid && "!my-0 py-4", inList && "overflow-hidden", className)} ref={hoverRef}>
      {!!value && (
        <EllipisWithTooltip
          as="pre"
          className={clsx(
            "overflow-hidden text-sm",
            inCard && "line-clamp-1",
            inGrid && autoHeight ? "whitespace-break-spaces break-words" : "overflow-ellipsis whitespace-nowrap"
          )}
        >
          {JSON.stringify(value)}
        </EllipisWithTooltip>
      )}
      {!value && <span>-</span>}

      {isHovered && (
        <div
          className={clsx(
            "absolute right-0 flex gap-x-1",
            (inGrid || inList) && "top-2",
            inCard && "-top-1",
            inList && "right-2"
          )}
        >
          <IconButton
            className="rounded-lg"
            icon={PenLineIcon}
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenEditJSONEditor();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CellJSON;
