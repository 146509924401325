import Link from "next/link";
import React, { useState } from "react";

import Button from "components/Button";
import IconButton from "components/IconButton";
import Skeleton from "components/Skeleton";
import useOpenRecordInNestedView from "hooks/useOpenRecordInNestedView";
import { ViewAction } from "types/baTypes";
import { RecordItem } from "types/common";
import { ButtonAppearance, ButtonType, LINK_OPEN_LOCATIONS, UI_ACTIONS } from "utils/constants";
import Tooltip from "components/Tooltip";
import CellEmpty from "./CellEmpty";

type CellButtonProps = {
  value: string | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, row?: RecordItem) => void);
  label?: string;
  target?: string;
  isLoading?: boolean;
  isLink?: boolean;
  className?: string;
  appearance?: ButtonAppearance;
  type?: ButtonType;
  icon?: string;
  row?: RecordItem;
  actions?: ViewAction[];
  onActionClick?: (action: ViewAction, row: RecordItem) => void;
  disabledAfterAction?: boolean;
  labelAfterAction?: string;
  link_open_location?: string;
  showTooltip?: boolean;
};

const CellButton = ({
  value,
  label = "Link",
  labelAfterAction,
  appearance = ButtonAppearance.SECONDARY,
  isLoading,
  isLink = false,
  className = "",
  type = ButtonType.Button,
  icon,
  actions,
  onActionClick,
  row,
  disabledAfterAction = false,
  link_open_location,
  showTooltip
}: CellButtonProps) => {
  const { openRecordInNestedView } = useOpenRecordInNestedView();
  const [isDisabled, setIsDisabled] = useState(false);
  const [finalLabel, setFinalLabel] = useState(label);

  const isInvalidLink = isLink && !value;
  const isInvalidButtonIcon = type === ButtonType.IconButton && !icon;

  if (isInvalidLink || isInvalidButtonIcon) {
    return <CellEmpty className={className} />;
  }

  if (isLoading) {
    return <Skeleton />;
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    if (typeof value === "function") {
      const onClick = value;
      onClick?.(e, row);
    }

    if (disabledAfterAction) {
      setIsDisabled(true);
    }

    if (actions?.length && row) {
      if (actions?.[0]?.name === UI_ACTIONS.EDIT_TILE_COMPONENTS && row?.originalRow?.public_id) {
        window.open(`/admin/tiles/${row?.originalRow?.id}`, "_blank");
      } else {
        onActionClick?.(actions?.[0], row);
      }
    }

    if (labelAfterAction) {
      setFinalLabel(labelAfterAction);
    }

    if (link_open_location === LINK_OPEN_LOCATIONS.SIDEBAR) {
      handleClickInOpenSidebar(value as string);
    }
  };

  const handleClickInOpenSidebar = (value = "") => {
    const urlParts = (value || "").split("/");
    if (urlParts.length > 2) {
      const recordId = urlParts[urlParts.length - 1];
      const tablePath = urlParts[urlParts.length - 2];

      if (isNaN(+recordId)) {
        // if url last part is not a number i.e not a result I, fallback to opening url in new tab
        window.open(value?.startsWith("http") ? value : `http://${value}`, "_blank");
      } else {
        openRecordInNestedView({ id: recordId }, `/${tablePath}`);
      }
    }
  };

  const isExternalURL = typeof value === "string" ? value.startsWith("http") : false;

  const getAs = () => {
    if (typeof value === "string" && link_open_location !== LINK_OPEN_LOCATIONS.SIDEBAR) {
      if (value && isLink && isExternalURL) {
        return (
          <a
            href={value}
            target={link_open_location === LINK_OPEN_LOCATIONS.NEW_TAB ? "_blank" : undefined}
            rel="noreferrer"
          />
        );
      } else if (value && isLink) {
        return <Link href={value} target={link_open_location === LINK_OPEN_LOCATIONS.NEW_TAB ? "_blank" : undefined} />;
      }
      return <button />;
    }

    return;
  };

  const commonProps = {
    onClick: handleClick,
    disabled: isDisabled
  };

  const buttonProps = {
    appearance,
    label: finalLabel,
    as: getAs(),
    iconName: icon
  };

  const iconButtonProps = {
    color: appearance,
    iconName: icon,
    tag: (isLink && link_open_location !== LINK_OPEN_LOCATIONS.SIDEBAR ? "a" : "button") as React.ElementType,
    href: value as string,
    target: link_open_location === LINK_OPEN_LOCATIONS.NEW_TAB ? "_blank" : undefined
  };

  if (isLink && typeof value === "string" && !type) {
    return (
      <div className={className}>
        <Link href={value}>{label}</Link>
      </div>
    );
  }

  if (type === ButtonType.IconButton) {
    return (
      <div className={className}>
        {showTooltip ? (
          <Tooltip title={label}>
            <IconButton {...commonProps} {...iconButtonProps} />
          </Tooltip>
        ) : (
          <IconButton {...commonProps} {...iconButtonProps} />
        )}
      </div>
    );
  }

  return (
    <div className={className}>
      <Button {...commonProps} {...buttonProps} />
    </div>
  );
};

export default CellButton;
