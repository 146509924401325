import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { SelectOption, SelectOptions } from "types/common";
import Skeleton from "components/Skeleton";
import { CellType, US_STATES } from "utils/constants";
import SelectCellGroup from "../CellGroupHandler/SelectCellGroup";
import { CellGroupValueType } from "../utils";
import CellEmpty from "./CellEmpty";

type CellStateProps = {
  value: string;
  isLoading?: boolean;
  isEditable?: boolean;
  onEdit?: (value: string) => void;
  onClick?: () => void;
  className?: string;
  placeholder?: string;
};

const finalOptions = US_STATES.map((state) => {
  const name = `${state.title} (${state.value})`;
  return {
    title: name,
    value: state.value,
    record: { id: state.value, name }
  };
});

const Cell = ({ name = "", className = "" }) => (
  <div className={clsx("whitespace-nowrap font-normal", className)}>{name}</div>
);

const CellState = ({
  value = "",
  isLoading = false,
  onEdit,
  onClick,
  isEditable = false,
  className = "",
  placeholder = ""
}: CellStateProps) => {
  const [selected, setSelected] = useState<CellGroupValueType[]>([]);

  useEffect(() => {
    if (value != selected[0]?.id) {
      updateSelected([{ id: value }]);
    }
  }, [value]);

  const handleChange = (item: SelectOptions | string | boolean | number | SelectOption) => {
    const value = (item as SelectOption)?.value;
    updateSelected([{ id: value }]);
    onEdit?.(value as string);
  };

  const updateSelected = (val: { id: string | number | undefined }[]) => {
    // @ts-ignore
    setSelected(val);
  };

  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 max-w-full" />
      </div>
    );
  }

  return (
    <div onClick={onClick} className={clsx("w-full", className)}>
      {isEditable ? (
        <SelectCellGroup
          value={selected}
          onEdit={handleChange}
          options={finalOptions}
          cell={Cell}
          isMultiple={false}
          inForm={true}
          showSearch={true}
          showCreate={false}
          placeholder={placeholder}
          type={CellType.STATE}
          classNamePlaceholder="!text-base-secondary dark:!text-base-dark-disabled font-normal text-md"
          className="!text-md"
        />
      ) : (
        value || <CellEmpty className={className} />
      )}
    </div>
  );
};

export default CellState;
