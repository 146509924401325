import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import clsx from "clsx";
import { PlusIcon, Trash2Icon } from "lucide-react";
import dynamicIconImports from "lucide-react/dynamicIconImports";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Button from "components/Button";
import Icon from "components/Icon";
import IconButton from "components/IconButton";
import { IconButtonColor } from "components/IconButton/utils";
import SearchInput from "components/SearchInput";
import Tooltip from "components/Tooltip";
import { ButtonAppearance } from "utils/constants";
import useLucideIconsMetadata from "./useLucideIconsMetadata";

type LucideIconPickerProps = {
  value?: string;
  onIconSelect?: (iconName?: string) => void;
  label?: string;
  className?: string;
  initOpen?: boolean;
  disabled?: boolean;
  isExternalState?: boolean;
};
const LUCIDE_ICON_NAMES = Object.keys(dynamicIconImports);

const LucideIconPicker = ({
  value,
  onIconSelect,
  label = "Add",
  className,
  initOpen = false,
  disabled = false,
  isExternalState = false
}: LucideIconPickerProps) => {
  const [search, setSearch] = useState("");
  const [showPicker, setShowPicker] = useState(initOpen);
  const [targetRef, setTargetRef] = useState<HTMLElement | null>(null);
  const [selectedIcon, setSelectedIcon] = useState(value);
  const iconsMetadata = useLucideIconsMetadata();
  useEffect(() => {
    setSelectedIcon(value);
  }, [value]);

  const filteredIconsList = useMemo(() => {
    if (!search || !iconsMetadata) return LUCIDE_ICON_NAMES;
    return LUCIDE_ICON_NAMES.filter((iconName) =>
      iconsMetadata[iconName]?.some((tag) => tag.toLowerCase().includes(search.toLowerCase()))
    );
  }, [search, iconsMetadata]);

  const handleIconClick = useCallback(
    (iconName?: string) => () => {
      if (!isExternalState) {
        setSelectedIcon(iconName);
      }
      onIconSelect?.(iconName);
      setShowPicker(false);
    },
    [onIconSelect, setShowPicker, isExternalState]
  );

  return (
    <>
      <div ref={setTargetRef} className={clsx("w-fit", className)}>
        {selectedIcon ? (
          <IconButton disabled={disabled} iconName={selectedIcon} onClick={() => setShowPicker((prev) => !prev)} />
        ) : (
          <Button
            onClick={() => setShowPicker((prev) => !prev)}
            label={label}
            icon={PlusIcon}
            appearance={ButtonAppearance.SECONDARY}
            disabled={disabled}
          />
        )}
      </div>
      <Popper open={showPicker} anchorEl={targetRef} placement={"bottom-start"} className="z-50">
        <ClickAwayListener onClickAway={() => setShowPicker(false)}>
          <div className="my-2 flex w-[318px] flex-col gap-4 rounded-xl bg-white p-4 shadow-md dark:bg-base-primary">
            <div className="flex items-center gap-1">
              <SearchInput value={search} onChange={(e) => setSearch(e.target.value)} onClear={() => setSearch("")} />
              {selectedIcon && (
                <IconButton
                  color={IconButtonColor.TRANSPARENT}
                  icon={Trash2Icon}
                  title="Remove Selected Icon"
                  onClick={handleIconClick(undefined)}
                  className="-mr-2"
                />
              )}
            </div>
            <div className="flex h-[230px] w-full flex-wrap content-start gap-2 overflow-auto pr-2 text-base-primary dark:text-base-dark-primary">
              {filteredIconsList.map((iconName) => (
                <Tooltip title={iconName as string} key={iconName as string}>
                  <div
                    className="h-fit w-fit cursor-pointer rounded-md p-1 hover:bg-neutral-100 dark:hover:bg-neutral-dark-100"
                    onClick={handleIconClick(iconName)}
                  >
                    <Icon name={iconName as any} className="pointer-events-none h-6 w-6 !shrink-0" />
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default React.memo(LucideIconPicker);
