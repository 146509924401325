import { useQuery } from "@tanstack/react-query";
import dynamicIconImports from "lucide-react/dynamicIconImports";
const LUCIDE_ICON_NAMES = Object.keys(dynamicIconImports);

const getIconsMetadata = async () => {
  try {
    const [tagsMetadata, categoriesMetadata] = await Promise.all([
      fetch("https://lucide.dev/api/tags").then((res) => res.json() as Promise<Record<string, string[]>>),
      fetch("https://lucide.dev/api/categories").then((res) => res.json() as Promise<Record<string, string[]>>)
    ]);
    return LUCIDE_ICON_NAMES.reduce((acc: Record<string, string[]>, iconName) => {
      acc[iconName] = [iconName, ...(tagsMetadata[iconName] || []), ...(categoriesMetadata[iconName] || [])].filter(
        Boolean
      );
      return acc;
    }, {});
  } catch (err) {
    console.log("Error fetching Lucide icons metadata", err);
  }
  return LUCIDE_ICON_NAMES.reduce((acc: Record<string, string[]>, iconName) => {
    acc[iconName] = [iconName];
    return acc;
  }, {});
};

const useLucideIconsMetadata = () => {
  const { data } = useQuery({
    queryKey: ["lucide-icons-metadata"],
    queryFn: getIconsMetadata,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  });

  return data;
};

export default useLucideIconsMetadata;
