"use client";

import { RotateDirection, Viewer } from "@react-pdf-viewer/core";
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import type { RenderRotateProps } from "@react-pdf-viewer/rotate";
import { CornerDownLeftIcon, CornerDownRightIcon } from "lucide-react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import { buildSupabasePublicUrl } from "components/Image/utils";
import IconButton from "components/IconButton";
import Tooltip from "components/Tooltip";
import Loader from "components/Loader";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "./style.css";

type DocumentViewerProps = {
  src: string;
  mimetype?: string;
};

const DocumentViewer = ({ src, mimetype }: DocumentViewerProps) => {
  const isExternal = src.startsWith("http") || src.startsWith("blob");
  const finalSrc = isExternal ? src : buildSupabasePublicUrl(src);

  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  if (!finalSrc || (!isExternal && !mimetype)) return null;

  if (!mimetype) {
    return null;
  }

  if (mimetype === "application/pdf") {
    return (
      <div className="relative h-full w-full cursor-pointer bg-transparent">
        <div className="-top-10 z-50 text-neutral-300 dark:text-neutral-dark-300 max-lg:top-0 lg:absolute lg:right-0">
          <Toolbar>
            {({ Rotate }: ToolbarSlot) => (
              <div className="flex gap-2 max-lg:mb-4 max-lg:justify-end max-lg:pr-4">
                <Rotate direction={RotateDirection.Backward}>
                  {(props: RenderRotateProps) => (
                    <Tooltip title="Rotate counterclockwise">
                      <IconButton className="bg-background" icon={CornerDownLeftIcon} {...props} />
                    </Tooltip>
                  )}
                </Rotate>
                <Rotate direction={RotateDirection.Forward}>
                  {(props: RenderRotateProps) => (
                    <Tooltip title="Rotate clockwise">
                      <IconButton className="bg-background" icon={CornerDownRightIcon} {...props} />
                    </Tooltip>
                  )}
                </Rotate>
              </div>
            )}
          </Toolbar>
        </div>

        <div
          className="h-full w-full cursor-pointer overflow-auto bg-transparent"
          onClick={() => window.open(finalSrc, "_blank")}
        >
          <Viewer
            renderLoader={() => <Loader className="text-white" />}
            fileUrl={finalSrc}
            plugins={[toolbarPluginInstance]}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="h-full w-full cursor-pointer bg-transparent">
        <DocViewer
          documents={[{ uri: finalSrc, fileType: mimetype }]}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true
            },
            loadingRenderer: {
              overrideComponent: () => <Loader className="text-white" />
            }
          }}
          pluginRenderers={DocViewerRenderers}
        />
      </div>
    );
  }
};

export default DocumentViewer;
