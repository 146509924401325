import clsx from "clsx";
import { useHover } from "usehooks-ts";
import { XIcon, BriefcaseIcon } from "lucide-react";
import noop from "lodash/noop";

import { useRef } from "react";
import Image from "components/Image";
import Skeleton from "components/Skeleton";
import CellEmpty from "./CellEmpty";

export type CellCompanyFields = {
  path: string;
  name: string;
};

type CellCompanyProps = CellCompanyFields & {
  onRemoveClick?: () => void;
  isEditable?: boolean;
  isLoading?: boolean;
  isInOptions?: boolean;
  isSelected?: boolean;
  isHeader?: boolean;
  onClick?: () => void;
};

const CellCompany = ({
  path = "",
  name = "",
  onRemoveClick,
  isEditable = false,
  isLoading = false,
  isInOptions = false,
  isSelected = false,
  isHeader = false,
  onClick = noop
}: CellCompanyProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  if (isLoading) {
    return <Skeleton className="h-3 w-24" />;
  }

  if (!name) return <CellEmpty />;

  return (
    <div
      className={clsx(
        "relative inline-flex max-w-full flex-nowrap items-center gap-x-1.5 overflow-hidden  rounded-full bg-blue-100 py-0.5 pl-0.5  pr-2 text-blue-800  dark:bg-blue-900 dark:text-blue-200",
        isInOptions && isSelected && "max-w-[90%]"
      )}
      ref={hoverRef}
      onClick={onClick}
    >
      {path ? (
        <Image src={path} alt={name} width={20} height={20} className="shrink-0 rounded" />
      ) : (
        <BriefcaseIcon className="h-4 w-4 shrink-0" />
      )}
      <div className="relative flex max-w-[calc(100%_-_20px)]">
        {/* Ghost text to know the text width to show the remove icon without increase width */}
        <div
          className={clsx(
            "overflow-hidden overflow-ellipsis whitespace-nowrap text-sm",
            isEditable && "opacity-0",
            isHeader ? "font-semibold" : "font-medium"
          )}
        >
          {name}
        </div>
        {isEditable && (
          <div className="absolute inset-0 flex overflow-hidden">
            <div className={"overflow-hidden overflow-ellipsis whitespace-nowrap text-sm font-medium"}>{name}</div>
            {onRemoveClick && (
              <button
                className={clsx("", isHovered ? "block" : "hidden")}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveClick?.();
                }}
              >
                <XIcon className="h-4 w-4" />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CellCompany;
