import React, { useRef } from "react";
import { XIcon } from "lucide-react";
import clsx from "clsx";
import Image from "next/image";
import noop from "lodash/noop";
import { useHover } from "usehooks-ts";

import Skeleton from "components/Skeleton";
import CellEmpty from "./CellEmpty";

export type CellSpaceFields = {
  icon: string;
  name: string;
  nickname?: string;
};

type CellSpaceProps = CellSpaceFields & {
  onRemoveClick?: () => void;
  isEditable?: boolean;
  isLoading?: boolean;
  isHeader?: boolean;
  onClick?: () => void;
  isMultiple?: boolean;
};

const CellSpace = ({
  onRemoveClick,
  isEditable,
  isLoading,
  name = "",
  icon = "",
  nickname,
  isHeader = false,
  onClick = noop,
  isMultiple = false
}: CellSpaceProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  if (isLoading) {
    return <Skeleton className="h-3 w-24" />;
  }

  if (!name && !nickname) return <CellEmpty />;

  return (
    <div
      className="relative inline-flex flex-nowrap items-center gap-x-1 overflow-hidden rounded-full bg-neutral-200 px-2 dark:bg-neutral-dark-0"
      onClick={onClick}
      ref={isMultiple ? hoverRef : undefined}
    >
      {icon && <Image key={icon} src={icon} alt={name} height={24} width={24} />}
      <div className="relative flex">
        {/* Ghost text to know the text width to show the remove icon without increase width */}
        <div
          className={clsx(
            "overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm",
            isEditable && "opacity-0",
            isHeader ? "font-semibold" : "font-medium"
          )}
        >
          {nickname || name}
        </div>
        {isEditable && (
          <div className="absolute inset-0 flex overflow-hidden">
            <div className={"text-primary overflow-hidden overflow-ellipsis whitespace-nowrap text-sm font-medium"}>
              {nickname || name}
            </div>
            <button
              className={clsx("", isHovered ? "block" : "hidden")}
              onClick={(e) => {
                e.stopPropagation();
                onRemoveClick?.();
              }}
            >
              <XIcon className="h-4 w-4" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(CellSpace);
