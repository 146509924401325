"use client";

import clsx from "clsx";
import CellGroup from "components/CellGroup";
import Icon from "components/Icon";
import { getColumnValueFromRowData, getTitleTextFromColumn } from "lib/utils";
import { ApiRecordType } from "types/apiTypes";
import { TableColumnType, TableViewConfig } from "types/baTypes";
import { RecordItem } from "types/common";
import { ExtendedSchema } from "utils/schema";

const DetailHeaderMultiTitle = ({
  config,
  columns,
  recordData,
  extendedSchema,
  recordTypesData,
  type = "title",
  textClassName = ""
}: {
  config?: TableViewConfig;
  columns: TableColumnType[];
  recordData: RecordItem;
  extendedSchema?: ExtendedSchema;
  recordTypesData?: ApiRecordType[];
  type: "title" | "subtitle";
  textClassName?: string;
}) => {
  const multiTitleConfig =
    type === "subtitle" ? config?.additionalConfig?.multiSubtitle : config?.additionalConfig?.multiTitle;
  if (!multiTitleConfig) return null;

  return (
    <div className="flex items-center">
      {multiTitleConfig.map((titleCol) => {
        if (titleCol.type === "text" && titleCol.value) {
          return (
            <span key={titleCol.id} className={textClassName}>
              {titleCol.value}
            </span>
          );
        } else if (titleCol.type === "column") {
          if (type === "title") {
            const title =
              getTitleTextFromColumn({
                colId: titleCol.value,
                columns,
                recordData,
                extendedSchema,
                recordTypesData
              }) || "";
            return (
              <span className={textClassName} key={`${titleCol.id}_${titleCol.value}`}>
                {title}
              </span>
            );
          }
          const colConfig = columns.find((col) => col.id === titleCol.value);
          if (!colConfig) return null;
          const value = getColumnValueFromRowData({
            row: recordData,
            col: colConfig,
            colOptions: columns,
            extendedSchema,
            recordTypesData
          });
          return (
            <CellGroup
              key={`${titleCol.id}_${colConfig.id}`}
              value={value}
              row={recordData}
              column={colConfig}
              allColumns={columns}
              type={colConfig.type}
              classNameContainer="flex items-center"
            />
          );
        } else if (titleCol.type === "icon") {
          return (
            <Icon
              name={titleCol.value as any}
              key={titleCol.id}
              className={clsx("shrink-0", type === "title" ? "h-6 w-6" : "h-4 w-4")}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default DetailHeaderMultiTitle;
