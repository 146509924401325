import clsx from "clsx";

import Icon from "components/Icon";
import useOpenRecordInNestedView from "hooks/useOpenRecordInNestedView";
import { RecordItem } from "types/common";
import { ViewOption } from "utils/constants";
import CellEmpty from "./CellEmpty";

type CellExistProps = {
  value: any;
  className?: string;
  type?: "icon" | "count";
  icon?: string;
  pageTabId?: string;
  record?: RecordItem;
  columnTablePath?: string;
  isHeader?: boolean;
};

const CellExist = ({ value, className, type, icon, pageTabId, record, columnTablePath, isHeader }: CellExistProps) => {
  const { openRecordInNestedView } = useOpenRecordInNestedView();

  const finalValue = Array.isArray(value) ? value?.length : null;

  const handleClick = (e: any) => {
    e.stopPropagation();
    if (record && columnTablePath) {
      openRecordInNestedView(record, columnTablePath, {
        defaultPageTab: pageTabId
      });
    }
  };

  if (!finalValue) return <CellEmpty className={clsx(className, "cursor-pointer")} onClick={handleClick} />;

  if (type === "icon") {
    return (
      <button className={className} onClick={handleClick}>
        <Icon name={icon as any} className="text-secondary h-4 w-4" />
      </button>
    );
  }

  return <div className={clsx(className, isHeader && "font-semibold")}>{finalValue}</div>;
};

export default CellExist;
