// Hook used to fetch file annotations related data

import { useQuery } from "@tanstack/react-query";
import isObject from "lodash/isObject";
import { useEffect, useMemo } from "react";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { IAnnotation, IGeometry } from "components/ImageAnnotate/types";
import { IAnnotationType, SELECTOR_TYPES } from "components/ImageAnnotate/types/enums";
import { getTableData } from "lib/supabaseApi";
import {
  getColumnByNameAndTableName,
  getColumnOptionsLookupPropAndColumnName,
  getColumnValueFromRowData
} from "lib/utils";
import { Page, TableColumnType } from "types/baTypes";
import { QueryHookOptions, RecordItem } from "types/common";
import { generateFinalDataColumns, getCompositeKeysFromCols } from "utils/dataUtils";
import { LookupTypes, ViewOption } from "utils/constants";

import { appendRecordTypeLookupToCol } from "utils/columnUtils";
import useRecordTypes from "./useRecordTypes";
import useCurrentUser from "./useCurrentUser";
import useSchemaState from "./useSchemaState";

type FileAnnotationProps = {
  fileId: string;
  pageData?: Page;
};
const useFileAnnotations = ({ fileId, pageData }: FileAnnotationProps, options?: QueryHookOptions) => {
  const { data: recordTypesData } = useRecordTypes();
  const finalFilters = useMemo(() => {
    const filters = pageData?.pageFilters?.filters || [];
    // ##HARDCODED works with files_annotations and files table
    if (!filters?.find((filter) => filter.filterValue === fileId)) {
      // add file_id filter
      filters.push({
        id: "file_annotate_filter",
        filterField: "",
        filterValue: fileId,
        filterOperator: "equals",
        filterLookupPath: {
          "0": {
            lookupTableName: "files_annotations",
            lookupType: LookupTypes.JOIN,
            lookupColumns: []
          },
          "1": {
            lookupTableName: "files",
            lookupType: LookupTypes.FOREIGN,
            lookupForeignKey: "file_id",
            lookupColumns: ["id"]
          }
        }
      });
    }
    return { filters };
  }, [pageData?.pageFilters, fileId]);

  const { schemaInstance } = useSchemaState();
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();

  const compositeKeysByTable = useMemo(() => {
    return getCompositeKeysFromCols(pageData?.columns, schemaInstance?.extendedSchema);
  }, [pageData?.columns, schemaInstance?.extendedSchema]);

  const finalDataColumns = useMemo(() => {
    if (!pageData?.columns) return [];

    const finalPageCols = pageData?.columns
      ?.map((col) =>
        appendRecordTypeLookupToCol(
          col,
          recordTypesData || [],
          pageData?.table_name || "",
          schemaInstance?.extendedSchema
        )
      )
      .filter(Boolean) as TableColumnType[];

    return generateFinalDataColumns({
      columns: finalPageCols || [],
      view: ViewOption.DETAIL_MAIN,
      config: pageData?.views?.find((view) => view.viewType === ViewOption.DETAIL_MAIN),
      recordTypesData,
      tableName: pageData?.table_name || "",
      extendedSchema: schemaInstance?.extendedSchema
    });
  }, [pageData, recordTypesData, schemaInstance?.extendedSchema]);

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["table", pageData?.table_name, fileId],
    queryFn: () =>
      getTableData({
        tableName: pageData?.table_name || "",
        tableFiltersOption: finalFilters,
        columns: finalDataColumns || [],
        globalSort: pageData?.page_config?.globalSort,
        supabaseClient,
        compositeKeysByTable,
        currentUser,
        extendedSchema: schemaInstance?.extendedSchema
      }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!fileId && !!pageData && !!finalDataColumns?.length,
    ...options
  });

  const columnsForNotes = useMemo(() => {
    const messageColumn = getColumnByNameAndTableName(finalDataColumns, "message", "notes");
    const createdByColumn = getColumnByNameAndTableName(finalDataColumns, "full_name", "people");
    const createdAtColumn = getColumnByNameAndTableName(finalDataColumns, "created_at", "notes");
    const xyCoordinateColumn = getColumnByNameAndTableName(finalDataColumns, "", "files_annotations");

    const finalColumns: RecordItem = {};

    if (messageColumn) {
      finalColumns["message"] = messageColumn;
    }

    if (xyCoordinateColumn) {
      finalColumns["xyCoordinate"] = xyCoordinateColumn;
    }

    if (createdAtColumn) {
      finalColumns["createdAt"] = createdAtColumn;
    }

    if (createdByColumn) {
      finalColumns["createdBy"] = createdByColumn;
    }

    return finalColumns;
  }, [finalDataColumns]);

  const annotations: IAnnotation[] = useMemo(() => {
    if (!data?.data) return [];

    const formattedData: IAnnotation[] = [];

    data?.data?.forEach((annotation) => {
      const finalData: RecordItem = {
        id: annotation.id,
        type: IAnnotationType.NOTE
      };

      const messageColumn = columnsForNotes.message;
      const xyCoordinate = columnsForNotes.xyCoordinate;
      const createdByColumn = columnsForNotes.createdBy;
      const createdAtColumn = columnsForNotes.createdAt;

      const noteData: RecordItem = {};

      if (pageData?.table_name === "notes") {
        noteData.id = annotation.id;
      } else {
        noteData.id = isObject(annotation?.note_id) ? annotation?.note_id?.id : annotation?.note_id;
      }

      const geometry: IGeometry = { type: SELECTOR_TYPES.POINT };

      if (messageColumn) {
        const message = getColumnValueFromRowData({ row: annotation, col: messageColumn, colOptions: [messageColumn] });

        if (Array.isArray(message)) {
          noteData["message"] = message[0]?.message;
        } else if (typeof message === "string") {
          noteData["message"] = message;
        }
      }

      if (createdByColumn) {
        const createdBy = getColumnValueFromRowData({
          row: annotation,
          col: createdByColumn,
          colOptions: [createdByColumn]
        });
        noteData["created_by"] = createdBy?.[0];
      }

      if (createdAtColumn) {
        const createdAt = getColumnValueFromRowData({
          row: annotation,
          col: createdAtColumn,
          colOptions: [createdAtColumn]
        });
        const finalProperty: null | { lookupCols: string } = getColumnOptionsLookupPropAndColumnName(createdAtColumn);

        if (finalProperty?.lookupCols && Array.isArray(createdAt) && createdAtColumn.isLookup) {
          noteData["created_at"] = createdAt[0]?.[finalProperty.lookupCols];
        } else {
          noteData["created_at"] = createdAt;
        }
      }

      if (xyCoordinate) {
        const x_coordinate = getColumnValueFromRowData({
          row: annotation,
          col: xyCoordinate,
          colOptions: [xyCoordinate]
        });
        if (Array.isArray(x_coordinate)) {
          geometry.x = x_coordinate[0]?.x_coordinate;
          geometry.y = x_coordinate[0]?.y_coordinate;
        } else {
          geometry.x = x_coordinate;
        }
      }

      finalData[IAnnotationType.NOTE] = noteData;

      // if both coordinates are present then add to annotations list
      if (geometry.x !== undefined && geometry.y !== undefined) {
        formattedData.push({ geometry, data: finalData });
      }
    });

    return formattedData;
  }, [data?.data, columnsForNotes, pageData?.table_name]);

  return {
    isLoading,
    refetch,
    data: annotations
  };
};

export default useFileAnnotations;
