import { useState } from "react";
import { usePopper } from "react-popper";
import Avatar from "components/Avatar";
import { AvatarSize } from "utils/constants";
import { RecordItem } from "types/common";
import generateInitials from "utils/generateInitials";
import { IAnnotation } from "../types";
import Editor from "./Editor";
import Point from "./Point";

type SelectorProps = {
  annotation: IAnnotation;
  disableEditor?: boolean;
  onChange?: (annotation: IAnnotation) => void;
  onSubmit?: (e: any) => void;
  currentUser?: RecordItem;
};
const Selector = ({ annotation, disableEditor, onChange, onSubmit, currentUser }: SelectorProps) => {
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const note = annotation.data?.[annotation?.data?.type];

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [-8, 8]
        }
      }
    ]
  });
  return (
    <>
      <Point annotation={annotation} ref={setTargetElement}>
        <Avatar
          size={AvatarSize.XS}
          src={note?.id ? note?.created_by?.path : currentUser?.file?.path}
          initials={note?.id ? generateInitials(note?.created_by?.full_name) : generateInitials(currentUser?.full_name)}
          type={note?.id ? note?.created_by?.type : currentUser?.type}
        />
      </Point>
      {!disableEditor && annotation.selection?.showEditor ? (
        <div ref={setPopperElement as any} style={styles.popper} {...attributes.popper}>
          <Editor annotation={annotation} onChange={onChange} onSubmit={onSubmit} currentUser={currentUser} />
        </div>
      ) : null}
    </>
  );
};

export default Selector;
