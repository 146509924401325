import { useRef } from "react";
import { mergeProps, useFocusRing, useSlider, useSliderThumb, VisuallyHidden } from "react-aria";
import { useSliderState } from "react-stately";
import clsx from "clsx";

import { formatTime, parseTime } from "./utils";

type ThumProps = {
  state: ReturnType<typeof useSliderState>;
  trackRef: React.RefObject<HTMLDivElement>;
  index: number;
  onChangeStart?: () => void;
  focusProps: React.HTMLAttributes<HTMLDivElement>;
  isFocusVisible?: boolean;
};

const Thumb = (props: ThumProps) => {
  const { state, trackRef, focusProps, isFocusVisible, index } = props;
  const inputRef = useRef(null);
  const { thumbProps, inputProps } = useSliderThumb({ index, trackRef, inputRef }, state);

  return (
    <div
      className="absolute top-1/2 -translate-x-1/2"
      style={{
        left: `${state.getThumbPercent(index) * 100}%`,
      }}
    >
      <div
        {...thumbProps}
        onMouseDown={(...args) => {
          thumbProps.onMouseDown?.(...args);
          props.onChangeStart?.();
        }}
        onPointerDown={(...args) => {
          thumbProps.onPointerDown?.(...args);
          props.onChangeStart?.();
        }}
        className={clsx(
          "h-4 rounded-full",
          isFocusVisible || state.isThumbDragging(index)
            ? "w-1.5 bg-base-secondary dark:bg-base-dark-secondary"
            : "w-1 bg-base-secondary dark:bg-base-dark-secondary"
        )}
      >
        <VisuallyHidden>
          <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
        </VisuallyHidden>
      </div>
    </div>
  );
};

type SliderProps = {
  label?: string;
  maxValue: number;
  step?: number;
  value: any[];
  onChange: (value: any[]) => void;
  onChangeStart?: () => void;
  onChangeEnd?: (value: any[]) => void;
  numberFormatter: Intl.NumberFormat;
};

const Slider = (props: SliderProps) => {
  const trackRef = useRef(null);
  const state = useSliderState(props);
  const { groupProps, trackProps, labelProps, outputProps } = useSlider(props, state, trackRef);
  const { focusProps, isFocusVisible } = useFocusRing();

  const currentTime = parseTime(state.getThumbValue(0));
  const totalTime = parseTime(state.getThumbMaxValue(0));

  return (
    <div
      {...groupProps}
      className="absolute inset-x-0 bottom-full flex flex-auto touch-none items-center gap-6 md:relative"
    >
      {props.label && (
        <label className="sr-only" {...labelProps}>
          {props.label}
        </label>
      )}
      <div
        {...trackProps}
        onMouseDown={(...args) => {
          trackProps.onMouseDown?.(...args);
          props.onChangeStart?.();
        }}
        onPointerDown={(...args) => {
          trackProps.onPointerDown?.(...args);
          props.onChangeStart?.();
        }}
        ref={trackRef}
        className="relative w-full bg-neutral-300 dark:bg-neutral-dark-300 md:rounded-full"
      >
        <div
          className={clsx(
            "h-2 md:rounded-r-md md:rounded-l-xl",
            isFocusVisible || state.isThumbDragging(0)
              ? "bg-base-secondary dark:bg-base-dark-secondary"
              : "bg-base-secondary dark:bg-base-dark-secondary"
          )}
          style={{
            width:
              state.getThumbValue(0) === 0
                ? 0
                : `calc(${state.getThumbPercent(0) * 100}% - ${
                    isFocusVisible || state.isThumbDragging(0) ? "0.3125rem" : "0.25rem"
                  })`,
          }}
        />
        <Thumb
          index={0}
          state={state}
          trackRef={trackRef}
          onChangeStart={props.onChangeStart}
          focusProps={focusProps}
          isFocusVisible={isFocusVisible}
        />
      </div>
      <div className="hidden items-center gap-2 md:flex">
        <output
          {...outputProps}
          aria-live="off"
          className={clsx(
            "hidden rounded-md px-1 py-0.5 font-mono text-sm leading-6 md:block",
            state.getThumbMaxValue(0) === 0 && "opacity-0",
            isFocusVisible || state.isThumbDragging(0)
              ? "bg-neutral-200 text-base-primary dark:bg-neutral-dark-200 dark:text-base-dark-primary"
              : "text-base-disabled dark:text-base-dark-disabled"
          )}
        >
          {formatTime(currentTime, totalTime)}
        </output>
        <span className="text-sm leading-6 text-neutral-300 dark:text-neutral-dark-300" aria-hidden="true">
          /
        </span>
        <span
          className={clsx(
            "hidden rounded-md px-1 py-0.5 font-mono text-sm leading-6 text-base-disabled dark:text-base-dark-disabled md:block",
            state.getThumbMaxValue(0) === 0 && "opacity-0"
          )}
        >
          {formatTime(totalTime)}
        </span>
      </div>
    </div>
  );
};

export default Slider;
