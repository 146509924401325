import { useCallback } from "react";

import { NestedViewState } from "context/NestedViewContext";
import { RecordItem } from "types/common";
import useAllPagesLite from "./useAllPagesLite";
import useSearchQueryParams from "./useSearchQueryParams";
import useUserType from "./useUserType";
import useCurrentUser from "./useCurrentUser";

const useOpenRecordInNestedView = () => {
  const { data: allPages } = useAllPagesLite();
  const { userType } = useUserType();
  const { setParams } = useSearchQueryParams();
  const currentUser = useCurrentUser();

  const openRecordInNestedView = useCallback(
    (record: RecordItem, tablePath: string, additionalProps?: Partial<NestedViewState>) => {
      const pageDataToOpen = allPages?.find(
        (page) =>
          page?.path === tablePath && page?.user_type === userType && `${page.org_id}` === `${currentUser?.org_id}`
      );

      if (!pageDataToOpen || !record) return;
      setParams({
        cellSide: {
          pageId: pageDataToOpen?.id,
          recordId: record.id,
          tabId: additionalProps?.defaultPageTab
        }
      });
    },
    [allPages, userType, setParams, currentUser?.org_id]
  );

  return {
    openRecordInNestedView
  };
};

export default useOpenRecordInNestedView;
