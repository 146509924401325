import { useEffect, useMemo, useRef, useState } from "react";
import { Switch } from "@headlessui/react";
import clsx from "clsx";
import { useHover } from "usehooks-ts";
import { useTheme } from "next-themes";

import isBoolean from "lodash/isBoolean";
import Skeleton from "components/Skeleton";
import { CellRules } from "types/baTypes";
import Icon from "components/Icon";

type CellBooleanProps = {
  value?: boolean;
  isEditable?: boolean;
  inForm?: boolean;
  onEdit?: (value: boolean) => void;
  isLoading?: boolean;
  disabled?: boolean;
  rules?: CellRules;
  hasRequiredError?: boolean;
  className?: string;
  isFocused?: boolean;
  inCard?: boolean;
  gridInlineEditable?: boolean;
  inList?: boolean;
  activeIcon?: string;
  inactiveIcon?: string;
  activeColor?: string;
  activeColorDarkMode?: string;
  inactiveColor?: string;
  inactiveColorDarkMode?: string;
  showEmptyCellForInactive?: boolean;
  showFalseState?: boolean;
  falseStateIcon?: string;
  falseStateColor?: string;
  falseStateColorDarkMode?: string;
  title?: string;
  isInSeparatedFilter?: boolean;
  isCentered?: boolean;
};

const CellBoolean = ({
  value,
  isLoading = false,
  onEdit,
  isEditable,
  inForm = false,
  inList = false,
  disabled = false,
  className = "",
  isFocused = false,
  inCard = false,
  gridInlineEditable = false, //  used to not disable the switch when the cell is editable in grid to allow event propagation to the grid
  activeIcon,
  inactiveIcon,
  activeColor,
  activeColorDarkMode,
  inactiveColor,
  inactiveColorDarkMode,
  showEmptyCellForInactive = false,
  title,
  isInSeparatedFilter = false,
  showFalseState = false,
  falseStateIcon,
  falseStateColor,
  falseStateColorDarkMode,
  isCentered = false
}: CellBooleanProps) => {
  const { resolvedTheme } = useTheme();
  const [enabled, setEnabled] = useState(value);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  useEffect(() => {
    if (enabled !== value) {
      setEnabled(value);
    }
  }, [value]);

  useEffect(() => {
    if (isFocused) {
      buttonRef.current?.focus();
    }
  }, [isFocused]);

  const handleChange = (enabledUpdated: boolean) => {
    setEnabled(enabledUpdated);
    onEdit?.(enabledUpdated);
  };

  /**
   * if showFalseState is enabled, all empty value apart from 'false' is treated as inactive value
   * else if showFalseState is disabled,all falsy values including 'false' is  treated as inactive value
   */
  const isInactiveValue = useMemo(() => (showFalseState ? !isBoolean(enabled) : !enabled), [showFalseState, enabled]);

  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 max-w-full" />
      </div>
    );
  }

  if (showEmptyCellForInactive && isInactiveValue) return null;

  if ((enabled && activeIcon) || (isInactiveValue ? inactiveIcon : falseStateIcon)) {
    const finalActiveColor = resolvedTheme === "dark" ? activeColorDarkMode || activeColor : activeColor;
    const finalInactiveColor = resolvedTheme === "dark" ? inactiveColorDarkMode || inactiveColor : inactiveColor;
    const finalFalseStateColor =
      resolvedTheme === "dark" ? falseStateColorDarkMode || falseStateColor : falseStateColor;

    return (
      <div
        className={clsx(inList && "m-2", className, isCentered && "flex items-center justify-center")}
        ref={hoverRef}
      >
        <button
          onClick={() => isEditable && handleChange(!enabled)}
          className={clsx(
            "group/boolean inline-flex h-9 w-9 shrink-0 items-center justify-center rounded-full",
            enabled ? "" : "border-separator border hover:bg-neutral-100 dark:hover:bg-neutral-dark-100",
            !isEditable && "cursor-not-allowed"
          )}
          style={{
            backgroundColor: enabled ? finalActiveColor : isInactiveValue ? finalInactiveColor : finalFalseStateColor,
            color: !enabled && isHovered ? finalActiveColor : ""
          }}
          ref={buttonRef}
        >
          <Icon
            name={enabled ? activeIcon : isInactiveValue ? (inactiveIcon as any) : (falseStateIcon as any)}
            className={clsx("h-5 w-5", enabled ? "text-white" : "mix-blend-difference")}
          />
        </button>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        isEditable && !inCard && !inForm && " px-2 py-2",
        isInSeparatedFilter &&
          "text-primary text-primary inline-flex h-9 scale-100 cursor-pointer items-center justify-center gap-x-1 rounded-md border border-neutral-300 shadow-50 transition-transform hover:bg-neutral-200 hover:shadow-none active:scale-[0.98] active:bg-neutral-300 active:shadow-none dark:border-neutral-dark-300 dark:hover:bg-neutral-dark-200 dark:active:bg-neutral-dark-300",
        className,
        isCentered && "flex items-center justify-center"
      )}
    >
      {!!(isInSeparatedFilter && title) && (
        <span className="pointer-events-none whitespace-nowrap text-sm font-medium">{title}</span>
      )}
      <Switch
        checked={enabled}
        onChange={handleChange}
        disabled={gridInlineEditable ? false : !isEditable}
        className={clsx(
          enabled ? "!bg-primary-700 !opacity-100 " : "!bg-neutral-300 !opacity-100 dark:!bg-neutral-dark-300",
          "relative inline-flex h-[23.33px] w-10 shrink-0 items-center rounded-full border-2 border-transparent px-[2px] transition-colors duration-200 ease-in-out",
          disabled && "!opacity-50",
          isEditable && "cursor-pointer"
        )}
        ref={buttonRef}
        title={title}
      >
        <span
          aria-hidden="true"
          className={`${enabled ? "translate-x-[15px]" : "translate-x-0"}
            pointer-events-none inline-block h-[16.67px] w-[16.67px] transform rounded-full bg-neutral-0 shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    </div>
  );
};

export default CellBoolean;
