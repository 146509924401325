import clsx from "clsx";
import Image from "next/image";

const ThumbnailImage = ({
  snapshot,
  thumbnailSizes,
  classname = "object-cover"
}: {
  snapshot: string;
  thumbnailSizes?: string;
  classname?: string;
}) => {
  const sizes = thumbnailSizes ? thumbnailSizes : "(max-width: 768px) 150px, (max-width: 1200px) 256px, 240px";

  return (
    <div className="react-thumbnail-generator">
      <Image
        src={snapshot}
        alt="Video thumbnail"
        className={clsx("h-full w-full", classname)}
        sizes={sizes}
        fill
        priority
      />
    </div>
  );
};

export default ThumbnailImage;
