import clsx from "clsx";
import Tooltip from "components/Tooltip";
import Icon from "components/Icon";
import LucideIconPicker from "components/LucideIconPicker";
import CellPlaceholder from "./CellPlaceholder";

const CellIcon = ({
  label,
  iconName,
  className,
  isEditable,
  onEdit,
  isFocused,
  onClick
}: {
  label?: string;
  iconName?: string;
  className?: string;
  isEditable?: boolean;
  onEdit?: (value: any) => void;
  isFocused?: boolean;
  onClick?: () => void;
}) => {
  if (isEditable) {
    return (
      <div className="flex w-full items-center justify-center">
        <LucideIconPicker initOpen={isFocused} value={iconName as any} onIconSelect={onEdit} className={className} />
      </div>
    );
  }

  if (!iconName && isEditable) {
    return <CellPlaceholder onClick={onClick} className={className} />;
  }

  if (!iconName) {
    return null;
  }

  return (
    <Tooltip title={label || iconName} className="inline">
      <div className={className} onClick={onClick}>
        <Icon
          name={iconName as any}
          className={clsx("h-4 w-4 text-[#1D2433]/40 hover:text-base-secondary dark:text-base-dark-secondary/40")}
        />
      </div>
    </Tooltip>
  );
};

export default CellIcon;
