import { ColumnRule, TableColumnType } from "types/baTypes";
import { RecordItem } from "types/common";
import { getFormulaResultForColumn } from "utils/columnUtils";
import { CellType, ViewOption } from "utils/constants";

export const getUpdatedFormulaColumns = ({
  pageColumns,
  updatedColumnId,
  updatedValue,
  finalRecord,
  formulaFieldIds,
  formFormulaFieldsMap,
  isForm = false,
}: {
  pageColumns: TableColumnType[];
  updatedColumnId: string;
  updatedValue: any;
  finalRecord?: RecordItem;
  formulaFieldIds: string[];
  formFormulaFieldsMap: { [colId: string]: string[] };
  isForm?: boolean;
}) => {
  if (!formulaFieldIds.includes(updatedColumnId)) return;
  const updatedFinalRecord = { ...(finalRecord || {}) };
  let changedColumnIds = [updatedColumnId];
  const finalData: { [colId: string]: string | null } = {
    [updatedColumnId]: updatedValue || 0,
  };
  let currentColumnChecked = changedColumnIds[0];
  while (changedColumnIds.length) {
    const linkedFormulaColumnId = Object.keys(formFormulaFieldsMap || {}).find((colId: string) => {
      return formFormulaFieldsMap[colId].includes(currentColumnChecked);
    });
    if (linkedFormulaColumnId) {
      const formulaColumn = pageColumns.find((col: TableColumnType) => col.id === linkedFormulaColumnId);
      if (formulaColumn?.formula) {
        const formulaResult = getFormulaResultForColumn({
          formulaColumn,
          allColumns: pageColumns,
          record: updatedFinalRecord,
          isForm,
        });
        if (Number.isFinite(formulaResult) && formulaColumn?.name) {
          const finalKey = isForm ? formulaColumn.id : formulaColumn.name;
          finalData[linkedFormulaColumnId] = formulaResult;
          changedColumnIds.push(linkedFormulaColumnId);
          updatedFinalRecord[finalKey] = formulaResult;
          changedColumnIds = changedColumnIds.filter((colId: string) => colId !== currentColumnChecked);
        } else {
          changedColumnIds = changedColumnIds.filter((colId: string) => colId !== currentColumnChecked);
        }
      } else {
        changedColumnIds = changedColumnIds.filter((colId: string) => colId !== currentColumnChecked);
      }
    } else {
      changedColumnIds = changedColumnIds.filter((colId: string) => colId !== currentColumnChecked);
    }
    if (changedColumnIds.length) currentColumnChecked = changedColumnIds[0];
  }
  return finalData;
};

export const doesRecordDataMatchCondition = ({
  recordValue,
  conditionalViewRules,
  fullSelectedColumn,
  view,
}: {
  recordValue: RecordItem;
  conditionalViewRules: ColumnRule;
  fullSelectedColumn?: TableColumnType;
  view?: ViewOption;
}) => {
  if (!recordValue) return false;
  const { columnRules } = conditionalViewRules;
  const firstRule = columnRules?.[0];
  const { column: selectedColumn, value, field, lookupColumnNameField } = firstRule || {};
  const fieldToUse = view === ViewOption.FORM ? field : lookupColumnNameField;
  let finalConditionValue: string | boolean = value as string;
  // Check if value needs to be converted (for example for boolean)
  if (
    (fullSelectedColumn?.dbType?.format === "boolean" || fullSelectedColumn?.type === CellType.BOOLEAN) &&
    typeof finalConditionValue === "string"
  ) {
    finalConditionValue = finalConditionValue === "true";
  }
  if (selectedColumn?.isLookup && fieldToUse) {
    if (Array.isArray(recordValue)) {
      return recordValue.some((record: RecordItem) => record[fieldToUse] === finalConditionValue);
    } else {
      return recordValue[fieldToUse] === finalConditionValue;
    }
  } else {
    if (Array.isArray(recordValue)) {
      return recordValue.some((record: RecordItem) => {
        if (typeof record === "string" || typeof record === "number") return record === finalConditionValue;
        return record?.[selectedColumn?.name || ""] === finalConditionValue;
      });
    } else {
      if (typeof recordValue === "string" || typeof recordValue === "number" || typeof recordValue === "boolean") {
        return recordValue === finalConditionValue;
      }
      return recordValue?.[selectedColumn?.name || ""] === finalConditionValue;
    }
  }
};

export const getColumnIdValuesMapFromColumnKeys = (record: RecordItem, columnKeysIdMap: RecordItem) => {
  return Object.keys(record).reduce((acc: RecordItem, key: string) => {
    const colId = columnKeysIdMap[key];
    if (colId) {
      acc[colId] = record[key];
    }
    return acc;
  }, {});
};
