import React from "react";
import clsx from "clsx";
import Skeleton from "components/Skeleton";
import Tooltip from "components/Tooltip";
import { copyText } from "utils/texts";
import toast from "utils/toast";
import EllipisWithTooltip from "components/EllipisWithTooltip";
import CellEmpty from "./CellEmpty";
import CellPlaceholder from "./CellPlaceholder";

type CellTextFields = {
  value: string;
};

type CellTextProps = CellTextFields & {
  isLoading?: boolean;
  isEditable?: boolean;
  onClick?: () => void;
  className?: string;
  additionalText?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  isHeader?: boolean;
  singleClickToCopy?: boolean;
  textColor?: string;
  autoHeight?: boolean;
  inGrid?: boolean;
};

const CellText = ({
  value = "",
  isLoading = false,
  onClick,
  isEditable = false,
  className = "",
  additionalText = "",
  isHeader = false,
  onKeyDown,
  singleClickToCopy,
  textColor,
  autoHeight,
  inGrid = false
}: CellTextProps) => {
  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 max-w-full" />
      </div>
    );
  }

  if (!value && !isEditable) return <CellEmpty className={className} />;

  if (!value && isEditable) {
    return <CellPlaceholder onClick={onClick} className={className} onKeyDown={onKeyDown} />;
  }

  if (!isEditable && singleClickToCopy) {
    return (
      <Tooltip title="Click to copy" followCursor arrow={false}>
        <div
          className={clsx(
            "cursor-pointer overflow-hidden border border-transparent bg-transparent text-sm",
            className,
            isHeader ? "font-semibold" : "font-medium",
            inGrid && autoHeight ? "whitespace-break-spaces break-words" : "overflow-ellipsis whitespace-nowrap"
          )}
          onClick={async (e) => {
            e.stopPropagation();
            await copyText(typeof value === "object" ? JSON.stringify(value) : value + additionalText);
            toast.success("Copied to clipboard");
          }}
          tabIndex={0}
          style={{
            color: textColor
          }}
        >
          {typeof value === "object" ? JSON.stringify(value) : value + additionalText}
        </div>
      </Tooltip>
    );
  }

  return (
    <EllipisWithTooltip
      className={clsx(
        "overflow-hidden border border-transparent bg-transparent text-sm",
        className,
        isHeader ? "font-semibold" : "font-medium",
        inGrid && autoHeight ? "whitespace-break-spaces break-words" : "overflow-ellipsis whitespace-nowrap"
      )}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={isEditable ? 0 : undefined}
      style={{
        color: textColor
      }}
    >
      {typeof value === "object" ? JSON.stringify(value) : value + additionalText}
    </EllipisWithTooltip>
  );
};

export default React.memo(CellText);
