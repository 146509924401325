import clsx from "clsx";
import Skeleton from "components/Skeleton";
import CellEmpty from "./CellEmpty";

// type def for CellCoordinateProps
type CellCoordinateProps = {
  value: string;
  isLoading?: boolean;
  isEditable?: boolean;
  onClick?: () => void;
  className?: string;
};

/**
 * Currently this Cell Type is just used as Data Cell for the Coordinate Field Type
 * We can Add UI Display for this Cell Type in the future
 */
const CellCoordinate = ({
  value,
  isLoading = false,
  isEditable = false, // Is always false for this cell for now
  className = "",
}: CellCoordinateProps) => {
  if (isLoading) {
    return (
      <div className={className}>
        <Skeleton className="h-3 max-w-full" />
      </div>
    );
  }

  if (!value && !isEditable) return <CellEmpty className={className} />;

  return null;
};

export default CellCoordinate;
