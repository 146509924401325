import clsx from "clsx";
import { FileAudio } from "lucide-react";

const AudioFileThumbnail = ({ size }: { size?: "small" | "medium" }) => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <FileAudio className={clsx("h-14 w-14 text-[#6A6F79] dark:text-white", size === "small" && "scale-[60%]")} />
    </div>
  );
};

export default AudioFileThumbnail;
