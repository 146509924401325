import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { useCallback, useMemo } from "react";
import { getRollupFormulaColumnValues } from "components/Form/utils";
import { getUpdatedFormulaColumns } from "components/DetailView/utils";
import { ApiRecordType } from "types/apiTypes";
import { Page, TableColumnType } from "types/baTypes";
import { RecordItem, SelectOption } from "types/common";
import { isLookupForeignKeyOnJoinTable } from "utils/columnUtils";
import { CellType, EMPTY_FIELD, ERROR_TYPES, GENERIC_CELL_LAYOUTS, ViewOption } from "utils/constants";
import { getColumnHeaderTypeForRecordKey, getRecordForViewType } from "utils/dataUtils";
import useFormFieldsSubmission from "./useFormFieldsSubmission";
import useErrorLogger from "./useErrorLogger";
import useRecordTypes from "./useRecordTypes";
import useSchemaState from "./useSchemaState";
import useUIState from "./useUIState";

type useRecordEditProps = {
  tableName: string;
  pageColumns?: TableColumnType[];
  recordData?: RecordItem;
  recordSlug?: string;
  view: ViewOption;
  onSuccess?: (newItem?: RecordItem) => void;
  shouldRefetchTableData?: boolean;
};

const finalRecordHeaderMap = ({
  recordData,
  pageData,
  view,
  extendedSchema,
  recordTypesData
}: {
  recordData?: RecordItem;
  pageData?: Page;
  view: ViewOption;
  extendedSchema?: any;
  recordTypesData?: ApiRecordType[];
}): {
  finalRecord?: RecordItem;
  colHeaderTypeMap?: Record<string, { column: TableColumnType; isFormula?: boolean }>;
} => {
  if (!recordData?.id || !pageData?.table_name) {
    return {};
  }
  const { columns = [] } = pageData;
  const filteredColumns = columns.filter((col) => col.views?.[view]);
  filteredColumns.sort((a, b) =>
    !!a.sortOrder?.[view] && !!b.sortOrder?.[view] && (a.sortOrder?.[view] || 0) < (b.sortOrder?.[view] || 0) ? -1 : 1
  );
  const finalRecord = getRecordForViewType({
    record: recordData,
    viewType: view,
    columnOptions: columns,
    finalColumns: filteredColumns,
    extendedSchema,
    recordTypesData
  });
  const colHeaderTypeMap = getColumnHeaderTypeForRecordKey(filteredColumns);

  return { finalRecord, colHeaderTypeMap };
};

const useRecordEdit = ({
  tableName,
  pageColumns,
  recordData,
  recordSlug,
  view,
  onSuccess,
  shouldRefetchTableData = true
}: useRecordEditProps) => {
  const { logError } = useErrorLogger();
  const { showErrorToast } = useUIState();
  const { schemaInstance } = useSchemaState();
  const { data: recordTypesData } = useRecordTypes();
  const { onFormSubmitWithOptions } = useFormFieldsSubmission({
    tableName,
    formColumns: pageColumns || [],
    record: recordData,
    slug: recordSlug || "",
    shouldRefetchTableData,
    skipDefaultValueSet: true,
    source: "useRecordEdit"
  });

  const pageData = useMemo(() => {
    return {
      table_name: tableName,
      columns: pageColumns
    } as Page;
  }, [tableName, pageColumns]);

  const getFormulaMap = useCallback(() => {
    if (!pageColumns?.length) return {};

    let finalFormulaColIds: string[] = [];
    const finalRollupSourceFields: string[] = [];
    const formulaColMap: { [colId: string]: string[] } = {};
    pageColumns.forEach((col: TableColumnType) => {
      if (col.isRollup) {
        const { rollupConfig } = col;
        if (rollupConfig?.sourceColId) {
          finalRollupSourceFields.push(rollupConfig.sourceColId);
        }
      }
      if (col.isFormula) {
        const { formula } = col;
        if (formula) {
          const formulaIds = formula.match(/\{(.*?)\}/g);
          const formulaColIds = formulaIds?.map((id) => id.replace("{", "").replace("}", ""));
          if (formulaColIds) {
            finalFormulaColIds = finalFormulaColIds.concat(formulaColIds);
            formulaColMap[col.id] = formulaColIds;
          }
        }
      }
    });

    return {
      formulaFieldIds: finalFormulaColIds,
      formFormulaFieldsMap: formulaColMap,
      rollupSourceFields: finalRollupSourceFields
    };
  }, [pageColumns]);

  const onRecordEdit = useCallback(
    async ({
      values,
      keyInitial,
      id,
      recordDataInRecord,
      createdInOptions,
      additionalProps
    }: {
      values: any;
      keyInitial?: string;
      id?: string;
      recordDataInRecord?: RecordItem;
      createdInOptions?: { createdIn: string; createdInPath: string };
      additionalProps?: { baseGenericColumnId?: string; onRecordUpdateSuccess?: () => void; isNewRecord?: boolean }; // baseGenericColumnId is sent when the column is an edit column within a generic combination cell
    }) => {
      try {
        const { finalRecord, colHeaderTypeMap } = finalRecordHeaderMap({
          recordData: recordDataInRecord || recordData,
          pageData,
          view,
          extendedSchema: schemaInstance?.extendedSchema,
          recordTypesData
        });
        if (!finalRecord || !colHeaderTypeMap || !pageColumns?.length) return;
        const key = keyInitial
          ? keyInitial
          : (Object.entries(colHeaderTypeMap).find((entry) => entry?.[1].column.id === id)?.[0] as string);
        const data = {
          [colHeaderTypeMap[key].column.id]: values
        };

        // if existing value is empty and new value is also empty do update db
        // isEmpty returns true for numbers value so added condition for number type
        if (
          (typeof finalRecord[key] === "number" ? !finalRecord[key] : isEmpty(finalRecord[key])) &&
          isEmpty(values) &&
          typeof values !== "boolean" &&
          typeof values !== "number"
        ) {
          return;
        }

        const updatedFinalRecord: RecordItem = { ...(finalRecord || {}), [colHeaderTypeMap[key].column.name]: values };
        const columnId = colHeaderTypeMap[key].column.id;
        const rollupUpdatedColumnIds: string[] = [];
        const { formulaFieldIds, formFormulaFieldsMap, rollupSourceFields } = getFormulaMap();
        let updatedFinalData: any = null;
        // Check and add any formula columns that depend on changed column
        if (formulaFieldIds?.includes(columnId) && !isEmpty(formFormulaFieldsMap)) {
          try {
            updatedFinalData = getUpdatedFormulaColumns({
              updatedColumnId: columnId,
              updatedValue: values,
              finalRecord: { ...(updatedFinalRecord || {}), [columnId]: values },
              pageColumns,
              formulaFieldIds,
              formFormulaFieldsMap
            });
            if (!isEmpty(updatedFinalData)) {
              Object.keys(updatedFinalData || {}).forEach((colId: string) => {
                const formulaResult = updatedFinalData?.[colId];
                if (Number.isFinite(formulaResult)) {
                  data[colId] = formulaResult;
                }
              });
            }
          } catch (err) {
            console.log("@@ Error in formula ", err);
            showErrorToast("Formula error: " + (err as Error)?.message);
          }
        }
        // Check if any rollup columns need to be updated
        if (rollupSourceFields?.includes(columnId)) {
          const rollupColumnsToUpdate = pageColumns.filter((col: TableColumnType) => {
            if (col.isRollup) {
              return col.rollupConfig?.sourceColId === columnId;
            }
            return false;
          });
          if (rollupColumnsToUpdate?.length) {
            rollupColumnsToUpdate.forEach((rollupCol: TableColumnType) => {
              if (values?.optionData?.id) {
                data[rollupCol.id] = values?.optionData?.[rollupCol.rollupConfig?.sourceColColumn || ""];
                updatedFinalRecord[rollupCol.name] =
                  values?.optionData?.[rollupCol.rollupConfig?.sourceColColumn || ""];
              } else {
                data[rollupCol.id] = isEmpty(values)
                  ? rollupCol.dbType?.isFKey
                    ? null
                    : rollupCol.dbType?.format === "text"
                      ? ""
                      : 0
                  : values;
                updatedFinalRecord[rollupCol.name] = isEmpty(values)
                  ? rollupCol.dbType?.isFKey
                    ? null
                    : rollupCol.dbType?.format === "text"
                      ? ""
                      : 0
                  : values;
              }
              rollupUpdatedColumnIds.push(rollupCol.id);
            });
            if (rollupUpdatedColumnIds.length) {
              const updatedColumnsAndValues = getRollupFormulaColumnValues({
                rollupUpdatedColumnIds,
                allFormValues: updatedFinalRecord,
                formFormulaFieldsMap,
                formulaFieldIds,
                formColumns: pageColumns
              });
              if (Object.keys(updatedColumnsAndValues).length) {
                Object.keys(updatedColumnsAndValues).forEach((colId: string) => {
                  const formulaResult = updatedColumnsAndValues?.[colId];
                  if (Number.isFinite(formulaResult)) {
                    data[colId] = formulaResult;
                  }
                });
              }
            }
          }
        }
        const isColForeignKeyOnJoin = isLookupForeignKeyOnJoinTable(
          colHeaderTypeMap[key].column,
          schemaInstance?.extendedSchema
        );
        if (isColForeignKeyOnJoin) {
          const { lookupPath } = colHeaderTypeMap[key].column;
          const firstLevelDetails = lookupPath?.["0"];
          const secondLevelDetails = lookupPath?.["1"];
          if (firstLevelDetails?.lookupTableName && secondLevelDetails?.lookupForeignKey) {
            const relevantRecord = cloneDeep(
              recordData?.[firstLevelDetails?.lookupColumnLabel || firstLevelDetails?.lookupTableName] || {}
            );
            if (!isEmpty(relevantRecord) && Array.isArray(relevantRecord)) {
              const firstValue = relevantRecord[0];
              if ((values as SelectOption)?.optionData?.id) {
                firstValue[secondLevelDetails?.lookupForeignKey] = { id: (values as SelectOption)?.optionData?.id };
                data[colHeaderTypeMap[key].column.id] = {
                  ...firstValue
                };
              }
            }
          }
        }

        // If column is Generic combination cell, we need to also update the other edit columns in the generic config to null
        const genericColumn = additionalProps?.baseGenericColumnId
          ? pageColumns?.find((col) => col.id === additionalProps?.baseGenericColumnId)
          : undefined;
        if (
          genericColumn?.type === CellType.GENERIC_CELL &&
          genericColumn?.cellConfig?.genericConfig?.layout === GENERIC_CELL_LAYOUTS.COMBINATION_CELL &&
          genericColumn?.cellConfig?.genericConfig?.comboEditColumnIds?.includes(columnId)
        ) {
          const { comboEditColumnIds } = genericColumn?.cellConfig?.genericConfig;

          comboEditColumnIds.forEach((colId) => {
            if (colId !== columnId) {
              const otherCol = pageColumns?.find((col) => col.id === colId);
              if (otherCol?.isLookup) {
                data[colId] = {
                  id: EMPTY_FIELD
                };
              } else {
                data[colId] = "";
              }
            }
          });
        }
        try {
          // finalRecord is only adding display column data, this does not include columns like `id` composite keys etc.
          // recordDataInRecord is used when passed as some columns such as File need the original record not the formatted one
          await onFormSubmitWithOptions(data, {
            ignoreJoinTableCheck: true,
            record: recordDataInRecord?.id ? recordDataInRecord : finalRecord,
            ...createdInOptions
          });
          onSuccess?.({ id: recordDataInRecord?.id, ...data });
          additionalProps?.onRecordUpdateSuccess?.();
        } catch (err: any) {
          logError({
            error: err,
            source: "useRecordEdit - onRecordEdit",
            message: err.message || "Error saving record",
            type: ERROR_TYPES.HOOKS,
            url: window.location.href,
            additionalInfo: {
              data,
              createdInOptions,
              additionalProps,
              values,
              keyInitial,
              id,
              recordDataInRecord
            }
          });
          showErrorToast("An error occured while saving the record details. " + (err as Error)?.message);
        }
      } catch (error: any) {
        if ((error as Error)?.message?.includes("Can't understand after")) {
          showErrorToast(
            "Formula error occured, please ensure all formula columns have been added to the view.  " +
              (error as Error)?.message
          );
        } else {
          showErrorToast("An error occured while updating the record. " + (error as Error)?.message);
        }
        logError({
          error: error,
          source: "useRecordEdit - onRecordEdit",
          message: error.message || "Error saving record",
          type: ERROR_TYPES.HOOKS,
          url: window.location.href,
          additionalInfo: {
            createdInOptions,
            additionalProps,
            values,
            keyInitial,
            id,
            recordDataInRecord
          }
        });
      }
    },
    [
      recordData,
      pageData,
      view,
      schemaInstance?.extendedSchema,
      pageColumns,
      onFormSubmitWithOptions,
      onSuccess,
      logError,
      getFormulaMap,
      showErrorToast,
      recordTypesData
    ]
  );

  return {
    onRecordEdit,
    onFormSubmitWithOptions
  };
};

export default useRecordEdit;
