import { useQuery } from "@tanstack/react-query";
import { getPageDataById } from "lib/adminApi";
import { Page } from "types/baTypes";
import { QueryHookOptions } from "types/common";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import useCurrentUser from "./useCurrentUser";

const usePageDataById = (id = "", options?: QueryHookOptions, isAdminPage?: boolean) => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["page", id, currentUser?.org_id, isAdminPage],
    queryFn: () => getPageDataById(supabaseClient, id, currentUser?.org_id, isAdminPage),
    enabled: !!id,
    refetchOnMount: false,
    refetchInterval: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options
  });

  return {
    data: data?.data as Page | undefined,
    isLoading,
    refetch
  };
};

export default usePageDataById;
