import { Transition } from "@headlessui/react";
import {
  MessageSquarePlus as AnnotationIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CopyIcon,
  DownloadIcon,
  XIcon
} from "lucide-react";

import clsx from "clsx";
import { Fragment, useState } from "react";
import { useMediaQuery } from "usehooks-ts";

import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import AudioViewer from "components/AudioViewer";
import DetailTitleControlsHeader, {
  DetailHeaderAdditionalMultiTitleConfig
} from "components/DetailView/DetailTitleControlsHeader";
import DocumentViewer from "components/DocumentViewer";
import FileAnnotation from "components/FileAnnotation";
import IconButton from "components/IconButton";
import { IconButtonColor, IconButtonSize } from "components/IconButton/utils";
import ImageComponent from "components/Image";
import Modal from "components/Modal";
import Tooltip from "components/Tooltip";
import Video from "components/Video";
import { NestedViewState } from "context/NestedViewContext";
import useTableActionsState from "hooks/useTableActionsState";
import { PageAction } from "types/baTypes";
import { FeaturedImageDetails, RecordItem } from "types/common";
import { FileType, TOAST_TYPE } from "utils/constants";
import { copyFileRawUrl, downloadFileFromSupabase } from "utils/file";

import toast from "utils/toast";

type FileModalProps = {
  srcFile: string;
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  fileType: string;
  fileName?: string;
  isPrevDisabled?: boolean;
  isNextDisabled?: boolean;
  onPrev?: () => void;
  onNext?: () => void;
  pageTitle?: string;
  title?: string;
  nestedViewState?: NestedViewState;
  featuredImage?: FeaturedImageDetails | null;
  hideNavigation?: boolean;
  showRecordNavigationControl?: boolean;
  hideBreadcrumb?: boolean;
  onExpandClick?: (isExpanded: boolean) => void;
  hideExpandButton?: boolean;
  hideSidebar?: boolean;
  mimetype?: string;
  dimensions?: string;
  enableFileAnnotation?: boolean;
  fileId: string;
  annotationPageId?: string;
  actions: PageAction[];
  recordData?: RecordItem;
  tableName?: string;
  tablePath?: string;
  formattedRecord?: RecordItem;
  additionalConfigForMultiTitle?: DetailHeaderAdditionalMultiTitleConfig;
};

const FileModal = ({
  srcFile,
  children,
  isOpen,
  onClose,
  fileType,
  fileName,
  isPrevDisabled,
  isNextDisabled,
  onPrev,
  onNext,
  pageTitle,
  title,
  nestedViewState,
  featuredImage,
  hideNavigation,
  showRecordNavigationControl = false,
  hideBreadcrumb = false,
  onExpandClick,
  hideExpandButton = false,
  hideSidebar = false,
  mimetype,
  dimensions,
  fileId,
  enableFileAnnotation = false,
  annotationPageId,
  actions = [],
  recordData,
  tableName,
  tablePath,
  formattedRecord,
  additionalConfigForMultiTitle
}: FileModalProps) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const isExternal = srcFile.startsWith("http") || srcFile.startsWith("blob");
  const notShowSidebar = isExternal || hideSidebar;
  const { currentProjectId } = useTableActionsState();

  const [showAnnotations, setShowAnnotations] = useState(enableFileAnnotation);

  const handleClose = () => {
    onClose();
  };

  const handleDownload = async () => {
    const toastId = toast.success("Downloading file...", {
      autoClose: false
    });
    await downloadFileFromSupabase(srcFile, fileName || "file");
    toast.update(toastId, "Downloaded file successfully", {
      autoClose: 2000,
      type: TOAST_TYPE.SUCCESS
    });
  };

  const handleCopyUrl = async () => {
    await copyFileRawUrl(srcFile);
    toast.success("Copied Raw URL successfully...", {
      autoClose: 2000
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      showClose={false}
      classNameBg={clsx("!border-none !bg-transparent")}
      fullScreen
      hideOverlay
    >
      <div
        className={clsx(
          "h-full overflow-hidden bg-base-secondary/90 transition-all dark:bg-black/75 lg:fixed lg:inset-0"
        )}
      >
        <div className={clsx(notShowSidebar ? "block" : "hidden", "h-full w-full lg:block")}>
          <div
            className={clsx(
              "fixed top-5 z-10 w-full items-center justify-between px-5 lg:flex",
              notShowSidebar ? "flex" : "hidden"
            )}
          >
            <XIcon className="text-icons h-5 w-5 cursor-pointer" onClick={handleClose} />
          </div>
          <div className={clsx(notShowSidebar ? "block h-full" : "hidden lg:block lg:h-full")}>
            <div
              className={clsx(
                "relative flex h-[calc(100%-64px)] items-center justify-center gap-2 overflow-hidden transition-all duration-300 lg:h-full",
                !notShowSidebar && "mr-[556px]"
              )}
            >
              <div className="z-50 ml-3 hidden h-7 w-7 lg:block">
                {isPrevDisabled || hideNavigation ? null : (
                  <ChevronLeftIcon
                    className="h-7 w-7 cursor-pointer text-neutral-0 dark:text-neutral-dark-0"
                    onClick={(e) => {
                      e.stopPropagation();
                      onPrev?.();
                    }}
                  />
                )}
              </div>
              <div
                className={clsx(
                  "group/image relative flex h-full w-full items-center lg:max-h-[80%] lg:min-h-[20%]",
                  notShowSidebar ? "cursor-pointer" : "lg:min-w-[20%] lg:max-w-[80%]"
                )}
              >
                {fileType === FileType.IMAGE ? (
                  enableFileAnnotation || (currentProjectId && tableName === "files") ? (
                    <FileAnnotation
                      src={srcFile}
                      id={fileId}
                      enabled={showAnnotations}
                      annotationPageId={annotationPageId}
                    />
                  ) : (
                    <TransformWrapper pinch={{ step: 50 }}>
                      <TransformComponent
                        wrapperClass="!h-full !w-full"
                        contentClass="!h-full !w-full !cursor-zoom-in justify-center items-center"
                      >
                        <ImageComponent fill src={srcFile} alt="" className="object-contain" />
                      </TransformComponent>
                    </TransformWrapper>
                  )
                ) : null}

                {fileType === FileType.DOCUMENT && <DocumentViewer src={srcFile} mimetype={mimetype} />}
                {fileType === FileType.VIDEO && !isMobile && (
                  <Video src={srcFile} className="h-full w-full object-contain" autoPlay controls />
                )}
                {fileType === FileType.AUDIO && <AudioViewer src={srcFile} title={fileName} type={mimetype} />}
                <div className="absolute -bottom-14 z-30 flex w-full items-center justify-center gap-2">
                  {enableFileAnnotation || (currentProjectId && tableName === "files") ? (
                    <div className="flex items-center gap-2 rounded-xl bg-neutral-0 p-2 shadow-300 dark:bg-neutral-dark-0">
                      <Tooltip title="Toggle Annotations">
                        <IconButton
                          size={IconButtonSize.XS}
                          icon={AnnotationIcon}
                          color={IconButtonColor.PRIMARY}
                          onClick={() => setShowAnnotations((prev) => !prev)}
                        />
                      </Tooltip>
                      <Tooltip title="Copy Raw Url">
                        <IconButton icon={CopyIcon} onClick={handleCopyUrl} />
                      </Tooltip>
                      <Tooltip title="Download">
                        <IconButton
                          size={IconButtonSize.XS}
                          icon={DownloadIcon}
                          color={IconButtonColor.LIGHT}
                          onClick={handleDownload}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="flex items-center gap-2 rounded-xl bg-neutral-0 p-2 shadow-300 dark:bg-neutral-dark-0">
                      <Tooltip title="Copy Raw Url">
                        <IconButton icon={CopyIcon} onClick={handleCopyUrl} />
                      </Tooltip>
                      <Tooltip title="Download">
                        <IconButton icon={DownloadIcon} onClick={handleDownload} />
                      </Tooltip>
                    </div>
                  )}

                  {dimensions && (
                    <div className="select-none rounded-md px-2 py-1 text-xs text-white">{dimensions}</div>
                  )}
                </div>
              </div>
              <div className="z-50 mr-3 hidden h-7 w-7 lg:block">
                {isNextDisabled || hideNavigation ? null : (
                  <ChevronRightIcon
                    className="h-7 w-7 cursor-pointer text-neutral-0 dark:text-neutral-dark-0"
                    onClick={(e) => {
                      e.stopPropagation();
                      onNext?.();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {!notShowSidebar && (
          <Transition.Root show={true} as={Fragment}>
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={clsx(
                  "bg-background border-separator block h-full w-full overflow-hidden border shadow-400  lg:fixed lg:right-0 lg:top-0 lg:h-[calc(100%-24px)] lg:max-w-[544px] lg:rounded-lg",
                  "block h-full w-full",
                  "z-50 lg:m-3"
                )}
              >
                <div className={clsx("flex h-screen flex-1 flex-col")}>
                  <DetailTitleControlsHeader
                    onClose={handleClose}
                    isPrevDisabled={isPrevDisabled}
                    isNextDisabled={isNextDisabled}
                    onPrev={onPrev}
                    onNext={onNext}
                    pageTitle={pageTitle}
                    nestedViewState={nestedViewState}
                    title={title}
                    additionalConfigForMultiTitle={additionalConfigForMultiTitle}
                    featuredImage={featuredImage}
                    hideNavigation={hideNavigation}
                    showRecordNavigationControl={showRecordNavigationControl}
                    hideBreadcrumb={hideBreadcrumb}
                    onExpandClick={onExpandClick}
                    hideExpandButton={hideExpandButton}
                    inNestedView
                    actions={actions}
                    recordData={recordData}
                    tableName={tableName}
                    formattedRecord={formattedRecord}
                    tablePath={tablePath}
                  />

                  <div className="flex flex-1 flex-col overflow-y-auto">
                    <div
                      className={clsx(
                        "relative flex h-[300px] min-h-[300px] w-full flex-col overflow-hidden lg:hidden"
                      )}
                    >
                      {fileType === FileType.IMAGE && (
                        <TransformWrapper pinch={{ step: 50 }}>
                          <TransformComponent
                            wrapperClass="!h-full !w-full"
                            contentClass="!h-full !w-full !cursor-zoom-in items-center justify-center"
                          >
                            <ImageComponent fill src={srcFile} alt="" className="object-cover" />
                          </TransformComponent>
                        </TransformWrapper>
                      )}
                      {fileType === FileType.DOCUMENT && <DocumentViewer src={srcFile} mimetype={mimetype} />}
                      {fileType === FileType.VIDEO && isMobile && (
                        <Video src={srcFile} className="h-full w-full object-cover" autoPlay controls />
                      )}
                      {fileType === FileType.AUDIO && <AudioViewer src={srcFile} title={fileName} type={mimetype} />}
                      {dimensions && (
                        <div className="absolute bottom-1 right-1 rounded-md bg-neutral-dark-100 bg-opacity-40 px-2 py-1 text-xs text-white">
                          {dimensions}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-1 flex-col px-4 pb-4 lg:px-8">{children}</div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </Transition.Root>
        )}
      </div>
    </Modal>
  );
};

export default FileModal;
