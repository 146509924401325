import { useEffect, useMemo, useState } from "react";
import sortBy from "lodash/sortBy";
import ImageAnnotate from "components/ImageAnnotate";
import { IAnnotation } from "components/ImageAnnotate/types";
import useFileAnnotations from "hooks/useFileAnnotations";
import useSubmitFileAnnotation from "hooks/useSubmitFileAnnotation";
import Loader from "components/Loader";
import useCurrentUser from "hooks/useCurrentUser";
import usePageDataById from "hooks/usePageDataById";
import useTableActionsState from "hooks/useTableActionsState";
import { formatActions } from "utils/actions";

const FileAnnotation = ({
  src,
  id,
  enabled,
  annotationPageId = ""
}: {
  src: string;
  id: string;
  enabled?: boolean;
  annotationPageId?: string;
}) => {
  const { data: pageData, isLoading: isPageDataLoading } = usePageDataById(annotationPageId);
  const { currentProjectId } = useTableActionsState();
  const { data: annotationsData, refetch, isLoading } = useFileAnnotations({ fileId: id, pageData: pageData });
  const [annotations, setAnnotations] = useState<IAnnotation[]>([]);
  const currentUser = useCurrentUser();

  const viewActions = useMemo(() => {
    if (!pageData?.ui_pages_actions) return;

    return formatActions(sortBy(pageData.ui_pages_actions, "sort_order"));
  }, [pageData?.ui_pages_actions]);

  useEffect(() => {
    setAnnotations(annotationsData);
  }, [annotationsData]);

  const [currAnnotation, setCurrAnnotation] = useState<IAnnotation>({});

  const { submitNoteAnnotation } = useSubmitFileAnnotation({
    tableName: pageData?.table_name,
    currentProjectId,
    fileId: id
  });

  const onSubmit = async (newAnnotation: IAnnotation) => {
    const createdAnnotation = await submitNoteAnnotation(newAnnotation);
    if (createdAnnotation) {
      setAnnotations((prev) => {
        const newAnnotations = prev.map((ann) =>
          ann.data?.id === createdAnnotation.data?.id ? createdAnnotation : ann
        );
        if (!newAnnotations.some((ann) => ann.data?.id === createdAnnotation.data?.id)) {
          newAnnotations.push(createdAnnotation);
        }
        return newAnnotations;
      });
      refetch();
    }
  };

  if (isLoading || (annotationPageId && isPageDataLoading)) {
    return <Loader className=" text-neutral-0" />;
  }

  return (
    <div className="flex h-full w-full items-center justify-center">
      <ImageAnnotate
        src={src}
        annotations={annotations}
        value={currAnnotation}
        onChange={setCurrAnnotation}
        onSubmit={onSubmit}
        disableAnnotation={!enabled}
        currentUser={currentUser}
        actions={viewActions}
        tableName={pageData?.table_name}
        tablePath={pageData?.path}
      />
    </div>
  );
};

export default FileAnnotation;
