import { useRef, useState } from "react";
import clsx from "clsx";
import { CopyIcon, X as RemoveIcon } from "lucide-react";

import IconButton from "components/IconButton";
import Image from "components/Image";
import { IconButtonColor, IconButtonSize } from "components/IconButton/utils";
import { FileType } from "utils/constants";
import Video from "components/Video";
import DocumentThumbnailPreview from "components/DocumentThumbnailPreview";
import AudioFileThumbnail from "components/MimeTypeIcons/AudioFileThumbnail";
import Tooltip from "components/Tooltip";
import { copyFileRawUrl } from "utils/file";
import toast from "utils/toast";
import { HoverCard, HoverCardContent, HoverCardPortal, HoverCardTrigger } from "components/ui/hovercard";

type FileCardPreviewProps = {
  classNameContainer?: string;
  className?: string;
  src: string;
  onRemovePreview?: () => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  fileType?: string;
  mimeType?: string;
  fileId?: string;
  thumbnailPath?: string;
  inCell?: boolean;
  size?: "small" | "medium";
  recordId?: string;
  disableThumbnailSave?: boolean;
};
const FileCardPreview = ({
  src,
  classNameContainer,
  className,
  onRemovePreview,
  fileType = FileType.IMAGE,
  mimeType,
  fileId,
  thumbnailPath,
  size = "small",
  recordId,
  disableThumbnailSave,
  ...rest
}: FileCardPreviewProps) => {
  const [isShowing, setIsShowing] = useState(false);
  const timeout = useRef<NodeJS.Timeout>();
  const onMouseEnter = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setIsShowing(true);
  };

  const onMouseLeave = () => {
    timeout.current = setTimeout(() => setIsShowing(false), 50);
  };

  return (
    <div
      className={clsx("group relative aspect-[4/3] w-[150px] cursor-pointer", classNameContainer)}
      data-testid="FileCardPreview"
      {...rest}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {onRemovePreview && isShowing && (
        <>
          <div className="absolute -right-2 -top-2 z-[2]">
            <IconButton
              icon={RemoveIcon}
              size={IconButtonSize.XS}
              color={IconButtonColor.LIGHT}
              onClick={(e) => {
                e.stopPropagation();
                onRemovePreview();
              }}
            />
          </div>
          <div className="absolute left-1 top-1 z-[2]">
            <Tooltip title="Copy Raw Url">
              <IconButton
                icon={CopyIcon}
                size={IconButtonSize.XS}
                color={IconButtonColor.LIGHT}
                onClick={async (e) => {
                  e.stopPropagation();
                  await copyFileRawUrl(src);
                  toast.success("Copied Raw URL successfully...", {
                    autoClose: 2000
                  });
                }}
              />
            </Tooltip>
          </div>
        </>
      )}
      <div
        className={clsx(
          "border-separator relative h-full w-full overflow-hidden rounded-xl border shadow-50 hover:shadow-200 ",
          className
        )}
      >
        {fileType === FileType.IMAGE && (
          <>
            <div className="absolute bottom-0 left-0 right-0 top-0 z-[1] bg-opacity-0 transition group-hover:bg-neutral-dark-0 group-hover:bg-opacity-40"></div>
            <Image
              src={src}
              alt="Image File Preview"
              className="h-full w-full object-cover"
              fill
              priority
              sizes="(max-width: 768px) 150px,(max-width: 1200px) 300px, 33vw"
            />
          </>
        )}
        {fileType === FileType.VIDEO && (
          <div className="relative h-full w-full">
            <Video
              src={src}
              className="h-full w-full object-cover"
              fileId={fileId}
              thumbnailPath={thumbnailPath}
              showPreview
              playsInline
              muted
            />
            <div className="absolute left-0 top-0 z-[1] flex h-full w-full items-center justify-center">
              <div
                className={clsx(
                  "flex shrink-0 items-center justify-center rounded-full bg-black/60",
                  size === "small" ? "h-5 w-5" : "h-7 w-7"
                )}
              >
                {size === "small" ? (
                  <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0 8.91938C0 9.75788 0.969932 10.2241 1.6247 9.70024L6.52391 5.78087C7.02432 5.38054 7.02432 4.61946 6.52391 4.21913L1.6247 0.299757C0.969932 -0.224054 0 0.242119 0 1.08062V8.91938Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0 12.9194C0 13.7579 0.969932 14.2241 1.6247 13.7002L9.02391 7.78087C9.52432 7.38054 9.52432 6.61946 9.02391 6.21913L1.6247 0.299757C0.969932 -0.224054 0 0.242119 0 1.08062V12.9194Z"
                      fill="white"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
        )}
        {fileType === FileType.DOCUMENT && (
          <div className="relative h-full w-full">
            <HoverCard openDelay={300}>
              <HoverCardTrigger>
                <DocumentThumbnailPreview
                  src={src}
                  mimeType={mimeType}
                  fileId={fileId}
                  thumbnailPath={thumbnailPath}
                  size={size}
                  recordId={recordId}
                  disableThumbnailSave={disableThumbnailSave}
                />
              </HoverCardTrigger>
              <HoverCardPortal>
                <HoverCardContent side="left" className="aspect-[1/1.29] h-[600px] !w-auto">
                  <DocumentThumbnailPreview
                    src={src}
                    mimeType={mimeType}
                    fileId={fileId}
                    thumbnailPath={thumbnailPath}
                    thumbnailSizes="(max-width: 768px) 250px, 600px"
                    recordId={recordId}
                    disableThumbnailSave={disableThumbnailSave}
                  />
                </HoverCardContent>
              </HoverCardPortal>
            </HoverCard>
          </div>
        )}
        {fileType === FileType.AUDIO && <AudioFileThumbnail size={size} />}
      </div>
    </div>
  );
};

export default FileCardPreview;
