import React from "react";
import { IAnnotation } from "../types";

type PointProps = {
  annotation: IAnnotation;
  children?: React.ReactElement;
};
const Point = React.forwardRef(({ annotation, children, ...rest }: PointProps, ref: any) => {
  const { geometry } = annotation;
  if (!geometry) return null;

  return (
    <div
      className="absolute flex translate-y-[-100%] cursor-pointer rounded-full rounded-bl-none bg-white p-1 pt-0.5 shadow-md"
      style={{
        top: `${geometry.y}%`,
        left: `${geometry.x}%`,
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </div>
  );
});

Point.displayName = "Point";

export default Point;
