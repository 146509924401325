import { useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import clsx from "clsx";
import Skeleton from "components/Skeleton";

type CellRatingProps = {
  value: number;
  isEditable?: boolean;
  onEdit?: (value: number) => void;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
};

const CellRating = ({
  value,
  isLoading = false,
  isEditable = false,
  onEdit,
  disabled = false,
  className = "",
}: CellRatingProps) => {
  const [rating, setRating] = useState(value);

  useEffect(() => {
    if (rating !== value) {
      setRating(value);
    }
  }, [value]);

  if (isLoading) {
    return <Skeleton className="h-3 w-10" />;
  }

  const handleChange = (ratingUpdated: number) => {
    setRating(ratingUpdated);
    onEdit?.(ratingUpdated);
  };

  return (
    <div className={clsx("w-full text-sm", className)}>
      <Rating
        value={value}
        precision={0.5}
        disabled={disabled as boolean}
        readOnly={!isEditable}
        onChange={(event, newValue) => {
          handleChange(newValue as number);
        }}
      />
    </div>
  );
};

export default CellRating;
